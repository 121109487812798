import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Footer from "../Footer";
import Navigation from "../Navigation";
import Lottie from "react-lottie";
import * as animationData from "../../Images/132033-green-login.json";
import { Item, MyAPI, MyError } from "../../helper/api";
import { useNavigate, useNavigation } from "react-router-dom";
import ButtonLoading from "../ButtonLoading";

export default function UserLogin() {
  const navigation = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [otpSent, setOtpSent] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOtpSent = async () => {
    setIsLoading(true);
    // Trim the phone number and check its length
    const trimmedPhone = phone.trim();
    if (!trimmedPhone || trimmedPhone.length !== 10) {
      MyError.error("Please enter a valid phone number.");
      return; // Return early if phone number is invalid
    }

    let res = await MyAPI.POST("/user/sendotp", { phone });

    let { status, message } = res.data || res;
    setIsLoading(false);
    if (status === true) {
      setOtpSent(true);
      MyError.success(message);
    } else {
      if (message === "User not registered") {
        MyError.error("please sign-up first.");
        navigation("/user/sign-up");
      } else {
        MyError.error(message);
      }
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    let myOtpAPIData = {
      phone,
      otp,
    };
    let res = await MyAPI.POST("/user/login", myOtpAPIData);
    setIsLoading(false);

    let { status, message, data } = res.data || res;

    if (status === true) {
      Item.setItem("token", data.token);
      Item.setItem("Iam", "user");
      navigation("/user/dashboard/profile");
      MyError.success(message);
    } else {
      MyError.error(message);
    }
  };

  return (
    <>
      <Navigation />
      <Container style={{ padding: "50px" }} fluid>
        <h3 style={{ textAlign: "center", marginTop: "10px", fontWeight: 600 }}>
          Please Log In Here
        </h3>
        <hr style={{ paddingBottom: "20px" }}></hr>

        <Row>
          <Col md={6}>
            <div style={{ height: "100%", width: "100%" }}>
              <Lottie
                options={defaultOptions}
                style={{ marginTop: "30px" }}
                height={300}
                width={300}
              />
            </div>
          </Col>
          <Col md={6}>
            <Card
              style={{
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: " 5px 5px 5px 5px #f5f6f8",
                borderBottom: "5px solid #feb92d",
              }}
            >
              {otpSent ? (
                <Col noValidate style={{ padding: "40px", fontSize: "13px" }}>
                  <h4
                    style={{
                      textAlign: "center",
                      fontWeight: 600,
                      paddingBottom: "30px",
                    }}
                  >
                    {" "}
                    Verify OTP{" "}
                  </h4>
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="number"
                      placeholder="Enter OTP"
                      style={{
                        fontSize: "14px",
                        height: "50px",
                        width: "100%",
                        border: "none",
                        borderBottom: "2px solid #032276 ",
                        borderRadius: "20px",
                      }}
                      onChange={(e) => setOtp(e.target.value)}
                      value={otp}
                    />
                  </Form.Group>
                  {isLoading ? (
                    <ButtonLoading />
                  ) : (
                    <Button
                      onClick={handleLogin}
                      className="mx-auto d-block"
                      style={{
                        fontSize: "14px",
                        marginTop: "40px",
                        backgroundColor: "#032276",
                        height: "50px",
                        width: "60%",
                        borderRadius: "20px",
                      }}
                      type="button"
                    >
                      Login
                    </Button>
                  )}
                </Col>
              ) : (
                <Col noValidate style={{ padding: "40px" }}>
                  <h4
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      fontWeight: 600,
                      paddingBottom: "30px",
                    }}
                  >
                    User Login
                  </h4>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Control
                      required
                      name="phone"
                      type="number"
                      placeholder="Enter Mobile No. "
                      style={{
                        fontSize: "14px",
                        height: "50px",
                        width: "100%",
                        border: "none",
                        borderBottom: "2px solid #032276 ",
                        borderRadius: "20px",
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </Form.Group>
                  {isLoading ? (
                    <ButtonLoading />
                  ) : (
                    <Button
                      onClick={handleOtpSent}
                      className="mx-auto d-block"
                      style={{
                        fontSize: "14px",
                        marginTop: "40px",
                        backgroundColor: "#032276",
                        height: "50px",
                        width: "60%",
                        borderRadius: "20px",
                      }}
                      type="button"
                    >
                      Send OTP
                    </Button>
                  )}
                </Col>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
