import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Navigation from '../Navigation';
import kalash from '../../Images/lohri-festival-png-images-thumbnail-1641994101-removebg-preview.png'

export default function FestivalPooja() {

    {
        window.scrollTo(0, 0)
    }

    let navigate = useNavigate();

    const getVendorList = (value) => {
        navigate(`/panditforpoooja/${value}`)
    }

  return (
    <>
       <Navigation />
            <Container style={{padding:'30px'}} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                        </Link>
                    </Col>
                    <Col md={8}>
                        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Our Festival Special Services</h5>

                    </Col>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Diwali Laxmi Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Diwali Laxmi Puja</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Dussehra Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Dussehra Puja</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Ganesh Sathapana and Visarjan Puja") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Ganesh Sathapana and Visarjan Puja</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Hartalika Teej Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Hartalika Teej Puja</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Holika Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Holika Puja</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Janmashtami Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Janmashtami Puja</h6>
                            </div>
                        </Card>
                    </Col>
                    
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Navaratri Kalash Sthapana Puja and Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Navaratri Kalash Sthapana Puja and Havan</h6>
                            </div>
                        </Card>


                    </Col>
                  
                    
                </Row>
               
            </Container>
            <Footer />
    </>
  )
}
