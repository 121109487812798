import React from 'react'
import '../ComponentsCss/Steps.css'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { FaFileAlt, FaLightbulb, FaRegUser } from 'react-icons/fa'
import Lottie from 'react-lottie';
import * as animationData from '../Images/Og3wx98hmc.json'

export default function Steps() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <Container className='mx-auto d-block stepsmain'>
                <p className='pstep'>S T E P S</p>
                <h2 style={{ color: '#fff', textAlign: 'center', fontSize: '30px', fontWeight: 500, paddingBottom: '100px' }}>How Pandit Bulao Works</h2>

                <Row>
                    <Col md={4}>
                        <div className='stepico mx-auto d-block'><FaFileAlt style={{ marginTop: 18, marginLeft: '27px', height: '50%', width: '25px', color: 'white' }} />
                        </div>
                        <Lottie options={defaultOptions}
                           height={'14%'}
                            width={'32%'}
                            style={{ marginLeft: '80%' }}
                            id='responsive'
                        />
                        <p style={{ color: '#fff', fontSize: '18px', fontWeight: 500, textAlign: 'center'}}>Describe Your Task

                        </p>
                        <p style={{ color: '#fff', fontSize: '13px', textAlign: 'center', marginTop: '40px' }}>This helps us determine which Taskers are best for your job</p>
                    </Col>
                    <Col md={4}>
                        <div className='stepico mx-auto d-block'><FaRegUser style={{ marginTop: 18, marginLeft: '27px', height: '50%', width: '25px', color: 'white' }}/>
                        </div>
                        <Lottie options={defaultOptions}
                            height={'14%'}
                            width={'32%'}
                            style={{ marginLeft:'80%'}}
                            id='responsive'
                        />
                        <p style={{ color: '#fff', fontSize: '18px', fontWeight: 500, textAlign: 'center'}}>Choose a Pandit
                         
                        </p>
                        <p style={{ color: '#fff', fontSize: '13px', textAlign: 'center', marginTop: '40px' }}>This helps us determine which Taskers are best for your job</p>
                    </Col>
                    <Col md={4}>
                        <div className='stepico mx-auto d-block'><FaLightbulb style={{ marginTop: 18, marginLeft: '27px', height: '50%', width: '25px', color: 'white' }} />
                        </div>
                        <p style={{ color: '#fff', fontSize: '18px', fontWeight: 500, textAlign: 'center',marginTop:'12%' }}>Live Smarter

                        </p>
                        <p style={{ color: '#fff', fontSize: '13px', textAlign: 'center', marginTop: '40px' }}>This helps us determine which Taskers are best for your job</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
