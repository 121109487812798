import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { MyAPI, MyError } from "../helper/api";
import panditpic from "../Images/pandit-removebg-preview.png";
import Loading from "./Loading";
function SearchPage() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const { value } = useParams();
  const [myData, setData] = useState(null);
  const fetchSearchResult = async () => {
    let res = await MyAPI.GET(`/public/search?keyword=${value}`);
    setIsLoading(false);
    let { status, message, data } = res.data || res;
    if (status === true) {
      console.log("search data", data);
      setData(data);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    fetchSearchResult();
  }, [value]);

  const navigatePanditProfile = (panditId) => {
    navigate(`/profile/${panditId}`);
  };

  const openPooja = (id) => {
    navigate("/pooja/" + id);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Navigation />
      <Container fluid className="py-4 px-3" style={{ minHeight: "40vh" }}>
        {!myData && <h5>Loading Please Wait...</h5>}

        {myData && myData.poojas && myData.poojas.length === 0 && (
          <h5>Pooj's Not Found.</h5>
        )}

        {myData && myData.pandits && myData.pandits.length === 0 && (
          <h5>Pandit's Not Found.</h5>
        )}

        {myData && myData.poojas.length > 0 && (
          <Row className="mb-4">
            <Col md={12}>
              <h5>Pooja's</h5>
            </Col>
          </Row>
        )}

        <Row>
          {myData &&
            myData.poojas.length > 0 &&
            myData.poojas.map((item, index) => (
              <Col
                md={3}
                onClick={() => openPooja(item._id)}
                key={index}
                className="cursor-pointer"
              >
                <Card className="shadow">
                  <Card.Img
                    style={{
                      maxHeight: "25vh",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    sizes="sm"
                    variant="top"
                    src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${item.image}`}
                  />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                    <Card.Text>{item.dateTime}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>

        {myData && myData.pandits.length > 0 && (
          <Row className="mb-4 mt-5">
            <Col md={12}>
              <h5>Pandit's</h5>
            </Col>
          </Row>
        )}

        <Row>
          {myData &&
            myData.pandits &&
            myData.pandits.length > 0 &&
            myData.pandits.map((pandit, index) => (
              <Col md={3} className="">
                <Card className="p-3 shadow bg-white rounded-3 d-flex flex-column justify-content-center align-items-center mt-3 cursor-pointer border">
                  <Image
                    className="rounded-circle object-fit-cover image-size p-1 border"
                    onClick={() => navigatePanditProfile(pandit._id)}
                    src={
                      pandit.image
                        ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${pandit.image}`
                        : panditpic
                    }
                    height={"100px"}
                    width={"100px"}
                  />
                  <h5
                    onClick={() => navigatePanditProfile(pandit._id)}
                    className="mt-3"
                  >
                    {pandit.name}
                  </h5>
                  <h6>
                    Rating : {pandit.rating.value} / 5 ({pandit.rating.count})
                  </h6>
                  <p className="text-secondary text-center">
                    {pandit.exp}+ years of experience in pooja
                  </p>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SearchPage;
