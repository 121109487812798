import React from "react";
import Navigation from "./Navigation";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

const ContactUsPage = () => {
  return (
    <>
      <Navigation />
      <ContactUs />
      <Footer />
    </>
  );
};

export default ContactUsPage;
