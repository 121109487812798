import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import "../ComponentsCss/Footer.css";
import panlogo from "../Images/panlogo.png";

export default function Footer() {
  return (
    <>
      <Container className="footermain" fluid>
        <Row>
          <Col md={3}>
            <h5>Site Link</h5>
            <hr style={{ width: "30%" }}></hr>

            <ul style={{ listStyle: "none", fontSize: "15px" }}>
              <li className="cursor-pointer">Blog</li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Contact Us
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Jobs
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Post a Job
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h5>Site Link</h5>
            <hr style={{ width: "30%" }}></hr>

            <ul style={{ listStyle: "none", fontSize: "15px" }}>
              <li className="cursor-pointer">Blog</li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Contact Us
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Jobs
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Post a Job
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h5>Popular Cities</h5>
            <hr style={{ width: "30%" }}></hr>

            <ul style={{ listStyle: "none", fontSize: "15px" }}>
              <li className="cursor-pointer">Sagar</li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Jabalpur
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Indore
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                Post a Job
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <h5>Contact Info</h5>
            <hr style={{ width: "30%" }}></hr>

            <ul style={{ listStyle: "none", fontSize: "15px" }}>
              <li className="cursor-pointer">India</li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                +91 85755247874
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                +91 7979447487
              </li>
              <li className="cursor-pointer" style={{ marginTop: "10px" }}>
                hellotest@gmail.com
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginTop: "50px" }}>
          <Col md={2}>
            <Image
              className="mx-auto d-block"
              src={panlogo}
              style={{ height: "100px", width: "120px", objectFit: "contain" }}
            />
          </Col>
          <Col md={8}>
            <p
              style={{ marginLeft: "10%", marginTop: "30px", fontSize: "13px" }}
            >
              Copyright 2022 | All Rights Reserved | Website Designed By{" "}
              <a href="https://softseekersinfotech.com/">
                SoftSeeker's Infotech Private Limited
              </a>
            </p>
          </Col>
          <Col md={2}>
            <FaFacebookF
              className="social-icon"
              style={{
                color: "#4267B2", // Facebook brand color
              }}
            />
            <FaLinkedinIn
              className="social-icon"
              style={{
                color: "#0A66C2", // LinkedIn brand color
              }}
            />
            <FaTwitter
              className="social-icon"
              style={{
                color: "#1DA1F2", // Twitter brand color
              }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
