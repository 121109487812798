import React from "react";
import ReactLoading from "react-loading";
import { Button } from "react-bootstrap";
function ButtonLoading() {
  return (
    <>
      <Button
        disabled={true}
        style={{
          fontSize: "14px",
          backgroundColor: "#032276",
          height: "50px",
          width: "auto",
          borderRadius: "20px",
        }}
        type="button"
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={"#FFB600"}
          height={30}
          width={30}
        />
      </Button>
    </>
  );
}

export default ButtonLoading;
