import { useQuery } from '@apollo/client'
import React from 'react'
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import Moment from 'react-moment'
import { GET_NOTIFICATION_BY_VENDORID } from '../../graphql/Query'

export default function VendorNotification() {
    const vendorId = localStorage.getItem('vendorId')
    const {data:notificationData,loading:notificationDataLoading} = useQuery(GET_NOTIFICATION_BY_VENDORID,{
        variables:{
            "vendorId": `${vendorId}`
        }
    })
    console.log('notificationData',notificationData)
  return (
    <>
      <Container>
      <h5 style={{ textAlign: 'center' }}>Booking Notifications</h5>
                <hr></hr>
                <Row>
                    <Col md={1}></Col>

                    <Col md={10}>
                        <Card style={{ padding: '20px' }}>
                            {
                                notificationDataLoading ?
                                    <>

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                    :
                                    notificationData && notificationData.getNotificationByVendorID.slice(0).reverse().map(notDAta =>
                                        <>

                                            <Row>
                                                <Col md={2}>
                                                    <div style={{ height: '100px', width: '120px' }}>
                                                        <Image classNAme='mx-auto d-block' src="https://s.clipartkey.com/mpngs/s/10-102584_clipart-free-download-best-ganpati-clipart.png" style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <h5 style={{ textAlign: 'center', fontSize: '13px', marginTop: '20px' }}>{ notDAta.title}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>{notDAta.description}</h5>
                                                    {/* <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Time: {notDAta.scheduleTime}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Service Name: {notDAta.serviceName}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Status: <span style={{color:'green'}}>{notDAta.status}</span></h5> */}
                                                </Col>
                                                <Col md={4}>
                                                    <h6 style={{ textAlign: 'center', fontSize: '12px', marginTop: '20px' }}>Booking Date & Time</h6>
                                                    <p style={{ textAlign: 'center', fontSize: '12px' }}><Moment format="DD-MM-YYYY hh:mm a">{notDAta.createDateTime}</Moment></p>
                                        
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                        </>
                                    )

                            }
                        </Card>
                    </Col>
                    <Col md={1}></Col>
                </Row>
      </Container>
    </>
  )
}
