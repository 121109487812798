import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { FaAngleRight, FaCog, FaEdit, FaTrashAlt } from "react-icons/fa";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_USERBY_USERID } from "../../graphql/Query";
import { MUTATION_UPDATE_USER } from "../../graphql/Mutation";
import { Navigate } from "react-router-dom";
import swal from "sweetalert";
import { Item, MyAPI, MyError } from "../../helper/api";
import s3FileUpload, { deleteFileFromS3 } from "../../helper/imageUploader";
import Loading from "../Loading";
import ButtonLoading from "../ButtonLoading";

export default function UserProfile() {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [showProfileField, setShowProfileField] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  const getProfile = async () => {
    let res = await MyAPI.GET("/user/profile", Item.getItem("token"));
    setIsLoading(false);
    let { status, message, data } = res.data || res;
    let {
      name,
      phone,
      email,
      whatsapp,
      address,
      state,
      district,
      city,
      pincode,
      image,
    } = data;

    setName(name);
    setPhone(phone);
    setEmail(email);
    setWhatsapp(whatsapp);
    setAddress(address);
    setState(state);
    setDistrict(district);
    setCity(city);
    setPincode(pincode);
    setImage(image);

    if (status === true) {
      setUser(data);
    } else {
      MyError.error(message);
    }
    console.log("profile api res : ", res);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [deleteProfileModel, setDeleteProfileModel] = useState(false);
  const [btnLoaing,setButtonLoading] = useState(false);

  // edit profile model
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // edit password model
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleSubmit = (event) => {};

  const handleUpdateProfile = async () => {
    if (!phone.trim() || phone.length !== 10) {
      MyError.error("Please enter a valid phone number.");
      return;
    }

    // Validate email
    if (!email.trim()) {
      MyError.error("Please enter a valid email address.");
      return;
    }

    if (!name.trim()) {
      MyError.error("Please enter a name.");
      return;
    }

    if (!whatsapp.trim() || whatsapp.length !== 10) {
      MyError.error("Please enter a valid WhatsApp number.");
      return;
    }

    if (!address.trim()) {
      MyError.error("Please enter an address.");
      return;
    }

    if (!state.trim()) {
      MyError.error("Please enter a state.");
      return;
    }

    if (!district.trim()) {
      MyError.error("Please enter a district.");
      return;
    }

    if (!city.trim()) {
      MyError.error("Please enter a city.");
      return;
    }

    if (!pincode.trim()) {
      MyError.error("Please enter a pincode.");
      return;
    }

    setButtonLoading(true);

    let uploadedProfileImage = "";

    try {
      if (showProfileField) {
        uploadedProfileImage = await s3FileUpload(profileImage);
        await deleteFileFromS3(image);
      }

      const data = {
        name,
        phone,
        email,
        whatsapp,
        address,
        state,
        district,
        city,
        pincode,
        image: showProfileField ? uploadedProfileImage : image,
      };

      let res = await MyAPI.PUT("/user/update", data, Item.getItem("token"));
      setButtonLoading(false);
      let { status, message } = res.data || res;
      setIsLoading(false);
      if (status === true) {
        getProfile();
        MyError.success(message);
        setShow(!show);
      } else {
        MyError.error(message);
      }
    } catch (error) {
      setIsLoading(false);
      MyError.error(error.message);
    }
  };

  return (
    <>
    <Loading isLoading={isLoading} />
      <Container>
        <Row>
          <Col md={6}>
            {name && name === "" ? (
              <h5>Hello User</h5>
            ) : (
              <h5>Welcome, {name && name}</h5>
            )}
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}
            >
              <span className="d-inline-block">
                <FaEdit
                  onClick={handleShow}
                  className="cursor-pointer"
                  style={{ height: "20px", width: "20px", marginRight: "30px" }}
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Setting</Tooltip>}
            >
              <span className="d-inline-block">
                <FaCog
                  onClick={handleShow2}
                  className="cursor-pointer"
                  style={{ height: "20px", width: "20px", marginRight: "30px" }}
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}
            >
              <span className="d-inline-block">
                <FaTrashAlt
                  onClick={() => setDeleteProfileModel(true)}
                  className="cursor-pointer text-danger"
                  style={{ height: "20px", width: "20px" }}
                />
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
        <hr></hr>
        <Row className="mt-5">
          <Col md={4}>
            <div
              className="mx-auto d-block border p-1"
              style={{ height: "200px", width: "200px", borderRadius: "50%" }}
            >
              <Image
                src={
                  image
                    ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${image}`
                    : "https://st3.depositphotos.com/15648834/17930/v/450/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                }
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
              ></Image>
            </div>
          </Col>
          <Col md={4}>
            <h4
              style={{
                fontSize: "14px",
                textAlign: "left",
                marginTop: "20px",
                color: "#032276",
              }}
            >
              {name && name}
            </h4>
            <p style={{ fontSize: "13px" }}>{phone && phone}</p>
            <p style={{ fontSize: "13px" }}>{email && email}</p>
            <h4
              style={{
                fontSize: "14px",
                textAlign: "left",
                marginTop: "20px",
                color: "#032276",
              }}
            >
              {city && city}
            </h4>
            <p style={{ fontSize: "13px" }}>
              {" "}
              {address && address} {city && city} {district && district}{" "}
              {state && state}
            </p>
            <p style={{ fontSize: "13px" }}>{pincode && pincode}</p>
          </Col>
        </Row>
      </Container>

      <Modal
        show={show}
        size="lg"
        className="p-0"
        style={{ marginTop: "7vh" }}
        onHide={handleClose}
      >
        <Modal.Body className="p-0 m-0">
          <div
            style={{
              border: "1px solid #022279",
              padding: "15px",
              borderRadius: "15px",
              margin: "20px",
            }}
          >
            <h6 style={{ marginTop: "15px", textAlign: "center" }}>
              Update Profile Here
            </h6>
            <hr></hr>

            <Col
              noValidate
              onSubmit={handleSubmit}
              style={{ fontSize: "14px", margin: "30px 0px 0px 0px" }}
            >
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label>
                      Full Name{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="First name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom01">
                    <Form.Label>
                      Email{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="phone">
                    <Form.Label>
                      Phone{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="whatsapp">
                    <Form.Label>
                      WhatsApp{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="WhatsApp"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom02">
                    <Form.Label>
                      Address{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom03">
                    <Form.Label>
                      City{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      required
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom04">
                    <Form.Label>
                      State{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      required
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group as={Col} controlId="validationCustom04">
                    <Form.Label>
                      Pincode{" "}
                      <span style={{ color: "red", marginTop: "10px" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Pincode"
                      required
                      onChange={(e) => setPincode(e.target.value)}
                      value={pincode}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Profile picture field */}

              <Row>
                <Col md={6} className="mb-3">
                  <Form.Check
                    type="switch"
                    id="toggleProfileField"
                    label="Upload Profile Picture"
                    checked={showProfileField}
                    onChange={() => setShowProfileField(!showProfileField)}
                    className="mb-3"
                  />
                </Col>
                {showProfileField && (
                  <>
                    <Col md={6} className="mb-3">
                      <Form.Control
                        type="file"
                        onChange={(e) => setProfileImage(e.target.files[0])}
                      />
                    </Col>
                  </>
                )}
              </Row>

              {btnLoaing ? (
                <ButtonLoading />
              ) : (
                <Button
                  className="mx-auto d-block text-white"
                  type="submit"
                  onClick={handleUpdateProfile}
                  style={{
                    backgroundColor: "#f39c12",
                    border: "none",
                    color: "#000",
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Col>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        size="md"
        className="p-0"
        style={{ marginTop: "10vh" }}
        onHide={handleClose2}
      >
        <Modal.Body className="p-0 m-0">
          <div
            style={{
              border: "1px solid #022279",
              padding: "15px",
              borderRadius: "15px",
              margin: "20px",
            }}
          >
            <h6 style={{ marginTop: "15px", textAlign: "center" }}>
              Change Password
            </h6>
            <hr></hr>

            <Form
              noValidate
              onSubmit={handleSubmit}
              style={{ fontSize: "14px", margin: "30px 0px 0px 0px" }}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>
                    Old Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Old Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mt-3"
                  as={Col}
                  md="12"
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    New Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enter New Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mt-3"
                  as={Col}
                  md="12"
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    Confirm Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Confirm Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              {isLoading ? (
                <Spinner
                  className="mx-auto d-block"
                  animation="border"
                  variant="secondary"
                />
              ) : (
                <Button
                  className="mx-auto d-block text-white"
                  type="submit"
                  style={{
                    backgroundColor: "#f39c12",
                    border: "none",
                    color: "#000",
                  }}
                >
                  Save Changes
                </Button>
              )}
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteProfileModel}
        size="md"
        className="p-0"
        style={{ marginTop: "20vh" }}
        onHide={() => setDeleteProfileModel(false)}
      >
        <Modal.Body className="p-0 m-0">
          <div className="px-3 py-3">
            <h6 style={{ marginTop: "15px", textAlign: "center" }}>
              Delete Account
            </h6>
            <hr></hr>
            <p>
              Are you sure you want to delete this account? This action can't be
              undu.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-sm btn-danger">Delete</Button>
          <Button
            onClick={() => setDeleteProfileModel(false)}
            className="btn btn-sm btn-primary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
