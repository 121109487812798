import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Button, Container, Modal, Spinner, Table } from 'react-bootstrap'
import { GET_ALL_USER } from '../../graphql/Query'
import Moment from 'react-moment';
import { FaEye } from 'react-icons/fa';
import { Item, MyAPI, MyError } from '../../helper/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';

export default function AllUsers() {

    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(true);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [viewUser, setViewUser] = useState()

    function handleViewUser(data) {
        setViewUser(data)
        handleShow()
    }


    const [userList, setUserList] = useState(null);

    const getUserList = async () => {
        let res = await MyAPI.GET('/admin/users', Item.getItem('token'));
        setIsLoading(false);
        let { status, message, data } = res.data || res;
        if (status === true) {
            console.log('get all users ', data);
            setUserList(data);
        } else {
            MyError.error(message);
        }
    };

    useEffect(() => {
        getUserList();
    }, [])

    const navigateToUserProfile = (id) =>{
        navigate(`/admindashboard/user/${id}`);
    };

    return (
        <>
        <Loading isLoading={isLoading} />
            <Container fluid>
                <h6 style={{ textAlign: 'center' }}>All Users</h6>
                <hr></hr>
                <Table bordered responsive="sm" style={{ borderBottom: '1px solid #000', border: '1px solid #000', fontSize: '13px' }}>
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th>id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Date/Time</th>
                            <th colSpan={3}>Action</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            userList && userList.length>0 && userList.map((user,index)=>(
                                <tr style={{ textAlign: 'center' }}>
                                <td>{index + 1}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td><Moment format="DD/MM/YYYY, hh:mm a">{user.createdDateTime}</Moment></td>
                                <td className='cursor-pointer' style={{ textAlign: 'center', color: '#032276 ' }}><FaEye size={22} onClick={() => navigateToUserProfile(user._id)} /></td>
                            </tr>
                            ))
                        }

                        {
                            userList && userList.length === 0 && (
                                <td colSpan={6}>users not found. Please add new users.</td>
                            )
                        }

                        {/* {
                            allUserDataloading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                allUserData && allUserData.getUser.map(userDqta =>
                                    <tr style={{ textAlign: 'center' }}>

                                        <td>{userDqta.fName} {userDqta.lName}</td>
                                        <td>{userDqta.contact}</td>
                                        <td><Moment format="DD/MM/YYYY, hh:mm a">{userDqta.createdDateTime}</Moment></td>
                                        <td style={{ textAlign: 'center', color: '#032276 ' }}><FaEye onClick={() => handleViewUser(userDqta)} /></td>
                                    </tr>

                                )
                        } */}
                    </tbody>
                </Table>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
