import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../ComponentsCss/Statics.css'
import CountUp from 'react-countup';

export default function Statics() {
    return (
        <>
            <Container className='staticmain'>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        <p style={{ textAlign: 'center', fontWeight: 600, color: '#ffb600' }}> S T A T I C S</p>

                        <h3 style={{ textAlign: 'center', color: '#fff', fontWeight: 600, margin: '15px' }}>Trusted by thousands of people all over the world</h3>
                        <p style={{ color: '#fff', textAlign: 'center', margin: '30px',fontSize:'12px' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <Row>
                            <Col md={3}>
                                <div className='numberstatics'>
                                    <h1 style={{ textAlign: 'center', fontSize: '60px', color: '#7748ee', fontWeight: 800 }}><CountUp delay={1} end={35} /></h1>
                                    <p style={{ textAlign: 'center', color: '#7748ee', fontSize: 20 }}>Providers</p>

                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='numberstatics'>
                                    <h1 style={{ textAlign: 'center', fontSize: '60px', color: '#62b815', fontWeight: 800 }}><CountUp delay={1} end={19} /></h1>
                                    <p style={{ textAlign: 'center', color: '#62b815', fontSize: 20 }}>Customers</p>

                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='numberstatics'>
                                    <h1 style={{ textAlign: 'center', fontSize: '60px', color: '#feb92d', fontWeight: 800 }}><CountUp delay={1} end={3} /></h1>
                                    <p style={{ textAlign: 'center', color: '#feb92d', fontSize: 20 }}>Jobs</p>

                                </div>
                            </Col>
                            <Col md={3}>
                        
                                    <h1 style={{ textAlign: 'center', fontSize: '60px',marginTop:'35px', color: '#2dbefd', fontWeight: 800 }}><CountUp delay={1} end={49} /></h1>
                                    <p style={{ textAlign: 'center', color: '#2dbefd', fontSize: 20 }}>Categories</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </>
    )
}
