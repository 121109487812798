import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import {
  FaBars,
  FaBell,
  FaChevronDown,
  FaCog,
  FaHome,
  FaProductHunt,
  FaShoppingBag,
  FaShoppingCart,
  FaUserAlt,
} from "react-icons/fa";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import "../../ComponentsCss/Usercss/UserDashboard.css";
import { Item, MyError } from "../../helper/api";

export default function VendorDashboard() {
  const navigation = useNavigate();

  let token = Item.getItem('token');
  let Iam = Item.getItem('Iam');

  useEffect(() => {
    if (!token || Iam !== 'vendor') {
      navigation("/");
      Item.removeItem("token");
      Item.removeItem("Iam");
      MyError.error('Unauthorized user. Please log in as a vendor!');
    }
  }, [token, Iam]);


  function handleDown() {
    const listItems = document.querySelectorAll(".panditsidebar-list li");

    listItems.forEach((item) => {
      item.addEventListener("click", () => {
        let isActive = item.classList.contains("active");

        listItems.forEach((el) => {
          el.classList.remove("active");
        });

        if (isActive) item.classList.remove("active");
        else item.classList.add("active");
      });
    });
  }

//   if (!vendorToken) {
//     return <Navigate to="/vendorlogin" />;
//   }

  function handleClick() {
    const toggleSidebar = document.querySelector(".toggle-panditsidebar");
    const panditlogo = document.querySelector(".panditlogo-box");
    const panditsidebar = document.querySelector(".panditsidebar");
    toggleSidebar.addEventListener("click", () => {
      panditsidebar.classList.toggle("close");
    });

    panditlogo.addEventListener("click", () => {
      panditsidebar.classList.toggle("close");
    });
  }

  const userlogoutHandle = () => {
    Item.removeItem('token');
    Item.removeItem('Iam');
    navigation("/");
  };

  return (
    <>
      <div className="panditsidebar close">
        {/* <!-- ========== Logo ============  --> */}
        <Link to="/" className="panditlogo-box">
          <i className="bx bxl-xing">
            <Image
              src="https://as2.ftcdn.net/v2/jpg/01/09/50/17/1000_F_109501700_MITG2HmANGaUyRUEDg5IvmQvwnmLmLEz.jpg"
              style={{ height: "70%", width: "50%" }}
            />
          </i>
          <div className="panditlogo-name">Pandit Bulao</div>
        </Link>

        {/* <!-- ========== List ============  --> */}
        <ul className="panditsidebar-list">
          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/" className="link">
                <i className="bx bx-grid-alt">
                  <FaHome />
                </i>
                <span className="name">Home</span>
              </Link>
              {/* <!-- <i class='bx bxs-chevron-down'></i> --> */}
            </div>
            <div className="submenu">
              <Link to="/" className="link submenu-title">
                Home
              </Link>
              {/* <!-- submenu links here  --> */}
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/vendordashboard/profile" className="link">
                <i className="bx bx-line-chart">
                  <FaUserAlt />
                </i>
                <span className="name">Profile</span>
              </Link>
              {/* <!-- <i class='bx bxs-chevron-down'></i> --> */}
            </div>
            <div className="submenu">
              <Link
                to="/vendordashboard/profile"
                className="link submenu-title"
              >
                Profile
              </Link>
              {/* <!-- submenu links here  --> */}
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/vendordashboard/notifications" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  <FaBell />
                </i>
                <span className="name">Notifications</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/vendordashboard/notifications"
                className="link submenu-title"
              >
                Notifications
              </Link>
            </div>
          </li>
          {/* <!-- -------- Dropdown List Item ------- --> */}
          {/* <li className="dropdown">
                        <div className="title">
                            <Link href="#" className="link">
                                <i className='bx bx-collection'><FaMailBulk /></i>
                                <span className="name">Category</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link href="#" className="link submenu-title">Category</Link>
                            <Link href="#" className="link">HTML & CSS</Link>
                            <Link href="#" className="link">JavaScript</Link>
                            <Link href="#" className="link">PHP & MySQL</Link>
                        </div>
                    </li> */}

          {/* <!-- -------- Dropdown List Item ------- --> */}
          <li className="dropdown">
            <div className="title">
              <Link to="/vendordashboard/bookings" className="link">
                <i className="bx bx-book-alt">
                  <FaShoppingCart />
                </i>
                <span className="name">Bookings</span>
              </Link>
              <i className="bx bxs-chevron-down" onClick={() => handleDown()}>
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              {/* <Link to="/orders" className="link submenu-title">Orders</Link> */}
              <Link to="/vendordashboard/bookings" className="link">
                Pending Booking
              </Link>
              <Link to="/vendordashboard/completebookings" className="link">
                Complete Booking
              </Link>
              <Link to="/vendordashboard/cancelbookings" className="link">
                Cancelled Booking
              </Link>
            </div>
          </li>

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/admindashboard/payments" className="link">
                <i className="bx bx-line-chart">
                  <FaProductHunt />
                </i>
                <span className="name">Payments</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/payments"
                className="link submenu-title"
              >
                Payments
              </Link>
            </div>
          </li>

          {/* <li>
                        <div className="title">
                            <Link to="/admindashboard/usercontact" className="link">
                                <i className='bx bx-pie-chart-alt-2'><FaOdnoklassniki /></i>
                                <span className="name">Contacts</span>
                            </Link>
                           
                        </div>
                        <div className="submenu">
                            <Link to="/admindashboard/usercontact" className="link submenu-title">Contacts</Link>
                           
                        </div>
                    </li> */}

          {/* <!-- -------- Dropdown List Item ------- --> */}
          {/* <li className="dropdown">
                        <div className="title">
                            <Link to="/admindashboard/contactsuser" className="link">
                                <i className='bx bx-extension'><FaPlug /></i>
                                <span className="name">Contacts</span>
                            </Link>
                            <i className='bx bxs-chevron-down' onClick={() => handleDown()}><FaChevronDown /></i>
                        </div>
                        <div className="submenu">
                            <Link to="/admindashboard/contactsuser" className="link submenu-title">Contacts</Link> */}
          {/* <Link href="#" className="link">UI Face</Link>
                            <Link href="#" className="link">Pigments</Link>
                            <Link href="#" className="link">Box Icons</Link> */}
          {/* </div>
                    </li> */}

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          {/* <li>
                        <div className="title">
                            <Link href="#" className="link">
                                <i className='bx bx-compass'><FaWpexplorer /></i>
                                <span className="name">Explore</span>
                            </Link>
                       
                        </div>
                        <div className="submenu">
                            <Link href="#" className="link submenu-title">Explore</Link>
                           
                        </div>
                    </li> */}

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          {/* <li>
                        <div className="title">
                            <Link to="/admindashboard/uploadproducts" className="link">
                                <i className='bx bx-history'><FaHistory /></i>
                                <span className="name">UploadProducts</span>
                            </Link>
                            
                        </div>
                        <div className="submenu">
                            <Link to="/admindashboard/uploadproducts" className="link submenu-title">UploadProducts</Link>
                            
                        </div>
                    </li> */}

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/admindashboard/adminsettings" className="link">
                <i className="bx bx-cog">
                  <FaCog />
                </i>
                <span className="name">Settings</span>
              </Link>
              {/* <!-- <i class='bx bxs-chevron-down'></i> --> */}
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/adminsettings"
                className="link submenu-title"
              >
                Settings
              </Link>
              {/* <!-- submenu links here  --> */}
            </div>
          </li>
        </ul>
      </div>

      {/* <!-- ============= Home Section =============== --> */}
      <section className="pandithome">
        <div className="toggle-panditsidebar d-flex align-items-center justify-content-between px-3">
          <FaBars
            size={22}
            className="bx bx-menu"
            onClick={() => handleClick()}
          />
          <Button
            style={{
              backgroundColor: "transparent",
              color: "#000",
              fontSize: "12px",
            }}
            onClick={() => userlogoutHandle()}
          >
            logout
          </Button>
        </div>

        <Container style={{ padding: "20px" }} fluid>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
