import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaCog, FaEdit, FaTrash, FaTrashAlt } from "react-icons/fa";
import {
  MUTATION_CREATE_VENDER_SERVICES,
  MUTATION_DELETE_VENDOR_SERVICES,
  MUTATION_UPDATE_VENDOR,
} from "../../graphql/Mutation";
import swal from "sweetalert";
import { QUERY_GET_VENDOR_BY_ID } from "../../graphql/Query";
import AWS from "aws-sdk";
import uniqid from "uniqid";
import { Item, MyAPI, MyError } from "../../helper/api";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

const ID = "AKIA6GB4RFKTM63VVHEK";
const SECRET = "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";
const BUCKET_NAME = "panditbulao";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

const imageKey = [];

export default function VenderProfile() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const vendorId = localStorage.getItem("vendorId");

  const [serviceName, setServiceName] = useState("");
  const [servicePrice, setServicePrice] = useState("");

  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  //   const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [alterNativeContact, setAlterNativeContact] = useState("");
  //   const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  //   const [city, setCity] = useState("");
  //   const [state, setState] = useState("");
  //   const [pincode, setPincode] = useState("");
  const [experience, setExperience] = useState("");
  const [description, setDescription] = useState("");
  const [availability, setAvailable] = useState("");
  const [availableTiming, setAvailableTiming] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [addressText, setAddressText] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [poojaImages, setPoojaImages] = useState([]);
  const [panditExp, setPanditExp] = useState("");
  const [uploadedPoojaImages, setUploadedPoojaImages] = useState([]);
  const [poojas, setPoojas] = useState([
    {
      poojaID: "",
      price: "",
      exp: "",
      duration: "",
    },
  ]);

  const [updateVendor, { loading: updatevendorLoading }] = useMutation(
    MUTATION_UPDATE_VENDOR
  );

  const [validated2, setValidated2] = useState(false);
  const [imageBox, setImageBox] = useState("");
  const [selectImage, setSelectImage] = useState();
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleVendorUpdate = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated2(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated2(false);
      event.preventDefault();
      // try {
      //     const uniqueId = uniqid();
      //     var regex = new RegExp('[^.]+$');
      //     var extension = selectImage.name.match(regex);
      //     var uniqueFileName = uniqueId + '-' + uniqueId + '.' + extension[0];
      //     setImageBox(uniqueFileName)
      //     const params = {
      //         Bucket: BUCKET_NAME,
      //         Key: uniqueFileName,
      //         Body: selectImage,
      //     };
      //     s3.upload(params, function (err, data) {
      //         if (err) {
      //             throw err;
      //         } else {
      updateVendor({
        variables: {
          vendorUpdateInput: {
            id: `${vendorId}`,
            fName: `${fName}`,
            lName: `${lName}`,
            contact: `${contact}`,
            username: null,
            password: null,
            image: null,
            alternativeContact: `${alterNativeContact}`,
            email: `${email}`,
            address: `${address}`,
            city: `${city}`,
            state: `${state}`,
            pincode: parseInt(pincode),
            experience: `${experience}`,
            description: `${description}`,
            availability: `${availability}`,
            availableTiming: `${availableTiming}`,
          },
        },
      });

      swal({
        title: "Added",
        text: "Your Profile is Updated",
        icon: "success",
      });
      handleClose();
    }
    //     });
    // }
    //     catch (err) {
    //         swal({
    //             title: 'Error!!!',
    //             text: 'Error please try again',
    //             icon: 'error',
    //         });
    //         console.log(err);
    //         setUploadLoading(false)
    //     }
    // }
  };

  const { data: vendorData, loading: allVendorsLoading } = useQuery(
    QUERY_GET_VENDOR_BY_ID,
    {
      variables: {
        vendorId: `${vendorId}`,
      },
    }
  );
  console.log("vendorData", vendorData);
  const [createVendorService, { loading: serviceLoading }] = useMutation(
    MUTATION_CREATE_VENDER_SERVICES,
    {
      refetchQueries: [QUERY_GET_VENDOR_BY_ID],
    }
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setValidated(false);
      createVendorService({
        variables: {
          vServiceInput: {
            services: [
              {
                serviceType: `${serviceName}`,
                servicePrice: `${servicePrice}`,
                serviceStatus: "active",
              },
            ],
            vendorId: `${vendorId}`,
          },
        },
      });
      swal({
        title: "Done",
        text: "Service Add Successfully",
        icon: "success",
      });
      setServiceName("");
      setServicePrice("");
    }
  };

  const [deleteServices] = useMutation(MUTATION_DELETE_VENDOR_SERVICES, {
    refetchQueries: [QUERY_GET_VENDOR_BY_ID],
  });
  const [vendorDelete, setVendorDelete] = useState("");

  function showdeleteHAndle(id) {
    setVendorDelete(id);
    handleShow2();
  }

  function handleDeleteService(id) {
    deleteServices({
      variables: {
        vendorId: `${vendorId}`,
        vServiceId: `${id}`,
      },
    });
    swal("Deleted!", "Your imaginary file has been deleted!", "success");
    handleClose2();
  }


  const [deleteProfileModel, setDeleteProfileModel] = useState(false);
  const [user_id, setUserId] = useState(null);

  const getUserDestails = async () => {
    let res = await MyAPI.GET("/pandit/pandit", Item.getItem("token"));
    setIsLoading(false);
    let { status, message, data } = res.data || res;
    if (status === true) {
      setUserId(data._id);
      setImage(data.image);
      setName(data.name);
      setUsername(data.username);
      setEmail(data.email);
      setPhoneNumber(data.phone_number);
      setWhatsappNumber(data.whatsapp_number);
      setAddressText(data.address_text);
      setState(data.address_fields.state);
      setDistrict(data.address_fields.district);
      setCity(data.address_fields.city);
      setPincode(data.address_fields.pincode);
      setPanditExp(data.exp);
      setPoojas(data.poojas);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    getUserDestails();
  }, []);

  const handleDeleteMyAccount = async () => {
    let res = await MyAPI.DELETE("/pandit/pandit", Item.getItem("token"));
    let { status, message } = res.data || res;
    if (status === true) {
      Item.removeItem("token");
      Item.removeItem("Iam");
      MyError.success("Account Deleted Successfully.");
      navigate("/");
    } else {
      MyError.error(message);
    }
    console.log(res);
  };

  return (
    <>
    <Loading isLoading={isLoading} />
      <Container>
        <Row>
          <Col md={6}>
            {name === "" ? <h6>Hello Vendor</h6> : <h6>Hello {name} Ji</h6>}
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Edit Profile</Tooltip>}
            >
              <span className="d-inline-block">
                <FaEdit
                  size={22}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/vendordashboard/profile/${user_id}`)
                  }
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Setting</Tooltip>}
            >
              <span className="d-inline-block ms-3">
                <FaCog
                  size={22}
                  onClick={handleShow3}
                  className="cursor-pointer"
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Delete Profile</Tooltip>}
            >
              <span className="d-inline-block">
                <FaTrashAlt
                  size={22}
                  onClick={() => setDeleteProfileModel(true)}
                  className="cursor-pointer text-danger ms-3"
                />
              </span>
            </OverlayTrigger>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col md={4}>
            <Card
              style={{
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: "2px 2px 2px 2px #7f8c8d",
                borderBottom: "5px solid #feb92d",
                fontSize: "13px",
              }}
            >
              <Table hover style={{ textAlign: "center" }}>
                <thead style={{ margin: "20px" }}>
                  <tr>
                    <th>Pooja Name</th>
                    <th>Price</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody style={{ margin: "20px" }}>
                  {!poojas ? (
                    <Spinner
                      className="mx-auto d-block"
                      animation="border"
                      variant="warning"
                    />
                  ) : (
                    poojas &&
                    poojas.map((pooja, index) => (
                      <tr>
                        <td>{pooja.poojaID}</td>
                        <td>{pooja.price}</td>
                        <td>
                          <FaTrash
                            className="cursor-pointer"
                            style={{ color: "#b33939" }}
                            onClick={() => showdeleteHAndle(pooja.id)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
            <Card
              style={{
                padding: "15px",
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: "2px 2px 2px 2px #7f8c8d",
                borderBottom: "5px solid #feb92d",
                fontSize: "13px",
              }}
            >
              {allVendorsLoading ? (
                <Spinner
                  className="mx-auto d-block"
                  animation="border"
                  variant="warning"
                />
              ) : (
                <ListGroup variant="flush">
                  <ListGroup.Item>Name : {name}</ListGroup.Item>
                  <ListGroup.Item>Phone No. : {phoneNumber}</ListGroup.Item>
                  <ListGroup.Item>
                    WhatsApp No. : {whatsappNumber}
                  </ListGroup.Item>
                  <ListGroup.Item>Email : {email}</ListGroup.Item>
                  <ListGroup.Item>Address : {addressText}</ListGroup.Item>
                  <ListGroup.Item>City : {city}</ListGroup.Item>
                  <ListGroup.Item>State : {state}</ListGroup.Item>
                  <ListGroup.Item>Pincode : {pincode}</ListGroup.Item>
                  <ListGroup.Item>Experience : {panditExp}</ListGroup.Item>
                </ListGroup>
              )}
            </Card>
          </Col>

          <Col md={3}>
            <Card
              className="mx-auto d-block"
              style={{
                height: "170px",
                width: "170px",
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: "2px 2px 2px 2px #7f8c8d",
                borderBottom: "5px solid #feb92d",
              }}
            >
              <Image
                src="https://cdni.iconscout.com/illustration/premium/thumb/pandit-ji-2704960-2252413.png"
                style={{ height: "100%", width: "100%" }}
              />
            </Card>
          </Col>
          <Col md={4}>
            <Card
              style={{
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: "2px 2px 2px 2px #7f8c8d",
                borderBottom: "5px solid #feb92d",
                padding: "20px",
                fontSize: "13px",
              }}
            >
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h6 style={{ textAlign: "center" }}>Add Services Here</h6>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Service Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => setServiceName(e.target.value)}
                      value={serviceName}
                      style={{ fontSize: "13px" }}
                    >
                      <option>Choose Any One</option>
                      <option value="Ganesh Pooja">Ganesh Pooja</option>
                      <option value="Laxmi Pooja">Laxmi Pooja</option>
                      <option value="Satyanarayan Pooja">
                        Satyanarayan Pooja
                      </option>
                      <option value="Grahpravesh Pooja">
                        Grahpravesh Pooja
                      </option>
                      <option value="Vastu Pooja">Vastu Pooja</option>
                      <option value="Birthday Pooja">Birthday Pooja</option>
                      <option value="Namkaran Pooja">Namkaran Pooja</option>
                      <option value="Shadi Pooja">Shadi Pooja</option>
                      <option value="Shivratri Pooja">Shivratri Pooja</option>
                      <option value="Tulsi Vivah Pooja">
                        Tulsi Vivah Pooja
                      </option>
                      <option value="Satyanarayan Pooja">Havan Pooja</option>
                      <option value="Diwali Pooja">Diwali Pooja</option>
                      <option value="Annapurna Pooja">Annapurna Puja</option>
                      <option value="Bhoomi Puja">Bhoomi Puja</option>
                      <option value="Brihaspati Vrat Pooja">
                        Brihaspati Vrat Pooja
                      </option>
                      <option value="Durga Pooja">Durga Pooja</option>
                      <option value="Engagement Pooja">Engagement Pooja</option>
                      <option value="Nakashtra Shanti Pooja">
                        Nakashtra Shanti Pooja
                      </option>
                      <option value="God Bharai Pooja">God Bharai Pooja</option>
                      <option value="Haldi Ceremony">Haldi Ceremony</option>
                      <option value="Kuber Upasana Pooja">
                        Kuber Upasana Pooja
                      </option>
                      <option value="Mundan Ceremony">Mundan Ceremony</option>
                      <option value="New Vehicle Pooja">
                        New Vehicle Pooja
                      </option>
                      <option value="Office Openning Pooja">
                        Office Openning Pooja
                      </option>
                      <option value="Pusavn Sankar Pooja">
                        Pusavn Sankar Pooja
                      </option>
                      <option value="Roka Ceremony">Roka Ceremony</option>
                      <option value="Rudrabhishek Pooja">
                        Rudrabhishek Pooja
                      </option>
                      <option value="Saraswati Pooja">Saraswati Pooja</option>
                      <option value="Suddhi Karan Pooja">
                        Suddhi Karan Pooja
                      </option>
                      <option value="Solah Sombar Udyapan">
                        Solah Sombar Udyapan
                      </option>
                      <option value="Sutya Pooja">Sutya Pooja</option>
                      <option value="Vishwakarma Pooja">
                        Vishwakarma Pooja
                      </option>
                      <option value="Yagnopavit Sanskar">
                        Yagnopavit Sanskar
                      </option>

                      <option value="Ayush Havan">Ayush Havan</option>
                      <option value="Chandi Havan">Chandi Havan</option>
                      <option value="Dhanvanti Havan">Dhanvanti Havan</option>
                      <option value="Ganapati Havan">Ganapati Havan</option>
                      <option value="Gayatri Havan">Gayatri Havan</option>
                      <option value="Laxmi Kubera Havan">
                        Laxmi Kubera Havan
                      </option>
                      <option value="Rudra Havan">Rudra Havan</option>
                      <option value="Saraswati Havan">Saraswati Havan</option>
                      <option value="Sri Sukta Havan">Sri Sukta Havan</option>
                      <option value="Sudarshan Havan">Sudarshan Havan</option>

                      <option value="Budha Graha Shanti Jaap">
                        Budha Graha Shanti Jaap
                      </option>
                      <option value="Chandra Graha Shanti Jaap">
                        Chandra Graha Shanti Jaap
                      </option>
                      <option value="Gayatri Mantra Jaap">
                        Gayatri Mantra Jaap
                      </option>
                      <option value="Guru Graha Shanti Jaap">
                        Guru Graha Shanti Jaap
                      </option>
                      <option value="Ketu Graha Shanti Jaap">
                        Ketu Graha Shanti Jaap
                      </option>
                      <option value="Maha Mrityunjaya Jaap">
                        Maha Mrityunjaya Jaap
                      </option>
                      <option value="Mangal Graha Shanti Jaap">
                        Mangal Graha Shanti Jaap
                      </option>
                      <option value="Rahu Graha Shanti Jaap">
                        Rahu Graha Shanti Jaap
                      </option>
                      <option value="Santan Gopal Mantra Jaap">
                        Santan Gopal Mantra Jaap
                      </option>
                      <option value="Shani Graha Shanti Jaap">
                        Shani Graha Shanti Jaap
                      </option>
                      <option value="Shukra Graha Shanti Jaap">
                        Shukra Graha Shanti Jaap
                      </option>
                      <option value="Surya Graha Shanti Jaap">
                        Surya Graha Shanti Jaap
                      </option>

                      <option value="Akhand Ramayan Path">
                        Akhand Ramayan Path
                      </option>
                      <option value="Durga Saptashati Path">
                        Durga Saptashati Path
                      </option>
                      <option value="Hanuman Chalisa Path">
                        Hanuman Chalisa Path
                      </option>
                      <option value="Shanti Path">Shanti Path</option>
                      <option value="Sunderkand Path">Sunderkand Path</option>

                      <option value="Diwali Laxmi Puja">
                        Diwali Laxmi Puja
                      </option>
                      <option value="Dussehra Puja">Dussehra Puja</option>
                      <option value="Ganesh Sathapana and Visarjan Puja">
                        Ganesh Sathapana and Visarjan Puja
                      </option>
                      <option value="Hartalika Teej Puja">
                        Hartalika Teej Puja
                      </option>
                      <option value="Holika Puja">Holika Puja</option>
                      <option value="Janmashtami Puja">Janmashtami Puja</option>
                      <option value="Navaratri Kalash Sthapana Puja and Havan">
                        Navaratri Kalash Sthapana Puja and Havan
                      </option>

                      <option value="Ark Vivah for Male">
                        Ark Vivah for Male
                      </option>
                      <option value="Grahan Yog Shanti Puja">
                        Grahan Yog Shanti Puja
                      </option>
                      <option value="Guru Chandal Yoga Shanti">
                        Guru Chandal Yoga Shanti
                      </option>
                      <option value="Kaal Sarp Dash Puja">
                        Kaal Sarp Dash Puja
                      </option>
                      <option value="Kumbh Vivah for Female">
                        Kumbh Vivah for Female
                      </option>
                      <option value="Shani Chandra Vish Yog Nivaran">
                        Shani Chandra Vish Yog Nivaran
                      </option>
                      <option value="Shani Ketu Shrapati Dosh Nivaran">
                        Shani Ketu Shrapati Dosh Nivaran
                      </option>
                      <option value="Vishnu Vivah for Female">
                        Vishnu Vivah for Female
                      </option>
                      <option value="Vishti Karan Shanti Puja">
                        Vishti Karan Shanti Puja
                      </option>

                      <option value="Amavasya Turpan">Amavasya Turpan</option>
                      <option value="Antim Sanskar - Last Rites">
                        Antim Sanskar - Last Rites
                      </option>
                      <option value="Asthi Visarjan">Asthi Visarjan</option>
                      <option value="Barsi Ceremony">Barsi Ceremony</option>
                      <option value="Brahmins for Bhojan">
                        Brahmins for Bhojan
                      </option>
                      <option value="Garud Puran Path">Garud Puran Path</option>
                      <option value="Pitru Paksha (Mahalaya) Sharadh Puja">
                        Pitru Paksha (Mahalaya) Sharadh Puja
                      </option>
                      <option value="Shradh">Shradh</option>
                      <option value="Swayampaka / Seedha Daan for Brahmins">
                        Swayampaka / Seedha Daan for Brahmins
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Service Price</Form.Label>
                    <Form.Control
                      style={{ fontSize: "13px" }}
                      required
                      type="number"
                      placeholder="Enter Price"
                      onChange={(e) => setServicePrice(e.target.value)}
                      value={servicePrice}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                {serviceLoading ? (
                  <Spinner
                    className="mx-auto d-block"
                    animation="border"
                    variant="warning"
                  />
                ) : (
                  <Button
                    className="mx-auto d-block"
                    type="submit"
                    style={{
                      backgroundColor: "#ffb600",
                      color: "#000",
                      border: "none",
                    }}
                  >
                    Submit form
                  </Button>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body style={{ padding: "20px" }}>
          <Form noValidate validated={validated2} onSubmit={handleVendorUpdate}>
            <h6 style={{ textAlign: "center", margin: "20px" }}>
              Update Your Profile
            </h6>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Phone No.</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone No."
                    required
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>WhatsApp No.</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter WhatsApp No."
                    onChange={(e) => setWhatsappNumber(e.target.value)}
                    value={whatsappNumber}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Experience</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Experience"
                    required
                    onChange={(e) => setPanditExp(e.target.value)}
                    value={panditExp}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    required
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your State"
                    required
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Availability Timing"
                    required
                    onChange={(e) => setPincode(e.target.value)}
                    value={pincode}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Profile Picture</Form.Label>
                  <Form.Control type="file" placeholder="Enter" />
                </Form.Group>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Group as={Col} controlId="validationCustom01">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Address"
                    required
                    onChange={(e) => setAddressText(e.target.value)}
                    value={addressText}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="mx-auto d-block"
              type="submit"
              style={{
                backgroundColor: "#ffb600",
                color: "#000",
                border: "none",
              }}
            >
              Update Profile
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal size="sm" centered show={show2} onHide={handleClose2}>
        <Modal.Body className="px-3 py-3">
          <h5>Are You Sure You Want To Delete Service??</h5>
          <Button
            className="mx-auto d-block"
            type="submit"
            style={{
              backgroundColor: "#ffb600",
              color: "#000",
              border: "none",
            }}
            onClick={() => handleDeleteService(vendorDelete)}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        size="md"
        className="p-0"
        style={{ marginTop: "10vh" }}
        onHide={handleClose3}
      >
        <Modal.Body className="p-0 m-0">
          <div
            style={{
              border: "1px solid #022279",
              padding: "15px",
              borderRadius: "15px",
              margin: "20px",
            }}
          >
            <h6 style={{ marginTop: "15px", textAlign: "center" }}>
              Change Password
            </h6>
            <hr></hr>

            <Form
              noValidate
              // onSubmit={handleSubmit}
              style={{ fontSize: "14px", margin: "30px 0px 0px 0px" }}
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>
                    Old Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Old Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mt-3"
                  as={Col}
                  md="12"
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    New Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enter New Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mt-3"
                  as={Col}
                  md="12"
                  controlId="validationCustom01"
                >
                  <Form.Label>
                    Confirm Password{" "}
                    <span style={{ color: "red", marginTop: "10px" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Confirm Password"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button
                className="mx-auto d-block text-white"
                type="submit"
                style={{
                  backgroundColor: "#f39c12",
                  border: "none",
                  color: "#000",
                }}
              >
                Save Changes
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteProfileModel}
        size="md"
        className="p-0"
        style={{ marginTop: "20vh" }}
        onHide={() => setDeleteProfileModel(false)}
      >
        <Modal.Body className="p-0 m-0">
          <div className="px-3 py-3">
            <h6 style={{ marginTop: "15px", textAlign: "center" }}>
              Delete Account
            </h6>
            <hr></hr>
            <p>
              Are you sure you want to delete this account? This action can't be
              undu.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleDeleteMyAccount}
            className="btn btn-sm btn-danger"
          >
            Delete
          </Button>
          <Button
            onClick={() => setDeleteProfileModel(false)}
            className="btn btn-sm btn-primary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
