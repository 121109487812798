import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import { Container, Row, Col } from "react-bootstrap";
import { GoStar } from "react-icons/go";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { MyAPI, MyError } from "../../helper/api";
import PoojaCarousel from "./PoojaCarousel";
import Loading from "../Loading";

function PublicProfile() {
  let { id } = useParams();
  const [isLoading,setIsLoading] = useState(true);
  const [pandit, setPandit] = useState(null);
  const [panditPrice, setPanditPrice] = useState(0);

  const getPanditProfile = async (id) => {
    let res = MyAPI.GET(`/public/pandit?id=${id}`);
    setTimeout(()=>{
      setIsLoading(false);
    },3000)
    let { status, message, data } = (await res).data || res;
    if (status === true) {
      setPandit(data);
    } else {
      MyError.error(message);
    }
  };

  const calculatePrice = () => {
    if (pandit && pandit.poojas && pandit.poojas.length > 0) {
      let totalPrice = 0;
      pandit.poojas.forEach((pooja) => {
        totalPrice += parseInt(pooja.price);
      });
      const averagePrice = totalPrice / pandit.poojas.length;
      setPanditPrice(averagePrice);
    } else {
      // Handle case where pandit or poojas array is empty
      // You can set a default price or handle it as per your requirement
      setPanditPrice(0); // For instance, setting price to 0 if no poojas are present
    }
  };

  useEffect(() => {
    calculatePrice();
  }, [pandit]);

  useEffect(() => {
    if (id) {
      getPanditProfile(id);
    }
  }, [id]);

  const poojaPhotos = [
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeNuiM7mZ42XgzaUQpbDbpQa-LjGcOE7LAIw&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjcOoRHl5m3TMI4tUFpRk4pmsCWGgGnchhew&s",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOnVF8RQ71tr4x81ru8-kSi2IsRK8dfo6axA&s",
  ];

  return (
    <>
    <Loading isLoading={isLoading} />
      <Navigation />

      <Container className="shadow rounded-3 border border-2 py-3 pt-5">
        <Row>
          <Col
            md={4}
            className="d-flex align-items-start justify-content-center overflow-hidden"
          >
            <img
              src={
                pandit && pandit.image
                  ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${pandit.image}`
                  : "https://aws.astrotalk.com/consultant_pic/p-37885.jpg"
              }
              style={{
                width: "220px",
                height: "220px",
                borderRadius: "50%",
                objectFit: "cover",
                backgroundColor: "#DDDDDD",
              }}
              alt=""
            />
          </Col>
          <Col md={8} className="ps-5 mt-3 ps-md-0 ms-lg-0 ms-xl-0 ms-xxl-0">
            <h5>
              {pandit && pandit.name ? pandit.name : ""}{" "}
              <img
                src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/25x25/assets/images/astrologer_profile/verified.webp"
                alt=""
                srcset=""
              />{" "}
            </h5>
            <p className="m-0">Vedic, Numerology, Prashana</p>
            <p className="m-0">English, Hindi, Gujarati</p>
            <p className="m-0">
              Exp: {pandit && pandit.exp ? pandit.exp : ""} Years
            </p>
            <p>
              <b>₹ {panditPrice} /- </b> ( INR )
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="ps-5 pt-3" md={12}>
            <h4>About : </h4>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              nobis veritatis officiis sint qui natus non, iste voluptate
              deleniti inventore nesciunt consequuntur unde neque voluptates
              sapiente adipisci. Similique temporibus vero quos laborum,
              quibusdam quisquam illum fugiat ipsum maiores illo. Inventore?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
              illum expedita eos vitae autem sit, inventore facilis laborum at
              aspernatur, rem quibusdam quia, provident odio in nemo vel
              necessitatibus voluptate cumque pariatur beatae? Facilis possimus
              sapiente esse dolorem odit veritatis consequatur libero est
              blanditiis quae fuga, officia, dolorum quas omnis.
            </p>
          </Col>
        </Row>
      </Container>

      {pandit && pandit.pooja_images && pandit.pooja_images.length > 0 && (
        <PoojaCarousel poojaPhotos={pandit ? pandit.pooja_images : null} />
      )}

      <Container className="mt-3 mb-5 p-0">
        <Row style={{ overflowX: "hidden" }}>
          <Col md={6}>
            <div className="shadow px-2 py-3 border border-2 rounded-3 px-3">
              <h5>Rating & Reviews</h5>
              <br />
              <br />
              <Row>
                <Col
                  md={3}
                  className="d-flex align-items-center justify-content-center flex-column"
                >
                  <h5>4.93</h5>
                  <div>
                    <GoStar color="#FFB600" />
                    <GoStar color="#FFB600" />
                    <GoStar color="#FFB600" />
                    <GoStar color="#FFB600" />
                    <GoStar color="#FFB600" />
                  </div>
                </Col>
                <Col md={9}>
                  <Row className="align-items-center justify-content-center">
                    <Col md={1}>5</Col>
                    <Col
                      md={10}
                      sm={11}
                      className="rounded-3 p-0"
                      style={{ height: "14px", background: "#EEEEEE" }}
                    >
                      <div
                        className="progress"
                        style={{
                          height: "14px",
                          width: "40%",
                          backgroundColor: "#3ED33E",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <Col md={1}>4</Col>
                    <Col
                      md={10}
                      sm={11}
                      className="rounded-3 p-0"
                      style={{ height: "14px", background: "#EEEEEE" }}
                    >
                      <div
                        className="progress"
                        style={{
                          height: "14px",
                          width: "40%",
                          backgroundColor: "#3ED33E",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <Col md={1}>3</Col>
                    <Col
                      md={10}
                      sm={11}
                      className="rounded-3 p-0"
                      style={{ height: "14px", background: "#EEEEEE" }}
                    >
                      <div
                        className="progress"
                        style={{
                          height: "14px",
                          width: "40%",
                          backgroundColor: "#3ED33E",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <Col md={1}>2</Col>
                    <Col
                      md={10}
                      sm={11}
                      className="rounded-3 p-0"
                      style={{ height: "14px", background: "#EEEEEE" }}
                    >
                      <div
                        className="progress"
                        style={{
                          height: "14px",
                          width: "40%",
                          backgroundColor: "#3ED33E",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <Col md={1}>1</Col>
                    <Col
                      md={10}
                      sm={11}
                      className="rounded-3 p-0"
                      style={{ height: "14px", background: "#EEEEEE" }}
                    >
                      <div
                        className="progress"
                        style={{
                          height: "14px",
                          width: "40%",
                          backgroundColor: "#3ED33E",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={6} className="mt-3 mt-md-0 mt-lg-0 mt-xl-0 mt-xxl-0">
            <div className="shadow px-2 py-3 border border-2 rounded-3 px-3">
              <h5>User Reviews</h5>
              <Container className="rounded-3 mt-3 px-3 py-2 border border-2">
                <Col className="d-flex align-items-center justify-content-start">
                  <img
                    src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/35x35/assets/images/horoscope-11.webp"
                    alt=""
                    srcset=""
                  />
                  <p className="p-0 m-0 ms-3">Nisikanta</p>
                </Col>
                <div className="mt-2 mb-2">
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                </div>
                <p className="m-0">very good experience</p>
              </Container>
              <Container className="rounded-3 mt-2 px-3 py-2 border border-2">
                <Col className="d-flex align-items-center justify-content-start">
                  <img
                    src="https://d1gcna0o0ldu5v.cloudfront.net/fit-in/35x35/assets/images/horoscope-11.webp"
                    alt=""
                    srcset=""
                  />
                  <p className="p-0 m-0 ms-3">Nisikanta</p>
                </Col>
                <div className="mt-2 mb-2">
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                  <GoStar color="#FFB600" />
                </div>
                <p className="m-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Provident aut libero odit pariatur maxime. Nemo placeat, iusto
                  odio quam repudiandae esse ducimus dolor nostrum dicta culpa,
                  natus labore cupiditate dolorum?
                </p>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default PublicProfile;
