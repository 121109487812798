import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { GET_VENDORS_ALL } from '../../graphql/Query'
import { FaEye, FaTrash } from "react-icons/fa";
import { MUTATION_DELETE_VENDOR } from '../../graphql/Mutation';

export default function AllVendors() {
    {
        window.scrollTo(0, 0)
    }

    const { data: vendorData, laoding: vendorLoading } = useQuery(GET_VENDORS_ALL)
    console.log('vendorData', vendorData)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [viewVendor, setViewVendor] = useState()
    console.log('viewVendor', viewVendor)

    function handleViewVendor(data) {
        setViewVendor(data)
        handleShow()
    }

const [deleteVendor] = useMutation(MUTATION_DELETE_VENDOR,{
    refetchQueries:[
        GET_VENDORS_ALL
    ]
})
   function handleVenderDelete(id) {
    deleteVendor({
        variables: {
            "vendorId":`${id}`
        }
    })
    handleClose()
   }
    return (
        <>
            <Container fluid>
                <h6 style={{ textAlign: 'center' }}>All Vendors      </h6>
                <hr></hr>
                <Table bordered responsive="sm" style={{ borderBottom: '1px solid #000', border: '1px solid #000',fontSize:'13px' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Contact</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Pincode</th>
                            <th style={{ textAlign: 'center' }}>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            vendorLoading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                vendorData && vendorData.getVendor.map(userData =>
                                    <tr>
                                        <td>{userData.fName} {userData.lName}</td>
                                        <td>{userData.email}</td>
                                        <td>{userData.contact}</td>
                                        <td>{userData.address}</td>
                                        <td>{userData.city}</td>
                                        <td>{userData.pincode}</td>
                                        <td style={{ textAlign: 'center', color: '#032276 ' }}><FaEye onClick={() => handleViewVendor(userData)} /></td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </Container>
            <Modal show={show} onHide={handleClose} size='lg'>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Vendor Name</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.fName} {viewVendor && viewVendor.lName}</h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Vendor Email</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.email} </h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Contact No.</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.contact} </h4>
                        </Col>
                        <hr></hr>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Address</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.address}</h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>City</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.city} </h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>State</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.state} </h4>
                        </Col>
                        <hr></hr>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Pincode</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.pincode}</h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Experience</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.experience} </h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Availability</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{viewVendor && viewVendor.availability} </h4>
                        </Col>
                        <hr></hr>
                    </Row>
                    <h5>Services</h5>
                    <Row>
                    {
                        viewVendor && viewVendor.services.map(vdata =>
                        <>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Service Type</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{vdata.serviceType}</h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Service Price</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{vdata.servicePrice} </h4>
                        </Col>
                        <Col md={4}>
                            <h4 style={{ fontSize: 14, textAlign: 'center' }}>Service Status</h4>
                            <h4 style={{ fontSize: 13, textAlign: 'center' }}>{vdata.serviceStatus} </h4>
                        </Col>
                        <hr></hr>
                        </>
                        )
                    }
                    </Row>
                  
                            <Button className='mx-auto d-block' style={{backgroundColor:'red',border:'none'}} onClick={() => handleVenderDelete(viewVendor && viewVendor.id)}> <FaTrash />Delete</Button>
                </Modal.Body>

            </Modal>
        </>
    )
}
