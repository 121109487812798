import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Spinner, Table } from "react-bootstrap";
import { GET_ALL_USER } from "../../graphql/Query";
import Moment from "react-moment";
import { FaEdit, FaEye } from "react-icons/fa";
import { Item, MyAPI, MyError } from "../../helper/api";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { useNavigate, useNavigation } from "react-router-dom";
import Loading from "../Loading";

export default function AllPandits() {
  const navigation = useNavigate();

  const [allPandits, setAllPandits] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAllPandits = async () => {
    let res = await MyAPI.GET("/admin/pandit", Item.getItem("token"));
    setIsLoading(false);
    let { status, message, data } = res.data || res;

    if (status === true) {
      setAllPandits(data);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    fetchAllPandits();
  }, []);

  const panditView = (panditId) => {
    navigation(`/admindashboard/pandit/${panditId}`);
  };

  const panditEdit = (panditId) => {
    navigation(`/admindashboard/pandit/edit/${panditId}`);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container fluid>
        <h6 style={{ textAlign: "center" }}>All Pandit's</h6>
        <hr></hr>
        <Table
          bordered
          responsive="sm"
          style={{
            borderBottom: "1px solid #000",
            border: "1px solid #000",
            fontSize: "13px",
          }}
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>Date/Time</th>
              <th colSpan={3}>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              allPandits &&
              allPandits.length > 0 &&
              allPandits.map((pandit, index) => (
                <tr style={{ textAlign: "center" }}>
                  <td>{index + 1}</td>
                  <td>{pandit.name}</td>
                  <td>{pandit.email}</td>
                  <td>{pandit.phone_number}</td>
                  <td>
                    <Moment format="DD/MM/YYYY, hh:mm a">
                      {pandit.dateTime}
                    </Moment>
                  </td>
                  <td style={{ textAlign: "center", color: "#032276 " }}>
                    <FaEdit
                      onClick={() => panditEdit(pandit._id)}
                      size={22}
                      className="cursor-pointer"
                    />
                  </td>
                  <td style={{ textAlign: "center", color: "#032276 " }}>
                    <FaEye
                      onClick={() => panditView(pandit._id)}
                      size={22}
                      className="cursor-pointer"
                    />
                  </td>
                  <td className="text-danger" style={{ textAlign: "center" }}>
                    <MdDelete size={22} className="cursor-pointer" />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
