import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { MUTATION_UPDATE_USER } from '../../graphql/Mutation';
import Lottie from 'react-lottie';
import * as animationData from '../../Images/94123-1hour-login-effect.json'
import swal from 'sweetalert';
import Navigation from '../Navigation';
import Footer from '../Footer';
import jai from "../../Music/Jai Shri Ram - Message.mp3"

export default function UserUpdateProfile() {
    {
        window.scrollTo(0, 0)
    }

    function play() {
        new Audio(jai).play()
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const navigate = useNavigate();

    const [validated3, setValidated3] = useState(false);
    const [fname, setfname] = useState('')
    const [lname, setlname] = useState('')
    const [email, setEmail] = useState('')
    const [address, setaddress] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    const [pincode, setpincode] = useState('')

    const userId = localStorage.getItem('userId')

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [updateUser, { loading: updateUserLoading }] = useMutation(MUTATION_UPDATE_USER)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated3(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated3(false);

            event.preventDefault();
            updateUser({
                variables: {
                    "updateUserInput": {
                        "id": `${userId}`,
                        "fName": `${fname}`,
                        "lName": `${lname}`,
                        "email": `${email}`,
                        "address": `${address}`,
                        "city": `${city}`,
                        "state": `${state}`,
                        "pincode": parseInt(pincode),
                    }
                }
            })
            play()
            handleClose()
            navigate("/services")
        }
    };

    return (
        <>
            <Navigation />
            <Container style={{ fontSize: '14px', padding: '20px' }}>
                <Row>
                    <Col md={4}>
                        <Lottie options={defaultOptions}
                            style={{ marginTop: '30px' }}
                            height={300}
                            width={300}
                        />
                    </Col>
                    <Col md={8}>
                        <div style={{ border: '3px solid #022279', padding: '25px', borderRadius: '25px', margin: '20px' }}>


                            <h6 style={{ marginTop: '15px', textAlign: 'center' }}>Please Fill Details First</h6>
                            <hr></hr>

                            <Form noValidate validated={validated3} onSubmit={handleSubmit} style={{ fontSize: '14px', margin: '30px 0px 0px 0px' }}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>First name <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                            onChange={(e) => setfname(e.target.value)} value={fname}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Last name <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                            onChange={(e) => setlname(e.target.value)} value={lname}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Email <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Email"
                                            onChange={(e) => setEmail(e.target.value)} value={email}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Address <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Address"
                                            onChange={(e) => setaddress(e.target.value)} value={address}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>City <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="City" required onChange={(e) => setcity(e.target.value)} value={city} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>State <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Select required defaultValue="Choose State..." onChange={(e) => setstate(e.target.value)} value={state}>
                                            <option>Choose...</option>
                                            <option>Andhra Pradesh</option>
                                            <option>Arunachal Pradesh</option>
                                            <option>Assam</option>
                                            <option>Bihar</option>
                                            <option>Chhattisgarh</option>
                                            <option>Goa</option>
                                            <option>Gujarat</option>
                                            <option>Haryana</option>
                                            <option>Jharkhand</option>
                                            <option>Karnataka</option>
                                            <option>Kerala</option>
                                            <option>Madhya Pradesh</option>
                                            <option>Maharashtra</option>
                                            <option>Manipur</option>
                                            <option>Meghalaya</option>
                                            <option>Mizoram</option>
                                            <option>Nagaland</option>
                                            <option>Odisha</option>
                                            <option>Punjab</option>
                                            <option>Rajasthan</option>
                                            <option>Sikkim</option>
                                            <option>Tamil Nadu</option>
                                            <option>Telangana</option>
                                            <option>Tripura</option>
                                            <option>Uttar Pradesh</option>
                                            <option>Uttarakhand</option>
                                            <option>West Bengal</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>Pincode <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Enter Pincode" required onChange={(e) => setpincode(e.target.value)} value={pincode} />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        required
                                        label="Agree to terms and conditions"
                                        feedbackType="invalid"
                                    />
                                </Form.Group>
                                {
                                    updateUserLoading ?
                                        <Spinner className='mx-auto d-block' animation="border" variant="secondary" />
                                        :
                                        <Button className='mx-auto d-block' type="submit" style={{ backgroundColor: '#f39c12', border: 'none', color: '#000' }}>Submit form</Button>
                                }
                            </Form>
                        </div>
                    </Col>

                </Row>
            </Container>
            <Footer />
        </>
    )
}
