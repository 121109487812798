import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { IoCloudUploadOutline } from "react-icons/io5";
import s3FileUpload, { deleteFileFromS3 } from "../../helper/imageUploader";
import { Item, MyAPI, MyError } from "../../helper/api";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import ButtonLoading from "../ButtonLoading";

const EditPandit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const { id } = useParams();

  const [panditId, setPanditId] = useState("");

  const [username, setUsername] = useState("");
  const [image, setImage] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [addressText, setAddressText] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [poojaImages, setPoojaImages] = useState([]);
  const [panditExp, setPanditExp] = useState("");
  const [uploadedPoojaImages, setUploadedPoojaImages] = useState([]);
  const [poojas, setPoojas] = useState([
    {
      poojaID: "",
      price: "",
      exp: "",
      duration: "",
    },
  ]);

  const [pandit, setPandit] = useState(null);

  useEffect(() => {
    setPanditId(id);
    getPanditDetails();
  }, [id]);

  const getPanditDetails = async () => {
    let res = await MyAPI.GET(`/admin/pandit/${id}`, Item.getItem("token"));
    setIsLoading(false);
    let { status, message, data } = res.data || res;
    if (status === true) {
      setPandit(data);
      setUploadedPoojaImages(data.pooja_images);
      setImage(null);
      setProfileImage(data.image);
      setName(data.name);
      setUsername(data.username);
      setEmail(data.email);
      setPhoneNumber(data.phone_number);
      setWhatsappNumber(data.whatsapp_number);
      setAddressText(data.address_text);
      setState(data.address_fields.state);
      setDistrict(data.address_fields.district);
      setCity(data.address_fields.city);
      setPincode(data.address_fields.pincode);
      setPanditExp(data.exp);
      setPoojas(data.poojas);
      console.log("pandit", data);
    } else {
      MyError.error(message);
    }
  };

  const AddOneMorePooja = () => {
    setPoojas((prev) => [
      ...prev,
      {
        poojaID: "",
        price: "",
        exp: "",
        duration: "",
      },
    ]);
  };
  const [allPooja, setAllPooja] = useState(null);

  const fetchAllCategory = async () => {
    let res = await MyAPI.GET(`/admin/pooja`, Item.getItem("token"));
    let { status, message, data } = res.data || res;

    if (status === true) {
      setAllPooja(data);
    } else {
      MyError.error(message);
    }
    console.log(res.data);
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  const handleRemovePoojs = (indexA) => {
    setPoojas((prev) => prev.filter((_, index) => index !== indexA));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let uploadedPanditProfile = "";
    setBtnLoading(true);
    try {
      if (image) {
        console.log("upload image found.");
        uploadedPanditProfile = await s3FileUpload(image);
        console.log("upload image ->", uploadedPanditProfile);
        setProfileImage(uploadedPanditProfile);
      }

      if (profileImage) {
        let bodyData = {
          image: image ? uploadedPanditProfile : profileImage,
          name,
          email,
          phone_number: phoneNumber,
          whatsapp_number: whatsappNumber,
          address_text: addressText,
          address_fields: {
            state,
            district,
            city,
            pincode,
          },
          poojas,
          pooja_images: uploadedPoojaImages,
          exp: panditExp,
        };

        console.log(bodyData);

        let res = await MyAPI.PUT(
          `/admin/pandit?ID=${panditId}`,
          bodyData,
          Item.getItem("token")
        );
        setBtnLoading(false);

        let { status, message, data } = res.data || res;

        if (status === true) {
          MyError.success(message);
        } else {
          MyError.error(message);
        }
      } else {
        MyError.error("profile image uploading error.");
      }
    } catch (error) {
      setBtnLoading(false);
      console.log("error", error);
      MyError.error(error.message);
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newPoojas = [...poojas];
    newPoojas[index] = { ...newPoojas[index], [name]: value };
    setPoojas(newPoojas);
  };

  const onDrop = (acceptedFiles) => {
    const files = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    setPoojaImages((prevImages) => [...prevImages, ...files]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleUploadImagesClick = async () => {
    try {
      setBtnLoading(true);
      const uploadedFileNames = await Promise.all(
        poojaImages.map(async (file) => {
          const fileName = await s3FileUpload(file);
          return fileName;
        })
      );
      setBtnLoading(false);
      let combinedArry = [...uploadedFileNames, ...uploadedPoojaImages];
      setUploadedPoojaImages(combinedArry);
      console.log("uploaded poojas file", combinedArry);
    } catch (error) {
      setBtnLoading(false);
      MyError.error(error.message);
    }
  };

  const handleRemoveImage = async (indexToRemove) => {
    try {
      await deleteFileFromS3(uploadedPoojaImages[indexToRemove]);
      setUploadedPoojaImages((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
      );
    } catch (error) {
      MyError.error(error.message);
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <h1 className="text-center">Edit Pandit</h1>
      <center>
        <h6>Pandit Name</h6>
      </center>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="image">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Enter image URL"
                  name="image"
                  // value={image}
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="phone_number">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter phone number"
                  name="phone_number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="whatsapp_number">
                <Form.Label>Whatsapp Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Whatsapp number"
                  name="whatsapp_number"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  name="state"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="district">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter district"
                  name="district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter pincode"
                  name="pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" md={4}>
              <Form.Group controlId="pincode">
                <Form.Label>Experience</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Experience"
                  name="Experience"
                  value={panditExp}
                  onChange={(e) => setPanditExp(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col className="mt-3 mb-3" md={12}>
              <Form.Group controlId="address_text">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter address"
                  name="address_text"
                  value={addressText}
                  onChange={(e) => setAddressText(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <hr />
            <Col className="mt-3 mb-4" md={4}>
              <Form.Group controlId="image">
                <Form.Label>Pooja Images</Form.Label>
                <div
                  {...getRootProps()}
                  className="border rounded-3 d-flex align-items-center justify-content-center flex-column"
                  style={{ cursor: "pointer", width: "420px", height: "30vh" }}
                >
                  <Form.Control
                    {...getInputProps()}
                    type="file"
                    placeholder="Enter image URL"
                    name="image"
                    style={{ display: "none" }}
                  />
                  <IoCloudUploadOutline size={32} />
                  <p className="text-center mt-3">
                    Drag 'n' drop some files here, <br /> or click to select
                    files
                  </p>
                </div>
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
              <Form.Group className="mb-4" controlId="duration">
                {btnLoading ? (
                  <ButtonLoading />
                ) : (
                  <Button onClick={handleUploadImagesClick}>
                    Upload Images
                  </Button>
                )}
              </Form.Group>
            </Col>
            <Col md={5} className="d-flex" style={{ flexWrap: "wrap" }}>
              {uploadedPoojaImages.map((pooja, index) => (
                <div key={index} className="p-2 position-relative">
                  <img
                    className="rounded-3"
                    style={{ width: 120, height: 120 }}
                    src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${pooja}`}
                    alt={`Pooja ${index + 1}`}
                  />
                  <button
                    type="button"
                    className="btn-close position-absolute top-0 end-0 bg-danger text-white"
                    style={{ color: "white" }}
                    onClick={() => handleRemoveImage(index)}
                    aria-label="Close"
                  ></button>
                </div>
              ))}
            </Col>
            <hr />
            {poojas.map((pooja, index) => (
              <Row key={index}>
                <Col className="mt-3" md={3}>
                  <Form.Group controlId={`poojaID-${index}`}>
                    {/* <Form.Label>Pooja ID</Form.Label> */}
                    <Form.Select
                      name="poojaID"
                      value={pooja.poojaID}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    >
                      <option value="">Select Pooja</option>
                      {allPooja &&
                        allPooja.length > 0 &&
                        allPooja.map((pooja) => {
                          return (
                            <option key={pooja._id} value={pooja._id}>
                              {pooja.title}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="mt-3" md={3}>
                  <Form.Group controlId={`price-${index}`}>
                    {/* <Form.Label>Pooja Price</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter Pooja Price"
                      name="price"
                      value={pooja.price}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-3" md={3}>
                  <Form.Group controlId={`exp-${index}`}>
                    {/* <Form.Label>Pooja Experience</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter Pooja Experience"
                      name="exp"
                      value={pooja.exp}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col className="mt-3" md={2}>
                  <Form.Group controlId={`duration-${index}`}>
                    {/* <Form.Label>Pooja Duration</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Enter Pooja Duration"
                      name="duration"
                      value={pooja.duration}
                      onChange={(e) => handleInputChange(index, e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex align-items-center justify-content-center"
                  style={{ position: "relative" }}
                >
                  <button
                    className="btn-close bg-danger text-white p-2 mt-3"
                    style={{ color: "white" }}
                    onClick={() => handleRemovePoojs(index)}
                    aria-label="Close"
                  ></button>
                </Col>
              </Row>
            ))}

            <Col className="d-flex mt-4">
              <Form.Group>
                <Button onClick={AddOneMorePooja}>Add One More Pooja</Button>
              </Form.Group>
              <Form.Group className="ms-3">
                {btnLoading ? (
                  <ButtonLoading />
                ) : (
                  <Button variant="primary" type="submit">
                    Update
                  </Button>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default EditPandit;
