import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap'
import swal from 'sweetalert';
import { MUTATION_SEND_VENDOR_REQUEST } from '../graphql/Mutation';

export default function AreYouPandit() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [contact, setContact] = useState('')
  const [email, setEmail] = useState('')
  const [message, setmessage] = useState('')

  const [createRequest, { loading: requestLoading }] = useMutation(MUTATION_SEND_VENDOR_REQUEST)

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      setValidated(false);
      event.preventDefault();
      createRequest({
        variables: {
          "requestInput": {
            "fName": `${firstName}`,
            "lName": `${lastName}`,
            "contact": `${contact}`,
            "email": `${email}`,
            "message": `${message}`
          }
        }
      })
      swal({
        title: "Thank You",
        text: "We Will Contact You Soon!!!",
        icon: "success",

      })
      setFirstName('')
      setLastName('')
      setContact('')
      setEmail('')
      setmessage('')
      handleClose()
    }


  };



  return (
    <>
      <Container style={{ backgroundColor: '#022278', margintop: '30px', padding: '80px', borderRadius: '30px' }}>
        <h1 style={{ textAlign: 'center', color: '#fff', paddingTop: '30px', fontSize: '30px' }}>Are You Pandit ?</h1>
        <p style={{ textAlign: 'center', color: '#b6a5a0', margintop: '30px', fontSize: '13px' }}>Get More Puja Assignments At Your Preferred Location</p>
        <Button className='mx-auto d-block' style={{ marginTop: '40px', backgroundColor: '#feb92d', border: 'none', color: '#000' }} onClick={handleShow}>Send Request</Button>

      </Container>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hey!! Please Fill The Form</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px', fontSize: '13px' }}>
          <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ padding: '15px', marginLeft: '40px' }}>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  style={{ height: '50px' }}
                  onChange={(e) => setFirstName(e.target.value)} value={firstName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  style={{ height: '50px' }}
                  onChange={(e) => setLastName(e.target.value)} value={lastName}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Control
                  required
                  type="text"
                  placeholder="Phone No"
                  style={{ height: '50px' }}
                  onChange={(e) => setContact(e.target.value)} value={contact}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Control
                  required
                  type="text"
                  placeholder="Email"
                  style={{ height: '50px' }}
                  onChange={(e) => setEmail(e.target.value)} value={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01">
                <Form.Control
                  required
                  as="textarea"
                  placeholder="Message"
                  style={{ height: '90px' }}
                  onChange={(e) => setmessage(e.target.value)} value={message}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            {
              requestLoading ?
                <Spinner className='mx-auto d-block' animation="grow" variant="warning" />
                :
                <Button type="submit" style={{ height: '50px', width: '100%', background: '#ffb600', border: 'none', color: '#000', fontSize: '20px' }}>submit</Button>
            }
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
