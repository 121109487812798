import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { GET_ALL_USER } from "../../graphql/Query";
import Moment from "react-moment";
import { FaEye, FaRegEdit } from "react-icons/fa";
import {
  Item,
  MY_AWS,
  MyAPI,
  MyError,
  MyStatusOptions,
} from "../../helper/api";
import { MdDeleteForever } from "react-icons/md";
import s3FileUpload, { deleteFileFromS3 } from "../../helper/imageUploader";
import Loading from "../Loading";
import ButtonLoading from "../ButtonLoading";

export default function AllPooja() {
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [showImageInput, setShowImageInput] = useState(false);
  const [updateImage, setUpdateImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [showCategory, setShowCategory] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [allCategory, setAllCategory] = useState(null);
  const [showDescriptionModel, setShowDescriptionModel] = useState(false);

  const fetchAllCategory = async () => {
    let res = await MyAPI.GET(`/admin/category`, Item.getItem("token"));
    let { status, message, data } = res.data || res;

    if (status === true) {
      setAllCategory(data);
    } else {
      MyError.error(message);
    }
    console.log(res.data);
  };

  const getAllPooja = async () => {
    let res = await MyAPI.GET("/admin/pooja", Item.getItem("token"));
    let { status, message, data } = res.data || res;
    setIsLoading(false);
    if (status === true) {
      setData(data);
    } else {
      MyError.error(message);
    }
    console.log("all category", res.data);
  };

  useEffect(() => {
    getAllPooja();
    fetchAllCategory();
  }, []);

  const handleEdit = (category) => {
    setEditingCategory(category);
    handleShowModal();
  };

  const handleDelete = async (category) => {
    setDeletingCategory(category);

    let res = await MyAPI.DELETE(
      `/admin/pooja?id=${category._id}`,
      Item.getItem("token")
    );
    let { status, message, data } = res.data || res;
    if (status === true) {
      getAllPooja();
      await MY_AWS.deleteFile(category.image);
      MyError.success(message);
    } else {
      MyError.error(message);
    }
    console.log(res.data);
  };

  const handleEditCategory = async () => {
    if (
      !editingCategory.title ||
      !editingCategory.description ||
      !editingCategory.categoryId
    ) {
      MyError.error("All fields are required.");
      return;
    }

    if (showImageInput && !updateImage) {
      MyError.error("Please upload an image.");
      return;
    }

    let uploadedFileName = null;
    setBtnLoading(true);
    if (showImageInput) {
      try {
        uploadedFileName = await s3FileUpload(updateImage);
        if (!uploadedFileName) {
          MyError.error("Failed to upload image.");
        }
        await deleteFileFromS3(editingCategory.image);
      } catch (error) {
        setBtnLoading(false);
        MyError.error(error.message);
        return;
      }
    }

    try {
      const requestData = {
        poojaId: editingCategory._id,
        title: editingCategory.name,
        description: editingCategory.description,
        status: editingCategory.status,
        image: showImageInput ? uploadedFileName : editingCategory.image,
      };

      const res = await MyAPI.PUT(
        `/admin/pooja`,
        requestData,
        Item.getItem("token")
      );
      setBtnLoading(false);
      const { status, message } = res.data || res;

      if (status === true) {
        MyError.success(message);
        getAllPooja();
        setShowModal(false);
        setEditingCategory(null); // Clear editingCategory state
      } else {
        MyError.error(message);
      }
    } catch (error) {
      setBtnLoading(false);
      MyError.error("An error occurred while processing your request.");
    }
  };

  const showDescription = (desc) => {
    setShowCategory(desc);
    setShowDescriptionModel(true);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Container fluid>
        <h6 style={{ textAlign: "center" }}>All Pooja</h6>
        <hr></hr>
        <Table
          bordered
          responsive="lg"
          style={{
            borderBottom: "1px solid #000",
            border: "1px solid #000",
            fontSize: "13px",
          }}
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Image</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
              <th>Description</th>
              <th>Action</th>
              <th>Date/Time</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              data &&
              data.map((category) => (
                <tr style={{ textAlign: "center" }} key={category.id}>
                  <td>
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${category.image}`}
                      alt="Category"
                    />
                  </td>
                  <td>{category.title}</td>
                  <td>{category.categoryId.name}</td>
                  <td>{category.status}</td>
                  <td>
                    <Button
                      className="bg-primary text-white mt-1"
                      variant="primary"
                      size="sm"
                      onClick={() => showDescription(category)}
                    >
                      Show
                    </Button>
                  </td>
                  <td>
                    <FaRegEdit
                      className="text-primary cursor-pointer"
                      size={22}
                      onClick={() => handleEdit(category)}
                    />
                    <MdDeleteForever
                      className="ms-3 text-danger cursor-pointer"
                      size={22}
                      onClick={() => handleDelete(category)}
                    />
                  </td>
                  <td>
                    <Moment format="DD/MM/YYYY, hh:mm a">
                      {category.dateTime}
                    </Moment>
                  </td>
                </tr>
              ))
            )}
            {data && data.length === 0 && (
              <tr>
                <td colSpan="7" className="px-3 py-2">
                  No Pooja Found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      <Modal
        show={showDescriptionModel}
        onHide={() => setShowDescriptionModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>{showCategory.description}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDescriptionModel(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing Categories */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              onChange={(e) =>
                setEditingCategory({
                  ...editingCategory,
                  title: e.target.value,
                })
              }
              type="text"
              value={editingCategory && editingCategory.title}
            />
          </Form.Group>

          <div className="mb-3">
            <Form.Label htmlFor="poojaPrice">Pooja Category</Form.Label>
            <Form.Select
              id="poojaPrice"
              value={editingCategory && editingCategory.categoryId}
              onChange={(e) =>
                setEditingCategory({
                  ...editingCategory,
                  categoryId: e.target.value,
                })
              }
            >
              <option value="">select</option>
              {allCategory && allCategory.length > 0 ? (
                allCategory.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))
              ) : (
                <option disabled>Category not found.</option>
              )}
            </Form.Select>
          </div>

          <div className="mb-3">
            <Form.Label htmlFor="poojaPrice">Pooja Status</Form.Label>
            <Form.Select
              id="poojaPrice"
              value={editingCategory && editingCategory.status}
              onChange={(e) =>
                setEditingCategory({
                  ...editingCategory,
                  status: e.target.value,
                })
              }
            >
              <option value="">select</option>
              {MyStatusOptions.map((status, key) => (
                <option>{status}</option>
              ))}
            </Form.Select>
          </div>

          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Label>Pooja Description</Form.Label>
            <Form.Control
              as="textarea"
              onChange={(e) =>
                setEditingCategory({
                  ...editingCategory,
                  description: e.target.value,
                })
              }
              value={editingCategory && editingCategory.description}
              rows={3} // Adjust the number of rows as needed
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="toggleImageInput">
            <Form.Check
              type="switch"
              id="toggleImageInput"
              label="Update Image"
              checked={showImageInput}
              onChange={() => setShowImageInput(!showImageInput)}
            />
          </Form.Group>

          {showImageInput && (
            <Form.Group className="mb-3" controlId="categoryImage">
              <Form.Label>Category Image</Form.Label>
              <Form.Control
                onChange={(e) => setUpdateImage(e.target.files[0])}
                type="file"
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {btnLoading ? (
            <ButtonLoading />
          ) : (
            <Button variant="primary" onClick={handleEditCategory}>
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
