import { useQuery } from '@apollo/client'
import React from 'react'
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import Moment from 'react-moment'
import { GET_CANCEL_BOOKING } from '../../graphql/Query'

export default function VendorCancelOrder() {

    const vendorId = localStorage.getItem('vendorId')

    const {data:cancelBooking,loading:cancelBookingLoading} = useQuery(GET_CANCEL_BOOKING,{
            variables:{
                "vendorId": `${vendorId}`
            }
    })
    console.log('cancelBooking',cancelBooking)
  return (
    <>
       <Container>
      <h5 style={{ textAlign: 'center' }}>All Cancel Bookings</h5>
                <hr></hr>
                <Row>
                    <Col md={1}></Col>

                    <Col md={10}>
                        <Card style={{ padding: '20px' }}>
                            {
                                cancelBookingLoading ?
                                    <>

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                    :
                                    cancelBooking && cancelBooking.getCancelBookingByVendorID.slice(0).reverse().map(bookCanDAta =>
                                        <>

                                            <Row>
                                                <Col md={2}>
                                                    <div style={{ height: '100px', width: '120px' }}>
                                                        <Image classNAme='mx-auto d-block' src="https://www.godpng.com/uploads/png/lord-rama-sita-png.png" style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <h5 style={{ textAlign: 'center', fontSize: '13px', marginTop: '20px' }}>Booking No: {bookCanDAta.bookingNo}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Date: {bookCanDAta.scheduleDate}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Time: {bookCanDAta.scheduleTime}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Service Name: {bookCanDAta.serviceName}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Contact: <span style={{color:'#3742fa'}}> {bookCanDAta.contact}</span></h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Alternate Contact: <span style={{color:'#3742fa'}}> {bookCanDAta.alternativeContact}</span></h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Address: <span style={{color:'#3742fa'}}> {bookCanDAta.address}</span></h5>
                                                </Col>
                                                <Col md={4}>
                                                    <h6 style={{ textAlign: 'center', fontSize: '12px', marginTop: '20px' }}>Booking Date & Time</h6>
                                                    <p style={{ textAlign: 'center', fontSize: '12px' }}><Moment format="DD-MM-YYYY hh:mm a">{bookCanDAta.createDateTime}</Moment></p>
                                        
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                        </>
                                    )

                            }
                        </Card>
                    </Col>
                    <Col md={1}></Col>
                </Row>
      </Container>
    </>
  )
}
