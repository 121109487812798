import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { Container } from "react-bootstrap";

function Loading({ isLoading }) {
  useEffect(() => {
    // Toggle body scroll based on isLoading prop
    window.scrollTo(0, 0);
    if (isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <Container
          fluid
          className="d-flex align-items-center justify-content-center position-absolute top-0 start-0"
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "142512",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#FFB600"}
            height={80}
            width={80}
          />
        </Container>
      )}
    </>
  );
}

export default Loading;
