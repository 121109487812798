import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Footer'
import Navigation from '../Navigation'
import kalash from '../../Images/11_09_2020-mantra-jaap-vidhi_20735138-removebg-preview.png'

export default function Jaap() {

    {
        window.scrollTo(0, 0)
    }

    let navigate = useNavigate();

    const getVendorList = (value) => {
        navigate(`/panditforpoooja/${value}`)
    }

  return (
    <>
    <Navigation />
            <Container style={{padding:'30px'}} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                        </Link>
                    </Col>
                    <Col md={8}>
                        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Our Jaap Services</h5>

                    </Col>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Budha Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Budha Graha Shanti Jaap</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Chandra Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Chandra Graha Shanti Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Gayatri Mantra Jaap") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Gayatri Mantra Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Guru Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Guru Graha Shanti Jaap</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Ketu Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Ketu Graha Shanti Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Maha Mrityunjaya Jaap") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Maha Mrityunjaya Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Mangal Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Mangal Graha Shanti Jaap</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Rahu Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Rahu Graha Shanti Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Santan Gopal Mantra Jaap") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Santan Gopal Mantra Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Shani Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Shani Graha Shanti Jaap</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Shukra Graha Shanti Jaap") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Shukra Graha Shanti Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Surya Graha Shanti Jaap") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Surya Graha Shanti Jaap</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
      
    </>
  )
}
