import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import {
  FaBars,
  FaChevronDown,
  FaCog,
  FaHome,
  FaProductHunt,
  FaPlaceOfWorship,
  FaRegComments,
  FaShoppingCart,
  FaUserAlt,
} from "react-icons/fa";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../../ComponentsCss/Admincss/Admindashboard.css";
import { Item, MyError } from "../../helper/api";

export default function AdminDashboard() {
  const navigation = useNavigate();

  const admintoken = Item.getItem("token");

  function handleDown() {
    const listItems = document.querySelectorAll(".adminsidebar-list li");

    listItems.forEach((item) => {
      item.addEventListener("click", () => {
        let isActive = item.classList.contains("active");

        listItems.forEach((el) => {
          el.classList.remove("active");
        });

        if (isActive) item.classList.remove("active");
        else item.classList.add("active");
      });
    });
  }

  function handleClick() {
    const toggleSidebar = document.querySelector(".toggle-adminsidebar");
    const adminlogo = document.querySelector(".adminlogo-box");
    const adminsidebar = document.querySelector(".adminsidebar");
    toggleSidebar.addEventListener("click", () => {
      adminsidebar.classList.toggle("close");
    });

    adminlogo.addEventListener("click", () => {
      adminsidebar.classList.toggle("close");
    });
  }

  const handleLogOut = () => {
    Item.removeItem("token");
    Item.removeItem("Iam");
    MyError.success("You have been logged out successfully");
    navigation("/");
  };

  let token = Item.getItem("token");
  let Iam = Item.getItem("Iam");

  useEffect(() => {
    if (!token || Iam !== "admin") {
      navigation("/");
      Item.removeItem("token");
      Item.removeItem("Iam");
      MyError.error("Unauthorized user. Please log in as a admin!");
    }
  }, [token, Iam]);

  if (!admintoken) {
    navigation("/admin/login");
  }

  return (
    <>
      <div className="adminsidebar close">
        {/* <!-- ========== Logo ============  --> */}
        <Link to="/" className="adminlogo-box">
          <i className="bx bxl-xing">
            <Image
              src="https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?cs=srgb&dl=pexels-anjana-c-674010.jpg&fm=jpg"
              style={{ height: "70%", width: "50%" }}
            />
          </i>
          <div className="adminlogo-name">Pandit Bulao</div>
        </Link>

        {/* <!-- ========== List ============  --> */}
        <ul className="adminsidebar-list">
          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/" className="link">
                <i className="bx bx-grid-alt">
                  <FaHome />
                </i>
                <span className="name">Home</span>
              </Link>
              {/* <!-- <i class='bx bxs-chevron-down'></i> --> */}
            </div>
            <div className="submenu">
              <Link to="/" className="link submenu-title">
                Home
              </Link>
              {/* <!-- submenu links here  --> */}
            </div>
          </li>

          {/* <!-- -------- Dropdown List Item ------- --> */}
          <li className="dropdown">
            <div className="title">
              <Link to="/admindashboard/allusers" className="link">
                <i className="bx bx-line-chart">
                  <FaUserAlt />
                </i>
                <span className="name">Users</span>
              </Link>
              <i className="bx bxs-chevron-down" onClick={() => handleDown()}>
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              {/* <Link href="#" className="link submenu-title">Category</Link> */}
              <Link to="/admindashboard/allusers" className="link">
                All Users
              </Link>
              <Link to="/admindashboard/pandit/all" className="link">
                All Pandit
              </Link>
              <Link to="/admindashboard/pandit/add" className="link">
                Add Pandit
              </Link>
            </div>
          </li>
          {/* <!-- -------- Dropdown List Item ------- --> */}
          <li className="dropdown">
            <div className="title">
              <Link to="/admindashboard/allusers" className="link">
                <i className="bx bx-line-chart">
                  <FaPlaceOfWorship />
                </i>
                <span className="name">Pooja</span>
              </Link>
              <i className="bx bxs-chevron-down" onClick={() => handleDown()}>
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              {/* <Link href="#" className="link submenu-title">Category</Link> */}
              <Link to="/admindashboard/addpooja" className="link">
                Add Pooja
              </Link>
              <Link to="/admindashboard/allpooja" className="link">
                All pooja
              </Link>
              <Link to="/admindashboard/categary/add" className="link">
                Add Category
              </Link>
              <Link to="/admindashboard/categary/all" className="link">
                All Category
              </Link>
            </div>
          </li>

          {/* <!-- -------- Dropdown List Item ------- --> */}
          <li className="dropdown">
            <div className="title">
              <Link to="/admindashboard/allbookings" className="link">
                <i className="bx bx-book-alt">
                  <FaShoppingCart />
                </i>
                <span className="name">Bookings</span>
              </Link>
              <i className="bx bxs-chevron-down" onClick={() => handleDown()}>
                <FaChevronDown />
              </i>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/allbookings"
                className="link submenu-title"
              >
                Bookings
              </Link>
            </div>
          </li>

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/admindashboard/payments" className="link">
                <i className="bx bx-line-chart">
                  <FaProductHunt />
                </i>
                <span className="name">Payments</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/payments"
                className="link submenu-title"
              >
                Payments
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/admindashboard/allrequests" className="link">
                <i className="bx bx-pie-chart-alt-2">
                  <FaRegComments />
                </i>
                <span className="name">Vendor Request</span>
              </Link>
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/allrequests"
                className="link submenu-title"
              >
                Vendor Request
              </Link>
            </div>
          </li>

          {/* <!-- -------- Non Dropdown List Item ------- --> */}
          <li>
            <div className="title">
              <Link to="/admindashboard/adminsettings" className="link">
                <i className="bx bx-cog">
                  <FaCog />
                </i>
                <span className="name">Settings</span>
              </Link>
              {/* <!-- <i class='bx bxs-chevron-down'></i> --> */}
            </div>
            <div className="submenu">
              <Link
                to="/admindashboard/adminsettings"
                className="link submenu-title"
              >
                Settings
              </Link>
              {/* <!-- submenu links here  --> */}
            </div>
          </li>
        </ul>
      </div>

      {/* <!-- ============= Home Section =============== --> */}
      <section className="adminhome">
        <div className="toggle-adminsidebar d-flex align-items-center justify-content-between pe-4">
          <FaBars
            className="bx bx-menu"
            onClick={() => handleClick()}
            style={{ width: 30, height: 30, marginLeft: 10 }}
          />
          <button
            onClick={handleLogOut}
            className="btn btn-sm border-primary text-primary"
          >
            Logout
          </button>
        </div>

        <Container style={{ padding: "15px" }}>
          <Row>
            <Col md={12}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
