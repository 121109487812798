import React from 'react'
import { Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap'
import Moment from 'react-moment'

export default function UserPayments() {
    return (
        <>
            <Container fluid>
                <h6 style={{ textAlign: 'center' }}>Your Payments</h6>
                <hr></hr>
                <Row>
                    <Col md={1}></Col>

                    <Col md={10}>
                        <Card style={{ padding: '20px' }}>


                            <Row>
                                <Col md={2}>
                                    <div style={{ height: '100px', width: '120px' }}>
                                        <Image classNAme='mx-auto d-block' src="https://www.godpng.com/uploads/png/lord-rama-sita-png.png" style={{ height: '100%', width: '100%' }} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <h5 style={{ textAlign: 'center', fontSize: '13px', marginTop: '20px' }}>Booking No: </h5>
                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Date: </h5>
                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Time: </h5>
                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Service Name: </h5>


                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Status: <span style={{ color: '#ffa801' }}></span></h5>


                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Status: <span style={{ color: 'green' }}></span></h5>

                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Status: <span style={{ color: '#ff3f34' }}></span></h5>

                                </Col>
                                <Col md={4}>
                                    <h6 style={{ textAlign: 'center', fontSize: '12px', marginTop: '20px' }}>Booking Date & Time</h6>
                                    <p style={{ textAlign: 'center', fontSize: '12px' }}><Moment format="DD-MM-YYYY hh:mm a">2004/26/06</Moment></p>

                                </Col>
                            </Row>
                            <hr></hr>
                        </Card>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </>
    )
}
