import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../ComponentsCss/Categories.css";
import kalash from "../Images/clipart-removebg-preview.png";
import { MyAPI, MyError } from "../helper/api";
import Loading from "./Loading";

export default function Categories() {
  let navigate = useNavigate();

  const [isLoading,setIsLoading] = useState(true);

  const [categories, setCategories] = useState(null);

  // Get all categories data from

  const getAllCategory = async () => {
    let res = await MyAPI.GET("/public/category");
    let { status, message, data } = res.data || res;
    setIsLoading(false);

    if (status === true) {
      setCategories(data);
      console.log("all public category", data);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const getVendorList = (value) => {
    navigate(`/category/${value}`);
  };

  return (
    <>
    <Loading isLoading={isLoading} />
      <Container className="categoryMain" fluid>
        <p style={{ color: "#ffb600", fontSize: "18px", fontWeight: "600" }}>
          Categories
        </p>
        <h1 className="popName">Popular Categories</h1>

        <Row>
          {!categories && <h6>Loading Please Wait...</h6>}

          {categories && categories.length === 0 && (
            <h6>Categories Not Found!</h6>
          )}

          {categories &&
            categories.length > 0 &&
            categories.map((item, index) => (
              <Col md={4}>
                <Card
                  onClick={() => {
                    getVendorList(item._id);
                  }}
                  className="crdCategory"
                >
                  <div className="textareas">
                    <div className="divCard overflow-hidden">
                      <Image
                        className="mx-auto d-block rounded-5 object-fit-contain"
                        src={
                          item.image
                            ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${item.image}`
                            : kalash
                        }
                        style={{
                          height: "90%",
                          width: "90%",
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'center'
                        }}
                      ></Image>
                    </div>
                    <h6
                      style={{
                        color: "black",
                        marginTop: "40px",
                        marginLeft: "40px",
                        fontWeight: 700,
                      }}
                    >
                      {item.name}
                    </h6>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
        <Link to="/services">
          <Button
            className="mx-auto d-block"
            style={{
              backgroundColor: "#ffb600",
              border: "none",
              height: "40px",
              width: "140px",
              color: "#000",
            }}
          >
            SHOW MORE
          </Button>
        </Link>
      </Container>
    </>
  );
}
