import { gql } from '@apollo/client';

export const QUERY_GET_USERBY_USERID = gql`
query Query($userId: ID) {
  getUserById(userId: $userId) {
    id
    fName
    lName
    contact
    email
    password
    address
    city
    state
    pincode
    longitude
    latitude
    subscriptionType
    subscriptionDateTime
    subscriptionAmount
    subscriptionName
    deviceToken
    createdDateTime
    status
  }
}
`
export const QUERY_GET_VENDOR_BY_ID = gql`
query Query($vendorId: ID) {
  getVendorById(vendorId: $vendorId) {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
  }
}
`
export const GET_VENDOR_BY_VENDOR_NAME = gql`
query Query($serviceType: String) {
  getVendorByVendorName(serviceType: $serviceType) {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
  }
}
`
export const GET_USER_BOOKING_BYID = gql`
query Query($userId: ID) {
  getBookingByUserId(userId: $userId) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const GET_ALL_USER = gql`
query Query {
  getUser {
    id
    fName
    lName
    contact
    email
    password
    address
    city
    state
    pincode
    longitude
    latitude
    subscriptionType
    subscriptionDateTime
    subscriptionAmount
    subscriptionName
    deviceToken
    createdDateTime
    status
  }
}
`
export const GET_VENDORS_ALL = gql`
query GetVendor {
  getVendor {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
  }
}
`
export const GET_NOTIFICATION_BY_VENDORID = gql`
query Query($vendorId: ID) {
  getNotificationByVendorID(vendorId: $vendorId) {
    id
    userId
    vendorId
    title
    description
    createDateTime
    status
  }
}
`
export const GET_BOOKING_BY_VENDORID = gql`
query Query($vendorId: ID) {
  getBookingByVendorID(vendorId: $vendorId) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const GET_USERDATA_BY_USERID = gql`
query GetUserDataByUserID($userId: ID) {
  getUserDataByUserID(userId: $userId) {
    id
    fName
    lName
    contact
    email
    password
    address
    city
    state
    pincode
    longitude
    latitude
    subscriptionType
    subscriptionDateTime
    subscriptionAmount
    subscriptionName
    deviceToken
    createdDateTime
    status
  }
}
`
export const GET_COMPLETE_BOOKING = gql`
query GetCompleteBookingByVendorID($vendorId: ID) {
  getCompleteBookingByVendorID(vendorId: $vendorId) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const GET_CANCEL_BOOKING = gql`
query GetCancelBookingByVendorID($vendorId: ID) {
  getCancelBookingByVendorID(vendorId: $vendorId) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const GET_ALL_BOOKINGS = gql`
query Query {
  getBooking {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const GET_REQUEST = gql`
query Query {
  getRequest {
    id
    fName
    lName
    contact
    email
    message
    createdDateTime
    status
  }
}
`

export const GET_ALL_POOJAS = gql`

query GetPooja {
  getPooja {
    id
    poojaName
    poojaDescription
    poojaPrice
    poojaImage
    keyPoints
    poojaDuration
    poojaLanguage
    poojaLocation
    poojaSamagri
    createdDateTime
    status
    poojaVendor {
      id
      fName
      lName
      contact
      username
      password
      type
      image
      alternativeContact
      email
      address
      city
      state
      pincode
      experience
      services {
        id
        serviceType
        servicePrice
        serviceStatus
      }
      Expertise {
        id
        ExpertiseName
        ExpertisPrice
      }
      description
      availability
      availableTiming
      createdDateTime
      status
    }
  }
}
`

export const GET_POOJA_BY_ID = gql`
query GetPoojaById($poojaId: ID) {
  getPoojaById(poojaId: $poojaId) {
    id
    poojaName
    poojaDescription
    poojaPrice
    poojaImage
    keyPoints
    poojaDuration
    poojaLanguage
    poojaLocation
    poojaSamagri
   
    createdDateTime
    status
    poojaVendor {
      id
      fName
      lName
      contact
      username
      password
      type
      image
      alternativeContact
      email
      address
      city
      state
      pincode
      experience
      services {
        id
        serviceType
        servicePrice
        serviceStatus
      }
      Expertise {
        id
        ExpertiseName
        ExpertisPrice
      }
      description
      availability
      availableTiming
      createdDateTime
      status
    }
  }
}

`
export const GET_POOJA_NAME = gql` 
query GetPooja {
  getPooja {
    id
    poojaName
    status
    poojaImage
  }
}
`