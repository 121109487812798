import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import Navigation from '../Navigation';
import kalash from '../../Images/pind-1-370x285.png'

export default function Pitrupuja() {

    {
        window.scrollTo(0, 0)
    }

    let navigate = useNavigate();

    const getVendorList = (value) => {
        navigate(`/panditforpoooja/${value}`)
    }

  return (
    <>
        <Navigation />
            <Container style={{ padding: '30px' }} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                        </Link>
                    </Col>
                    <Col md={8}>
                        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Our Pitru Puja Services</h5>

                    </Col>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Amavasya Turpan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Amavasya Turpan</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Antim Sanskar - Last Rites") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Antim Sanskar - Last Rites</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Asthi Visarjan") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Asthi Visarjan</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Barsi Ceremony") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Barsi Ceremony</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Brahmins for Bhojan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Brahmins for Bhojan</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Garud Puran Path") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Garud Puran Path</h6>
                            </div>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Pitru Paksha (Mahalaya) Sharadh Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Pitru Paksha (Mahalaya) Sharadh Puja</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Shradh") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Shradh</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Swayampaka / Seedha Daan for Brahmins") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Swayampaka / Seedha Daan for Brahmins</h6>
                            </div>
                        </Card>


                    </Col>
                </Row>

            </Container>
            <Footer />
    </>
  )
}
