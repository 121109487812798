import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import panditpic from "../Images/pandit-removebg-preview.png";
import { IoMdStar, IoMdStarHalf } from "react-icons/io";
import { MyAPI, MyError } from "../helper/api";
import Loading from "./Loading";

const PoojaBooking = () => {
  const { service } = useParams();
  const [isLoading,setIsLoading] = useState(true);
  const [poojaData, setPoojaData] = useState([]);
  const [poojaLoading, setpoojaLoading] = useState(false);
  const [pandits, setPandits] = useState(null)

  const getPandits = async () => {
    let res = await MyAPI.GET(`/public/pandit/poojas?poojaId=${service}`);
    let { status, message, data } = res.data || res;
    setIsLoading(false);
    console.log('all pandits for pooja ',data);
    if (status === true) {
      setPandits(data);
    } else {
      MyError.error(message);
    }
  };

  const getPooja = async () => {
    let res = await MyAPI.GET(`/public/pooja?id=${service}`);
    let { status, message, data } = res.data || res;
    if (status === true) {
      setPoojaData(data);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    getPandits();
    getPooja();
  }, [])

  const navigate = useNavigate();

  const navigatePanditProfile = (panditId) => {
    navigate(`/profile/${panditId}`);
  }

  return (
    <>
    <Loading isLoading={isLoading} />
      <Navigation />

      <>
        <Container style={{ padding: "30px" }} className="px-5" fluid>
          <Row>
            <Col md={1}></Col>
            <Col md={1}>
              <Link to="/services" style={{ textDecoration: "none" }}>
                <FaLongArrowAltLeft
                  style={{
                    height: "25px",
                    width: "25px",
                    marginTop: "10px",
                    color: "black",
                  }}
                />
              </Link>
            </Col>
            <Col md={8}>
              <h3 style={{ textAlign: "center" }}>Top Pandit's </h3>
            </Col>
            <Col md={1}></Col>
            <Col md={1}></Col>
          </Row>
          <hr></hr>

          <Row className="pooja_details_page mt-5 mb-5">
            {poojaLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <>
                <Col md={6} className="pooja_details_page_col1">
                  <div className="d-flex align-items-center ">
                    <h5 className="fw-semibold m-0 me-3">
                      {poojaData && poojaData.title ? poojaData.title : 'Loading...'}
                    </h5>
                    <IoMdStar />
                    <IoMdStar />
                    <IoMdStar />
                    <IoMdStar />
                    <IoMdStarHalf />
                    <span
                      className="text-success ms-3"
                      style={{ fontSize: 12 }}
                    >
                      (13 coustomer reviews)
                    </span>
                  </div>
                  {/* <h6 className="m-0 mt-3">
                    <b>Price:</b> ₹ 1300/- {poojaData && poojaData.name ? poojaData.name : 'Loading...'}
                  </h6> */}

                  <h4 className="mt-4 fw-semibold">Details</h4>

                  <p> {poojaData && poojaData.description ? poojaData.description : '' }</p>

                  {/* <p className="mt-4">
                    <b>Important Key Points :</b>
                  </p>

                  <ul className="mt-3">
                   
                    <li>This is key Points.</li>
                    <li>This is key Points.</li>
                    <li>This is key Points.</li>
                    <li>This is key Points.</li>
                    <li>This is key Points.</li>
                    <li>This is key Points.</li>
                  </ul> */}

                  {/* <div className="d-flex mt-4 align-items-center gap-3">
                    <div className="badge_c">
                      1 Hour
                    </div>
                    <div className="badge_c">
                      Hindi ,Other
                    </div>
                    <div className="badge_c">
                      Banglore
                    </div>
                  </div> */}

                  <button className="mt-4 shadow">Download Samgri</button>
                </Col>
              </>
            )}

            <Col
              md={6}
              className="pooja_details_page_col2 ps-md-5 mt-md-0 mt-5"
            >
              <h6>Select at least 2 pandits</h6>
              <Row>
               
                {
                  !pandits && (
                    <h5>Loading Please  Wait...</h5>
                  )
                }

                {
                  pandits && pandits.length === 0 && (
                    <h5>Pandit Not Available for this pooja. </h5>
                  )
                }

                {
                  pandits && pandits.length > 0 && pandits.map((pandit, index) => (
                    <Col md={6} className="">
                      <Card className="p-3 shadow bg-white rounded-3 d-flex flex-column justify-content-center align-items-center mt-3 cursor-pointer border">
                        <Image
                        className="rounded-circle object-fit-cover image-size p-1 border"
                          onClick={() => navigatePanditProfile(pandit._id)}
                          src={pandit.image ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${pandit.image}` : panditpic}
                          height={'100px'}
                          width={'100px'}
                        />
                        <h5 onClick={() => navigatePanditProfile(pandit._id)} className="mt-3">{pandit.name}</h5>
                        <h6>Price : ₹ {pandit.poojas.map((po)=>(
                          po.poojaID === service ?  po.price : ''
                        ))} /- </h6>
                        <p className="text-secondary text-center">
                          {pandit.exp}+ years of experience in pooja
                        </p>
                        <div className="d-flex align-items-center gap-2">
                          <p className="m-0">Select Pandit</p>
                          <input type="checkbox" className='cursor-pointer' />
                        </div>
                      </Card>
                    </Col>
                  ))
                }
              </Row>
            </Col>
          </Row>
          <hr></hr>
        </Container>

        <div className="w-100 pe-5">
          <button
            className="d-block ms-auto py-2 px-4 border-0 rounded-5"
            style={{ background: "#FFB600" }}
            onClick={() => navigate(`/booknow`)}
          >
            Next
          </button>
        </div>
      </>

      <Footer />
    </>
  );
};

export default PoojaBooking;
