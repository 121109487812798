import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../ComponentsCss/Navigation.css";
import { FaBars, FaBeer } from "react-icons/fa";
import panditlogo from "../Images/panlogo.png";
import { Item } from "../helper/api";

export default function Navigation() {

  const [Iam,setIam] = useState('');
  const [token,setToken] = useState('');

  const validate = () =>{
    setIam(Item.getItem("Iam"));
    setToken(Item.getItem("token"));
  }

  useEffect(()=>{
    validate();
  },[]);

  const logoutHandler = () =>{
    Item.removeItem('token');
    Item.removeItem('Iam');
    validate();
  }

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="BgNavbar"
        sticky="top"
      >
        <Container fluid>
          <Navbar.Brand className="Header">
            <Link to="/">
              <div style={{ height: "60px", width: "70px" }}>
                <Image
                  src={panditlogo}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FaBars style={{ color: "black" }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="d-flex align-items-center justify-content-end w-100">
              <Link to="/" className="titleNavbar">
                Home
              </Link>
              <Nav.Link
                href="#action1"
                className="headerLink"
                id="navbarLine"
                disabled
              >
                |
              </Nav.Link>
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                renderMenuOnMount={true}
                className="headerLink"
              >
                <Container>
                  <Row>
                    <Col>
                      <Link
                        to="/services"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Pujas
                      </Link>
                      <br />
                      <Link
                        to="/havan"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Hawans
                      </Link>
                      <br />
                      <Link
                        to="/jaap"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Jaaps
                      </Link>
                      <br />
                      <Link
                        to="/path"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Paths
                      </Link>
                      <br />
                      <Link
                        to="/"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Bhajans & Chowki
                      </Link>
                      <br />
                      <Link
                        to="/festivalpuja"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Festival Puja
                      </Link>
                      <br />
                      <Link
                        to="/shantipuja"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Shanti Puja
                      </Link>
                      <br />
                      <Link
                        to="/pitrupuja"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Pitru Pujas
                      </Link>
                      <br />
                      <Link
                        to="/astrology"
                        className="headerLink"
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Astrology
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              {/* <Link to="/astrology" className="headerLink" style={{ margin: '8px' }}>Astrology</Link> */}
              <Link
                to="/about"
                className="headerLink"
                style={{ margin: "8px" }}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className="headerLink"
                style={{ margin: "8px" }}
              >
                Contact Us
              </Link>

              {Iam === "admin" && token ? (
                // Render admin component or JSX here
                <>
                  <Link to="/admindashboard/allusers" className="titleNavbar">
                    Dashboard
                  </Link>
                  <Link
                    to="/"
                    className="titleNavbar btn border-danger text-danger btn-sm"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </Link>
                </>
              ) : Iam === "user" && token ? (
                <>
                  <Link to="/user/dashboard/profile" className="titleNavbar">
                    Dashboard
                  </Link>
                  <Link
                    to="/"
                    className="titleNavbar btn border-danger text-danger btn-sm"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </Link>
                </>
              ) : Iam === "vendor" && token ? (
                <>
                  <Link to="/vendordashboard/profile" className="titleNavbar">
                    Dashboard
                  </Link>
                  <Link
                    to="/"
                    className="titleNavbar btn border-danger text-danger btn-sm"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </Link>
                </>
              ) : (
                <Link to="/user/sign-in" style={{ marginLeft: "150px" }}>
                  <Button
                    style={{
                      backgroundColor: "#ffb600",
                      color: "#000",
                      border: "none",
                      height: "45px",
                      width: "100px",
                      borderRadius: "20px",
                    }}
                  >
                    Log In
                  </Button>
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
{
  /* <Col md={6}>
<h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>Pujas</h1>
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Annapurna Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Bhoomi Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Brihaspati Vrat Udyapan Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Durga Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Engagement Puja - Sagai</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Fix your Muhurath</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Gand Mool Nakashtra Shanti Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Ganesh Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Godh Bharai Puja (Baby Shower)</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Grah Shanti Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Griha Pravesh</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Haldi Ceremony</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Janamdin Puja - Birthday Party</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Kuber Upasana Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Mahalaxmi Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Mundan or Chudakarana Ceremony</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Namakran Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>New Vehicle Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Office Opening Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Pusavn Sankar</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Roka Ceremony</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Rudrabhishek Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Saraswati Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Satyanarayan Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Suddhikaran Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Solah Somvar Udyapan Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Sutya Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vasta Shanti Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vishwakarma Puja</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Vivah (Marriage)</Link><br />
  <Link to="/" className="titleNavbar" style={{ fontWeight: 'normal', fontSize: '12px' }}>Yognopavit Sanskar</Link><br />
</Col> */
}
