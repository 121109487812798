import { gql } from '@apollo/client'


export const MUTATION_ADMIN_LOGIN = gql`
  mutation CreateAdmin($username: String, $password: String) {
    adminLogin(username: $username, password: $password) {
      adminToken
      adminTokenExpire
      adminId
    }
  }
`
export const MUTATION_SEND_OTP = gql`
  mutation Mutation($contact: String, $otp: String) {
  sendOtp(contact: $contact, otp: $otp)
}
`

export const MUATATION_USERLOGIN_OTP = gql`
mutation CreateUser($contact: String, $otp: String) {
  userOtpLogin(contact: $contact, otp: $otp) {
    userId
    userToken
    userTokenExpiration
  }
}
`
export const MUTATION_SEND_VENDIR_OTP = gql`
  mutation Mutation($contact: String, $otp: String) {
    sendVendorOtp(contact: $contact, otp: $otp)
  }
`
export const MUATATION_VENDORLOGIN_OTP = gql`
  mutation createVendor($contact: String, $otp: String) {
    vendorOtpLogin(contact: $contact, otp: $otp) {
      vendorId
      vendorToken
      vendorTokenExpire
    }
  }
`
export const MUTATION_DELETE_VENDOR_SERVICES= gql `
mutation Mutation($vendorId: ID, $vServiceId: ID) {
  deleteVendorService(vendorId: $vendorId, vServiceId: $vServiceId) {
    id
    services {
      id
    }
  }
}
`
export const MUTATION_DELETE_VENDOR = gql `
mutation Mutation($vendorId: ID) {
  deleteVendor(vendorId: $vendorId) {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
  }
}
`
export const MUTATION_CREATE_VENDER_SERVICES = gql `
mutation Mutation($vServiceInput: vServiceInput) {
  createVendorService(VServiceInput: $vServiceInput) {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
  }
}
`
export const MUTATION_UPDATE_VENDOR = gql`
mutation Mutation($vendorUpdateInput: vendorUpdateInput) {
  updateVendor(VendorUpdateInput: $vendorUpdateInput) {
    id
    fName
    lName
    contact
    username
    password
    type
    image
    alternativeContact
    email
    address
    city
    state
    pincode
    experience
    description
    availability
    availableTiming
    createdDateTime
    status
    services {
      id
      serviceType
      servicePrice
      serviceStatus
    }
    Expertise {
      id
      ExpertiseName
      ExpertisPrice
    }
  }
}
`
export const MUTATION_CREATE_NOTIFICATION = gql `
mutation Mutation($notificationInput: notificationInput) {
  createNotification(NotificationInput: $notificationInput) {
    id
    userId
    vendorId
    title
    description
    createDateTime
    status
  }
}
`
export const MUTATION_CREATE_BOOKING = gql `
mutation Mutation($bookingInput: bookingInput) {
  createBooking(BookingInput: $bookingInput) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`
export const MUTATION_UPDATE_USER = gql`
mutation Mutation($updateUserInput: updateUserInput) {
  updateUser(UpdateUserInput: $updateUserInput) {
    id
    fName
    lName
    contact
    email
    password
    address
    city
    state
    pincode
    longitude
    latitude
    subscriptionType
    subscriptionDateTime
    subscriptionAmount
    subscriptionName
    deviceToken
    createdDateTime
    status
  }
}
`
export const MUTATION_CHANGE_BOOKING_STATUS = gql`
mutation Mutation($bookingId: ID, $status: String) {
  updateStatusByBookingId(bookingId: $bookingId, status: $status) {
    id
    paymentId
    bookingNo
    userId
    vendorId
    vendorName
    serviceName
    servicePrice
    totalAmount
    scheduleDate
    scheduleTime
    address
    contact
    alternativeContact
    createDateTime
    status
  }
}
`

export const MUTATION_SEND_VENDOR_REQUEST = gql `
mutation Mutation($requestInput: requestInput) {
  createRequest(RequestInput: $requestInput) {
    id
    fName
    lName
    contact
    email
    message
    createdDateTime
    status
  }
}
`
export const MUTATION_DELETE_REQUEST = gql `
mutation Mutation($requestId: ID) {
  deleteRequest(requestId: $requestId) {
    id
    fName
    lName
    contact
    email
    message
    createdDateTime
    status
  }
}
`

export const MUTATION_CREATE_POOJA = gql`
mutation CreatePooja($poojaInput: poojaInput) {
  createPooja(PoojaInput: $poojaInput) {
    id
    poojaName
    poojaDescription
    poojaPrice
    poojaImage
    keyPoints
    poojaDuration
    poojaLanguage
    poojaLocation
    poojaSamagri
    createdDateTime
    status
  }
}

`

export const MUTATION_DELETE_POOJA = gql`
mutation DeletePooja($poojaId: ID) {
  deletePooja(poojaId: $poojaId) {
    id
    createdDateTime
    status
  }
}
`