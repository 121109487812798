import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { FaEnvelopeOpenText, FaMapMarkedAlt, FaPhoneAlt } from 'react-icons/fa';
import '../ComponentsCss/ContactUs.css'
import Mapcontact from '../Images/S12A041-removebg-preview.png'
import Lottie from 'react-lottie';
import * as animationData from '../Images/47539-rotating-earth.json'
import Tilt from 'react-parallax-tilt';

export default function ContactUs() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };


  return (
    <>
      <Container style={{ padding: '20px', marginTop: '20px' }} className='p-0 mt-5 px-3 px-md-0'>
        <p style={{ color: '#feb92d', fontWeight: 500, fontSize: '18px' }}>Contact </p>
        <h1 style={{ color: '#032276', fontWeight: 700, fontSize: '30px', marginBottom: '30px' }}>Contact With Us</h1>

        <Row>
          <Col md={6}>
            <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ padding: '15px' }}>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="First name"
                    style={{ height: '45px' }}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} className='mt-3 mt-md-0' md="6" controlId="validationCustom02">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Last name"
                    style={{ height: '45px' }}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Phone No"
                    style={{ height: '45px' }}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" className='mt-3 mt-md-0' controlId="validationCustom02">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Email"
                    style={{ height: '45px' }}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Control
                    required
                    as="textarea"
                    placeholder="Message"
                    style={{ height: '80px' }}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Button type="submit" style={{ height: '50px', width: '100%', background: '#ffb600', border: 'none', color: '#000', fontSize: '20px' }}>submit</Button>
            </Form>
          </Col>
          <Col md={6}>
            {/* <Image className='mx-auto d-block' src={Mapcontact} /> */}
            <Lottie options={defaultOptions}
              height={300}
              width={350}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }}>
          <Col md={4}>
            <Tilt>
              <a href={`mailto:Panditbulao108@gmail.com`}>
                <Card className="mx-auto d-block contactcard">
                  <div className="mx-auto d-block contacticon">
                    <FaEnvelopeOpenText
                      className="mx-auto d-block"
                      style={{ marginTop: "15px", height: "25px", width: "25px" }}
                    />
                  </div>
                  <p style={{ textAlign: "center", marginTop: "10px", color: "#032276", fontSize: "18px", fontWeight: 600 }}>Mail Address</p>
                  <p style={{ textAlign: "center", marginTop: "5px", color: "#032276" }}>Panditbulao108@gmail.com</p>
                </Card>
              </a>
            </Tilt>
          </Col>
          <Col md={4}>
            <Tilt>
              <a href={`tel:+91 6568966636`}>
                <Card className="mx-auto d-block contactcard">
                  <div className="mx-auto d-block contacticon">
                    <FaPhoneAlt
                      className="mx-auto d-block"
                      style={{ marginTop: "20px", height: "25px", width: "25px" }}
                    />
                  </div>
                  <p style={{ textAlign: "center", marginTop: "10px", color: "#032276", fontSize: "18px", fontWeight: 600 }}>Contact No.</p>
                  <p style={{ textAlign: "center", marginTop: "10px", color: "#032276" }}>+91 6568966636</p>
                </Card>
              </a>
            </Tilt>
          </Col>
          <Col md={4}>
            <Tilt>
              <Card className='mx-auto d-block contactcard'>
                <div className='mx-auto d-block contacticon'>
                  <FaMapMarkedAlt className='mx-auto d-block' style={{ marginTop: '20px', height: '25px', width: '25px' }} />
                </div>
                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Address</p>
                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276' }}>xyz Near Sagar 254</p>
              </Card>
            </Tilt>
          </Col>
        </Row>
      </Container>
    </>
  )
}
