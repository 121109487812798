import axios from "axios";
import { toast } from "react-toastify";
import { deleteFileFromS3 } from "./imageUploader";

const baseUrl = process.env.REACT_APP_BASE_URL || "https://panditbulao-nodejs-backend.vercel.app";

export const MyAPI = {
    GET: async (url, token = null) => {
        try {
            const response = await axios.get(`${baseUrl}${url}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            return {
                status: response.status,
                data: response.data,
                message:response.data.message || response.message,
            };
        } catch (error) {
            let errorMessage = 'An error occurred while processing your request.';
            if (error.response) {
                // The request was made and the server responded with a status code
                errorMessage = error.response.data.message || errorMessage;
                return {
                    status: error.response.status,
                    message: errorMessage,
                };
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage = 'No response received from server.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message || errorMessage;
            }
            return {
                status: 500,
                message: errorMessage,
            };
        }
    },
    POST: async (url, data, token = null) => {
        try {
            const response = await axios.post(`${baseUrl}${url}`, data, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            return {
                status: response.status,
                data: response.data,
            };
        } catch (error) {
            let errorMessage = 'An error occurred while processing your request.';
            if (error.response) {
                // The request was made and the server responded with a status code
                errorMessage = error.response.data.message || errorMessage;
                return {
                    status: error.response.status,
                    message: errorMessage,
                };
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage = 'No response received from server.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message || errorMessage;
            }
            return {
                status: 500,
                message: errorMessage,
            };
        }
    },
    PUT: async (url, data, token = null) => {
        try {
            const response = await axios.put(`${baseUrl}${url}`, data, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            return {
                status: response.status,
                data: response.data,
            };
        } catch (error) {
            let errorMessage = 'An error occurred while processing your request.';
            if (error.response) {
                // The request was made and the server responded with a status code
                errorMessage = error.response.data.message || errorMessage;
                return {
                    status: error.response.status,
                    message: errorMessage,
                };
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage = 'No response received from server.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message || errorMessage;
            }
            return {
                status: 500,
                message: errorMessage,
            };
        }
    },
    DELETE: async (url, token = null) => {
        try {
            const response = await axios.delete(`${baseUrl}${url}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            return {
                status: response.status,
                data: response.data,
            };
        } catch (error) {
            let errorMessage = 'An error occurred while processing your request.';
            if (error.response) {
                // The request was made and the server responded with a status code
                errorMessage = error.response.data.message || errorMessage;
                return {
                    status: error.response.status,
                    message: errorMessage,
                };
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage = 'No response received from server.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message || errorMessage;
            }
            return {
                status: 500,
                message: errorMessage,
            };
        }
    }    
};

export const Item = {
    getItem:(name)=>{
        return sessionStorage.getItem(name);
    },
    setItem:(name,value)=>{
        sessionStorage.setItem(name,value);
    },
    removeItem:(name)=>{
        sessionStorage.removeItem(name);
    },
}

export const MyError = {
    success:(message)=>{
        toast.success(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    },
    error:(message)=>{
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }
};

export const MyStatusOptions = [
    "PENDING",
    "ACTIVE",
    "REJECTED",
];

export const MY_AWS = {
    deleteFile: async (file_name)=>{
        try {
            await deleteFileFromS3(file_name)
            return true;
        } catch (error) {
            return false;
        }
    }
};