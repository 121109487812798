import { useQuery } from "@apollo/client";
import React from "react";
import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Moment from "react-moment";
import {
  GET_USERDATA_BY_USERID,
  GET_USER_BOOKING_BYID,
} from "../../graphql/Query";

export default function UserBooking() {
  {
    window.scrollTo(0, 0);
  }

  const userId = localStorage.getItem("userId");
  const { data: UserBookingData, loading: userBookingLoading } = useQuery(
    GET_USER_BOOKING_BYID,
    {
      variables: {
        userId: `${userId}`,
      },
    }
  );
  console.log("UserBookingData", UserBookingData);

  return (
    <>
      <Container fluid>
        <h6 style={{ textAlign: "center" }}>Your Bookings</h6>
        <hr></hr>
        <Row>
          <Col md={12}>
            <Card className="border-0">
              {userBookingLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                <Row>

                {UserBookingData &&
                UserBookingData.getBookingByUserId
                  .slice(0)
                  .reverse()
                  .map((useComDAta) => (
                    <>
                      <Col md={6} className="p-1" >
                        <div className="px-3 pe-0 border rounded-3 shadow mb-3" style={{overflow:'hidden'}}>
                        <Col md={12} className="d-flex align-items-center justify-content-end m-0 p-0 mb-3">
                            <div className="py-2 px-3 bg-warning"
                            style={{borderBottomLeftRadius:10}}
                            >Booking No. 142512001</div>
                        </Col>
                        <Row>
                        <Col md={6} className="d-flex mb-3 flex-column border-end">
                          <div className="d-flex mb-3">
                            <div
                              className="p-1 border"
                              style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "10%",
                              }}
                            >
                              <Image
                                classNAme="mx-auto d-block"
                                src="https://www.godpng.com/uploads/png/lord-rama-sita-png.png"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div className="ms-3 py-2">
                              <h6 className="m-0 p-0" style={{fontSize:13}}><b>John Smith</b></h6>
                              <p className="m-0 p-0" style={{fontSize:13}}>5+ Years</p>
                              <p className="m-0 p-0" style={{fontSize:13}}>Pending</p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div
                              className="p-1 border"
                              style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "10%",
                              }}
                            >
                              <Image
                                classNAme="mx-auto d-block"
                                src="https://www.godpng.com/uploads/png/lord-rama-sita-png.png"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <div className="ms-3 py-2">
                              <h6 className="m-0 p-0" style={{fontSize:13}}><b>John Smith</b></h6>
                              <p className="m-0 p-0" style={{fontSize:13}}>5+ Years</p>
                              <p className="m-0 p-0" style={{fontSize:13}}>Pending</p>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>

                              <h5
                                style={{ textAlign: "start", fontSize: "11px" }}
                              >
                                Schedule Date: {useComDAta.scheduleDate}
                              </h5>
                              <h5
                                style={{ textAlign: "start", fontSize: "11px" }}
                              >
                                Schedule Time: {useComDAta.scheduleTime}
                              </h5>
                              <h5
                                style={{
                                  textAlign: "start",
                                  fontSize: "11px",
                                }}
                              >
                                Service Name: {useComDAta.serviceName}
                              </h5>
                              <h5
                                style={{
                                  textAlign: "start",
                                  fontSize: "11px",
                                }}
                              >
                                Address: {useComDAta.address}
                              </h5>
                              <h5
                                style={{
                                  textAlign: "start",
                                  fontSize: "11px",
                                }}
                              >
                                Contact: {useComDAta.contact}
                              </h5>
                              <h5
                                style={{
                                  textAlign: "start",
                                  fontSize: "11px",
                                }}
                              >
                                Alternate Contact:{" "}
                                {useComDAta.alternativeContact}
                              </h5>
                              <h5
                                style={{
                                  textAlign: "start",
                                  fontSize: "13px",
                                }}
                              >
                                Booking No: {useComDAta.bookingNo}
                              </h5>

                              {useComDAta.status === "pending" ? (
                                <h5
                                  style={{
                                    textAlign: "start",
                                    fontSize: "11px",
                                  }}
                                >
                                  Status:{" "}
                                  <span style={{ color: "#ffa801" }}>
                                    {useComDAta.status}
                                  </span>
                                </h5>
                              ) : useComDAta.status === "complete" ? (
                                <h5
                                  style={{
                                    textAlign: "start",
                                    fontSize: "11px",
                                  }}
                                >
                                  Status:{" "}
                                  <span style={{ color: "green" }}>
                                    {useComDAta.status}
                                  </span>
                                </h5>
                              ) : (
                                <h5
                                  style={{
                                    textAlign: "start",
                                    fontSize: "11px",
                                  }}
                                >
                                  Status:{" "}
                                  <span style={{ color: "#ff3f34" }}>
                                    {useComDAta.status}
                                  </span>
                                </h5>
                              )}
                        </Col>
                        </Row>
                        <hr />
                        <Col md={12} className="d-flex">
                          <h6
                            style={{
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            Booking Date & Time &nbsp;
                          </h6>
                          <p style={{ textAlign: "center", fontSize: "12px" }}>
                            <Moment format="DD-MM-YYYY hh:mm a">
                              {useComDAta.createDateTime}
                            </Moment>
                          </p>
                        </Col>
                        </div>
                      </Col>
                    </>
                  ))}
                  </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
