import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../Footer";
import Navigation from "../Navigation";
import { MyAPI, MyError } from "../../helper/api";
import s3FileUpload from "../../helper/imageUploader";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../ButtonLoading";

export default function UserRegistration() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [image, setImage] = useState(null);
  const [phoneIsExist, setPhoneIsExist] = useState(false);

  const [btnLoaing, setButtonLoading] = useState(false);

  const handleRegistration = async () => {
    // Validation checks
    if (!phone.trim() || phone.length !== 10) {
      MyError.error("Please enter a valid phone number.");
      return;
    }

    // Validate email
    if (!email.trim()) {
      MyError.error("Please enter a valid email address.");
      return;
    }

    // Validate other fields
    if (!username.trim()) {
      MyError.error("Please enter a username.");
      return;
    }

    if (!password.trim()) {
      MyError.error("Please enter a password.");
      return;
    }

    if (!name.trim()) {
      MyError.error("Please enter a name.");
      return;
    }

    if (!whatsapp.trim() || whatsapp.length !== 10) {
      MyError.error("Please enter a valid WhatsApp number.");
      return;
    }

    if (!address.trim()) {
      MyError.error("Please enter an address.");
      return;
    }

    if (!state.trim()) {
      MyError.error("Please enter a state.");
      return;
    }

    if (!district.trim()) {
      MyError.error("Please enter a district.");
      return;
    }

    if (!city.trim()) {
      MyError.error("Please enter a city.");
      return;
    }

    if (!pincode.trim()) {
      MyError.error("Please enter a pincode.");
      return;
    }

    if (!image) {
      MyError.error("Please upload profile picture.");
      return;
    }

    if (phoneIsExist) {
      MyError.error("phone already exist.");
      return;
    }

    setButtonLoading(true);

    try {
      let profileImage = "";
      try {
        profileImage = await s3FileUpload(image);
        const data = {
          username,
          password,
          name,
          phone,
          email,
          whatsapp,
          address,
          state,
          district,
          city,
          pincode,
          image: profileImage,
        };
        console.log(data);

        let res = await MyAPI.POST("/user/create", data);
        setButtonLoading(false);
        let { status, message } = res.data || res;
        if (status === true) {
          MyError.success(message);
          navigate("/user/sign-in");
        } else {
          MyError.error(message);
        }
        console.log("api res", res.data);
      } catch (error) {
        console.log(error);
        MyError.error(error.message);
      }
      return;
    } catch (error) {
      setButtonLoading(false);
      console.error("Error during registration:", error);
      MyError.error(error.message);
      // Handle registration error
    }
  };

  const checkPhoneIsExist = async () => {
    if (phone.trim().length > 0) {
      let res = await MyAPI.GET(
        `/check/user?field=phone_number&value=${phone}`
      );
      let { status, message, data } = res.data || res;
      console.log(data);
      if (status === true) {
        let { isValid } = data;
        if (isValid) {
          setPhoneIsExist(false);
        } else {
          setPhoneIsExist(true);
          MyError.error(message);
        }
      } else {
        MyError.error(message);
      }
    }
  };

  useEffect(() => {
    let time = setTimeout(() => {
      checkPhoneIsExist();
    }, 1000);

    return () => clearTimeout(time);
  }, [phone]);

  return (
    <>
      <Navigation />
      <Container style={{ padding: "50px" }} fluid>
        <h3 style={{ textAlign: "center", marginTop: "10px", fontWeight: 600 }}>
          User Registration
        </h3>
        <hr style={{ paddingBottom: "20px" }} />

        <Card
          style={{
            margin: "40px 10px 10px 10px",
            borderRadius: "25px",
            boxShadow: "5px 5px 5px 5px #f5f6f8",
            borderBottom: "5px solid #feb92d",
          }}
        >
          <Row noValidate style={{ padding: "40px" }}>
            <Col md={6} className="mt-3">
              <Form.Group controlId="name">
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="username">
                <Form.Control
                  required
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="email">
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="password">
                <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="phone">
                <Form.Control
                  className={
                    phoneIsExist ? "border border-danger text-danger" : ""
                  }
                  required
                  type="number"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} className="mt-3">
              <Form.Group controlId="whatsapp">
                <Form.Control
                  required
                  type="number"
                  placeholder="WhatsApp"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="address">
                <Form.Control
                  required
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="state">
                <Form.Control
                  required
                  type="text"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="district">
                <Form.Control
                  required
                  type="text"
                  placeholder="District"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="city">
                <Form.Control
                  required
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="pincode">
                <Form.Control
                  required
                  type="number"
                  placeholder="Pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mt-3">
              <Form.Group controlId="image">
                <Form.Control
                  required
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card>
        {btnLoaing ? (
          <ButtonLoading />
        ) : (
          <Button
            onClick={handleRegistration}
            className="mx-auto d-block"
            disabled={phoneIsExist}
            style={{
              fontSize: "14px",
              marginTop: "40px",
              backgroundColor: "#032276",
              height: "50px",
              width: "30%",
              borderRadius: "20px",
            }}
            type="button"
          >
            Register
          </Button>
        )}
      </Container>
      <Footer />
    </>
  );
}
