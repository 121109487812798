import React, { useEffect, useState } from "react";
import { Button, Card, Form, Alert, Col } from "react-bootstrap";
import s3FileUpload from "../../helper/imageUploader";
import { Item, MyAPI, MyError } from "../../helper/api";
import Loading from "../Loading";
import ButtonLoading from "../ButtonLoading";

const AddPooja = () => {
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [description, setDescription] = useState("");
  const [categaryImage, setCategoryImage] = useState(null);
  const [errors, setErrors] = useState({});

  const [allCategory, setAllCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const fetchAllCategory = async () => {
    let res = await MyAPI.GET(`/admin/category`, Item.getItem("token"));
    setIsLoading(false);
    let { status, message, data } = res.data || res;

    if (status === true) {
      setAllCategory(data);
    } else {
      MyError.error(message);
    }
    console.log(res.data);
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!title.trim()) {
      errors.title = "Pooja name is required";
      isValid = false;
    }

    if (!categoryId.trim()) {
      errors.categoryId = "Pooja category is required";
      isValid = false;
    }

    if (!description) {
      errors.description = "Pooja description is required";
      isValid = false;
    }

    if (!categaryImage) {
      errors.categaryImage = "Pooja image is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setBtnLoading(true);
      try {
        const uploadedFileName = await s3FileUpload(categaryImage);
        if (uploadedFileName) {
          console.log("File uploaded successfully:", uploadedFileName);
          let res = await MyAPI.POST(
            "/admin/pooja",
            { title, image: uploadedFileName, description, categoryId },
            Item.getItem("token")
          );
          setBtnLoading(false);
          console.log("category added", res.data);
          let { status, message, data } = res.data || res;
          if (status === true) {
            setCategoryImage(null);
            setTitle("");
            setDescription("");
            setCategoryId("");
            MyError.success(message);
          } else {
            MyError.error(message);
          }
        } else {
          MyError.error("s3 file uploading error.");
        }
      } catch (error) {
        setBtnLoading(false);
        MyError.error(error.message);
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <h1 className="text-center">Add Pooja</h1>
      <Card>
        <Card.Body className="pt-3">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="poojaPrice" className="form-label">
                Pooja Category
              </label>
              <select
                className="form-select"
                id="poojaPrice"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option selected>select</option>
                {allCategory &&
                  allCategory.length > 0 &&
                  allCategory.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                {allCategory && allCategory.length === 0 && (
                  <option>Category not found.</option>
                )}
              </select>
              {errors.categoryId && (
                <Form.Text className="text-danger">
                  {errors.categoryId}
                </Form.Text>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="poojaName" className="form-label">
                Pooja Name
              </label>
              <input
                type="text"
                className="form-control"
                id="poojaName"
                placeholder="Enter Pooja Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {errors.title && (
                <Form.Text className="text-danger">{errors.title}</Form.Text>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="poojaImage" className="form-label">
                Pooja Image
              </label>
              <input
                type="file"
                className="form-control"
                id="poojaImage"
                onChange={(e) => setCategoryImage(e.target.files[0])}
              />
              {errors.categaryImage && (
                <Form.Text className="text-danger">
                  {errors.categaryImage}
                </Form.Text>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="categoryStatus" className="form-label">
                Pooja Description
              </label>
              <textarea
                className="form-control"
                id="categoryStatus"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3} // Adjust the number of rows as needed
                placeholder="Enter Pooja Description"
              />
              {errors.description && (
                <Form.Text className="text-danger">
                  {errors.description}
                </Form.Text>
              )}
            </div>

            <Col md={2}>
              {btnLoading ? (
                <ButtonLoading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Add Pooja
                </button>
              )}
            </Col>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddPooja;
