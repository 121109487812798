import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Container, Form, Image, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { MUTATION_UPDATE_USER, MUTATION_UPDATE_VENDOR } from '../../graphql/Mutation';
import Lottie from 'react-lottie';
import * as animationData from '../../Images/94123-1hour-login-effect.json'
import swal from 'sweetalert';
import Navigation from '../Navigation';
import Footer from '../Footer';
import jai from "../../Music/Jai Shri Ram - Message.mp3"
import { assertValidSDLExtension } from 'graphql/validation/validate';
import { QUERY_GET_VENDOR_BY_ID } from '../../graphql/Query';

export default function VendorUpdate() {


    function play() {
        new Audio(jai).play()
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const navigate = useNavigate();
    const [validated2, setValidated2] = useState(false);

    const vendorId = localStorage.getItem('vendorId')
    console.log('vendorId',vendorId)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fName, setFname] = useState('')
    const [lName, setLname] = useState('')
    const [contact, setContact] = useState('')
    const [alterNativeContact, setAlterNativeContact] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pincode, setPincode] = useState('')
    const [experience, setExperience] = useState('')
    const [description, setDescription] = useState('')
    const [availability, setAvailable] = useState('')
    const [availableTiming, setAvailableTiming] = useState('')

    const [updateVendor, { loading: updateVendorLoading }] = useMutation(MUTATION_UPDATE_VENDOR)



    const handleVendorUpdate = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            setValidated2(false);
            event.preventDefault();
            // try {
            //     const uniqueId = uniqid();
            //     var regex = new RegExp('[^.]+$');
            //     var extension = selectImage.name.match(regex);
            //     var uniqueFileName = uniqueId + '-' + uniqueId + '.' + extension[0];
            //     setImageBox(uniqueFileName)
            //     const params = {
            //         Bucket: BUCKET_NAME,
            //         Key: uniqueFileName,
            //         Body: selectImage,
            //     };
            //     s3.upload(params, function (err, data) {
            //         if (err) {
            //             throw err;
            //         } else {
                        updateVendor({
                            variables: {
                                "vendorUpdateInput": {
                                    "id": `${vendorId}`,
                                    "fName": `${fName}`,
                                    "lName": `${lName}`,
                                    "username": null,
                                    "password": null,
                                    "image": null,
                                    "alternativeContact": `${alterNativeContact}`,
                                    "email": `${email}`,
                                    "address": `${address}`,
                                    "city": `${city}`,
                                    "state": `${state}`,
                                    "pincode":  parseInt(pincode),
                                    "experience": `${experience}`,
                                    "description": `${description}`,
                                    "availability": `${availability}`,
                                    "availableTiming": `${availableTiming}`
                                }
                            }
                        })

                        swal({
                            title: "Added",
                            text: "Your Profile is Updated",
                            icon: "success",

                        })
                        play()
                        setFname('')
                        setLname('')
                        setContact('')
                        setAlterNativeContact('')
                        setEmail('')
                        setAddress('')
                        setCity('')
                        setState('')
                        setPincode('')
                        setExperience('')
                        setDescription('')
                        setAvailable('')
                        setAvailableTiming('')
                        navigate("/vendordashboard/profile")
                    }
            //     });
            // }
        //     catch (err) {
        //         swal({
        //             title: 'Error!!!',
        //             text: 'Error please try again',
        //             icon: 'error',
        //         });
        //         console.log(err);
        //         setUploadLoading(false)
        //     }
        // }

    };

    // const handleSubmitVendor = (event) => {
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         setValidated2(true);
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //     else {
    //         setValidated2(false);
    //         event.preventDefault();
    //         updateVendor({
    //             variables: {
    //                 "vendorUpdateInput": {
    //                     "id": `${vendorId}`,
    //                     "fName": `${fName}`,
    //                     "lName": `${lName}`,
    //                     "username": null,
    //                     "password": null,
    //                     "image": null,
    //                     "alternativeContact": `${alterNativeContact}`,
    //                     "email": `${email}`,
    //                     "address": `${address}`,
    //                     "city": `${city}`,
    //                     "state": `${state}`,
    //                     "pincode": pincode,
    //                     "experience": `${experience}`,
    //                     "Description": `${description}`,
    //                     "availability": `${availability}`,
    //                     "availableTiming": `${availableTiming}`
    //                 }
    //             }
    //         })
    //         swal({
    //             title: "Profile Updated",
    //             text: "Jay Shree Ram..",
    //             icon: "success",
    //         })
    //         // play()
    //         navigate("/")
    //     }
    // };

    return (
        <>
            <Navigation />
            <Container style={{ fontSize: '14px', padding: '20px' }}>
                <Row>
                    <Col md={4}>
                        <Lottie options={defaultOptions}
                            style={{ marginTop: '30px' }}
                            height={300}
                            width={300}
                        />
                    </Col>
                    <Col md={7}>
                        <div style={{ border: '3px solid #022279', padding: '25px', borderRadius: '25px', margin: '20px' }}>


                            <h6 style={{ marginTop: '15px', textAlign: 'center' }}>Please Fill Details First</h6>
                            <hr></hr>

                            <Form noValidate validated={validated2} onSubmit={handleVendorUpdate} style={{ fontSize: '14px', margin: '30px 0px 0px 0px' }}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>First name <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First name"
                                            onChange={(e) => setFname(e.target.value)} value={fName}

                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Last name <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last name"
                                            onChange={(e) => setLname(e.target.value)} value={lName}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Email <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Email"
                                            onChange={(e) => setEmail(e.target.value)} value={email}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Address <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Address"
                                            onChange={(e) => setAddress(e.target.value)} value={address}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>Alternative Contact</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Alternative No."
                                            onChange={(e) => setAlterNativeContact(e.target.value)} value={alterNativeContact}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Description"
                                            onChange={(e) => setDescription(e.target.value)} value={description}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>City <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="City" required onChange={(e) => setCity(e.target.value)} value={city} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>State <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="State" required onChange={(e) => setState(e.target.value)} value={state} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>Pincode <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Pincode" required onChange={(e) => setPincode(e.target.value)} value={pincode} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                                        <Form.Label>Experience <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Experience" required onChange={(e) => setExperience(e.target.value)} value={experience} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>Availability</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={(e) => setAvailable(e.target.value)} value={availability}>
                                            <option>Choose One</option>
                                            <option value="Yes">Yes</option>
                                            <option value="Not Available">Not Available Today</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                                        <Form.Label>Availability Timing <span style={{ color: 'red', marginTop: '10px' }}>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Timing" required onChange={(e) => setAvailableTiming(e.target.value)} value={availableTiming} />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        required
                                        label="Agree to terms and conditions"
                                        feedbackType="invalid"
                                    />
                                </Form.Group>

                                {
                                    updateVendorLoading ?
                                        <Spinner className='mx-auto d-block' animation="border" variant="info" />
                                        :
                                        <Button className='mx-auto d-block' type="submit" style={{ backgroundColor: '#f39c12', border: 'none', color: '#000' }}>Submit form</Button>
                                }

                            </Form>

                        </div>
                    </Col>

                </Row>

            </Container>
            <Footer />
        </>


    )
}
