import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import "../ComponentsCss/MainContent.css";
import Pandit from "../Images/pandite-unscreen.gif";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import { MyError } from "../helper/api";

export default function MainContaint() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const handleSerach = (e) =>{
    e.preventDefault();
    if(!searchInput.trim()){
      MyError.error('Please enter a valid search query');
      return;
    }
    navigate(`/search/${searchInput}`);
  };

  return (
    <>
      <Container style={{ paddingTop: "80px" }} fluid>
        <Row style={{ marginLeft: "30px" }}>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <p
              style={{
                fontWeight: "normal",
                fontSize: "40px",
                color: "#022279",
              }}
            >
              Find{" "}
              <span
                style={{ fontSize: "40px", color: "#ffb600", fontWeight: 600 }}
              >
                Pandit's
              </span>{" "}
              &
            </p>
            <h1 style={{ fontSize: "45px", color: "#022279", fontWeight: 800 }}>
              Get Your Puja's Done
            </h1>

            <hr
              style={{
                marginTop: "20px",
                color: "#ffb600 ",
                borderBottom: "5px solid #ffb600",
              }}
            ></hr>

            <form onSubmit={(e)=>handleSerach(e)} className="w-100">
              <div className="search_bar py-2 px-4 shadow d-flex align-items-center justify-content-between">
                <input
                  type="text"
                  className="border-0"
                  onChange={(e) => setSearchInput(e.target.value)}
                  value={searchInput}
                  placeholder="Enter your puja name or pandit's name..."
                />
                <button type="submit">Search</button>
              </div>
            </form>
          </Col>
          <Col md={6} className="d-flex align-items-center mt-3 mt-md-0">
            <div className="imgpandit mx-auto">
              <Image
                src={Pandit}
                style={{ height: "70%", width: "70%", objectFit: "cover" }}
                alt="Responsive image"
              ></Image>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
