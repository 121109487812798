/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../Footer";
import Navigation from "../Navigation";
import Lottie from "react-lottie";
import * as animationData from "../../Images/132033-green-login.json";
import { Navigate, useNavigate } from "react-router-dom";
import { Item, MyAPI, MyError } from "../../helper/api";
import ButtonLoading from "../ButtonLoading";

export default function VendorLogin() {
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const handleVendorLogin = async (e) => {
    e.preventDefault();

    if (!username.trim()) {
      MyError.error("please enter a valid username or email.");
      return;
    }

    if (!password.trim()) {
      MyError.error("please enter a valid password.");
      return;
    }

    setBtnLoading(true);

    let res = await MyAPI.POST("/pandit/login", { username, password });

    setBtnLoading(false);

    let { status, message, data } = res.data || res;

    if (status === true) {
      Item.setItem("token", data.token);
      Item.setItem("Iam", "vendor");
      MyError.success(message);
      navigate("/vendordashboard/profile");
    } else {
      MyError.error(message);
    }
  };

  return (
    <>
      <Navigation />
      <Container style={{ padding: "50px" }} fluid>
        <h2 className="text-center mb-4 text-decoration-underline">SignIn</h2>
        {/* <hr style={{ paddingBottom: "20px" }}></hr> */}

        <Row>
          <Col md={6}>
            <div style={{ height: "100%", width: "100%" }}>
              <Lottie
                options={defaultOptions}
                style={{ marginTop: "30px" }}
                height={300}
                width={300}
              />
            </div>
          </Col>
          <Col md={6}>
            <Card
              className="shadow"
              style={{
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                // boxShadow: " 5px 5px 5px 5px #f5f6f8",
                borderBottom: "5px solid #feb92d",
              }}
            >
              <Form
                noValidate
                onSubmit={handleVendorLogin}
                style={{ padding: "40px" }}
              >
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter username / email. "
                    style={{
                      fontSize: "14px",
                      height: "50px",
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid #032276 ",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enter Password. "
                    style={{
                      fontSize: "14px",
                      height: "50px",
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid #032276 ",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Group>
                <Col md={12} className="d-flex align-items-center justify-content-center">
                  {btnLoading ? (
                    <ButtonLoading />
                  ) : (
                    <Button
                    className="mt-4"
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#032276",
                        height: "50px",
                        width: "60%",
                        borderRadius: "20px",
                      }}
                      type="submit"
                    >
                      Sign In
                    </Button>
                  )}
                </Col>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
