import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { FaMapMarkerAlt, FaMoneyBillAlt, FaStopwatch } from 'react-icons/fa'
import '../ComponentsCss/RecentlyPosted.css'

export default function RecentlyPosted() {
  return (
    <>
     <Container className='recentmain' fluid>
        <p style={{fontSize:'18px',color:'#ffb600'}}>Top Pandit's</p>
        <h2 style={{color:'#022278',fontWeight:700}}>Our Top Pandit's</h2>

        <Row>
            <Col md={6}>

                <Card className='cardtoppandit'>
                <Row>
                    <Col md={2}>
                     <div className='icotoppandit'></div>
                        
                    </Col>
                    <Col md={8}>
                     <h2 style={{marginLeft:'25px',marginTop:'22px',color:'#022278',fontWeight:600,fontSize:'20px'}}>Electrician Required in Brooklyn</h2>
                     <p style={{color:'#b6a5a0',fontSize:'20px',marginLeft:'25px'}}>Aone Theme</p>
                    <p style={{marginLeft:'25px'}}><FaStopwatch /> 3 Weeks ago  <FaMapMarkerAlt style={{marginLeft:'20px'}}/>Sagar   <FaMoneyBillAlt style={{marginLeft:'20px'}}/> ₹ 1200</p>
                    
                    </Col>
                </Row>
                </Card>
            </Col>
            <Col md={6}>

                <Card className='cardtoppandit'>
                <Row>
                    <Col md={2}>
                     <div className='icotoppandit'></div>
                        
                    </Col>
                    <Col md={8}>
                     <h2 style={{marginLeft:'25px',marginTop:'22px',color:'#022278',fontWeight:600,fontSize:'20px'}}>Looking For Home Cleaner sagar</h2>
                     <p style={{color:'#b6a5a0',fontSize:'20px',marginLeft:'25px'}}>Aone Theme</p>
                    <p style={{marginLeft:'25px'}}><FaStopwatch /> 3 Weeks ago  <FaMapMarkerAlt style={{marginLeft:'20px'}}/>Sagar   <FaMoneyBillAlt style={{marginLeft:'20px'}}/> ₹ 1200</p>
                    
                    </Col>
                </Row>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col md={6}>

                <Card className='cardtoppandit'>
                <Row>
                    <Col md={2}>
                     <div className='icotoppandit'></div>
                        
                    </Col>
                    <Col md={8}>
                     <h2 style={{marginLeft:'25px',marginTop:'22px',color:'#022278',fontWeight:600,fontSize:'20px'}}>Electrician Required in Brooklyn</h2>
                     <p style={{color:'#b6a5a0',fontSize:'20px',marginLeft:'25px'}}>Aone Theme</p>
                    <p style={{marginLeft:'25px'}}><FaStopwatch /> 3 Weeks ago  <FaMapMarkerAlt style={{marginLeft:'20px'}}/>Sagar   <FaMoneyBillAlt style={{marginLeft:'20px'}}/> ₹ 1200</p>
                    
                    </Col>
                </Row>
                </Card>
            </Col>
            <Col md={6}>

                <Card className='cardtoppandit'>
                <Row>
                    <Col md={2}>
                     <div className='icotoppandit'></div>
                        
                    </Col>
                    <Col md={8}>
                     <h2 style={{marginLeft:'25px',marginTop:'22px',color:'#022278',fontWeight:600,fontSize:'20px'}}>Looking For Home Cleaner sagar</h2>
                     <p style={{color:'#b6a5a0',fontSize:'20px',marginLeft:'25px'}}>Aone Theme</p>
                    <p style={{marginLeft:'25px'}}><FaStopwatch /> 3 Weeks ago  <FaMapMarkerAlt style={{marginLeft:'20px'}}/>Sagar   <FaMoneyBillAlt style={{marginLeft:'20px'}}/> ₹ 1200</p>
                    
                    </Col>
                </Row>
                </Card>
            </Col>
        </Row>
        <Button className='mx-auto d-block' style={{backgroundColor:'#ffb600',border:'none',marginTop:'20px',color:'black'}}>View More</Button>
     </Container> 
    </>
  )
}
