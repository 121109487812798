import AboutUsPage from "./Components/AboutUsPage";
import AddCategory from "./Components/Admin/AddCategory";
import AddPandit from "./Components/Admin/AddPandit";
import AddPooja from "./Components/Admin/AddPooja";
import AdminAllBookings from "./Components/Admin/AdminAllBookings";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import AdminLogin from "./Components/Admin/AdminLogin";
import AllCategory from "./Components/Admin/AllCategory";
import AllPandits from "./Components/Admin/AllPandits";
import AllPooja from "./Components/Admin/AllPooja";
import AllUsers from "./Components/Admin/AllUsers";
import AllVendors from "./Components/Admin/AllVendors";
import EditPandit from "./Components/Admin/EditPandit";
import UserById from "./Components/Admin/UserById";
import VendorsRequest from "./Components/Admin/VendorsRequest";
import ViewPandit from "./Components/Admin/ViewPandit";
import AllPoojasCategory from "./Components/AllPoojasCategory";
import Astrology from "./Components/Astrology";
import BookNow from "./Components/BookNow";
import ContactUsPage from "./Components/ContactUsPage";
import Home from "./Components/Home";
import PoojaBooking from "./Components/PoojaBooking";
import PoojaSevices from "./Components/PoojaSevices";
import SearchPage from "./Components/SearchPage";
import FestivalPooja from "./Components/Servicess/FestivalPooja";
import Havan from "./Components/Servicess/Havan";
import Jaap from "./Components/Servicess/Jaap";
import Paths from "./Components/Servicess/Paths";
import Pitrupuja from "./Components/Servicess/Pitrupuja";
import Shantipuja from "./Components/Servicess/Shantipuja";
import UserBooking from "./Components/User/UserBooking";
import UserDashboard from "./Components/User/UserDashboard";
import UserLogin from "./Components/User/UserLogin";
import UserPayments from "./Components/User/UserPayments";
import UserProfile from "./Components/User/UserProfile";
import UserRegistration from "./Components/User/UserRegistration";
import UserUpdateProfile from "./Components/User/UserUpdateProfile";
import EditVendor from "./Components/Vendor/EditVendor";
import PublicProfile from "./Components/Vendor/PublicProfile";
import SignUp from "./Components/Vendor/SignUp";
import VenderProfile from "./Components/Vendor/VenderProfile";
import VendorBooking from "./Components/Vendor/VendorBooking";
import VendorCancelOrder from "./Components/Vendor/VendorCancelOrder";
import VendorCompleteBooking from "./Components/Vendor/VendorCompleteBooking";
import VendorDashboard from "./Components/Vendor/VendorDashboard";
import VendorLogin from "./Components/Vendor/VendorLogin";
import VendorNotification from "./Components/Vendor/VendorNotification";
import VendorUpdate from "./Components/Vendor/VendorUpdate";

export const CustomRoutes = [
  {
    name: "index",
    component: <Home />,
    path: "/",
    isGroup: false,
  },
  {
    name: "serach",
    component: <SearchPage />,
    path: "/search/:value",
    isGroup: false,
  },
  {
    name: "user login",
    component: <UserLogin />,
    path: "/user/sign-in",
    isGroup: false,
  },
  {
    name: "user register",
    component: <UserRegistration />,
    path: "/user/sign-up",
    isGroup: false,
  },
  {
    name: "services",
    component: <PoojaSevices />,
    path: "/services",
    isGroup: false,
  },
  {
    name: "contact",
    component: <ContactUsPage />,
    path: "/contact",
    isGroup: false,
  },
  {
    name: "about",
    component: <AboutUsPage />,
    path: "/about",
    isGroup: false,
  },
  {
    name: "admin login",
    component: <AdminLogin />,
    path: "/admin/login",
    isGroup: false,
  },
  {
    name: "update data",
    component: <UserUpdateProfile />,
    path: "/updatedata",
    isGroup: false,
  },
  {
    name: "pandit for pooja",
    component: <PoojaBooking />,
    path: "/pooja/:service",
    isGroup: false,
  },
  {
    name: "all poojas by category",
    component: <AllPoojasCategory />,
    path: "/category/:id",
    isGroup: false,
  },
  {
    name: "astrology",
    component: <Astrology />,
    path: "/astrology",
    isGroup: false,
  },
  {
    name: "havan",
    component: <Havan />,
    path: "/havan",
    isGroup: false,
  },
  {
    name: "jaap",
    component: <Jaap />,
    path: "/jaap",
    isGroup: false,
  },
  {
    name: "path",
    component: <Paths />,
    path: "/path",
    isGroup: false,
  },
  {
    name: "festival pooja",
    component: <FestivalPooja />,
    path: "/festivalpuja",
    isGroup: false,
  },
  {
    name: "shanti pooja",
    component: <Shantipuja />,
    path: "/shantipuja",
    isGroup: false,
  },
  {
    name: "pitrupooja",
    component: <Pitrupuja />,
    path: "/pitrupuja",
    isGroup: false,
  },
  {
    name: "book now",
    component: <BookNow />,
    path: "/booknow",
    isGroup: false,
  },
  {
    name: "vendor login",
    component: <VendorLogin />,
    path: "/pandit/sign-in",
    isGroup: false,
  },
  {
    name: "vendor sign up",
    component: <SignUp />,
    path: "/pandit/sign-up",
    isGroup: false,
  },
  {
    name: "vendor update",
    component: <VendorUpdate />,
    path: "/vendorupdate",
    isGroup: false,
  },
  {
    name: "profile",
    component: <PublicProfile />,
    path: "/profile/:id",
    isGroup: false,
  },
  {
    name: "admin dashboard",
    component: <AdminDashboard />,
    path: "/admindashboard",
    isGroup: true,
    childs: [
      {
        name: "all users",
        component: <AllUsers />,
        path: "allusers",
      },
      {
        name: "get user",
        component: <UserById />,
        path: "user/:id",
      },
      {
        name: "vendors",
        component: <AllVendors />,
        path: "vendors",
      },
      {
        name: "all bookings",
        component: <AdminAllBookings />,
        path: "allbookings",
      },
      {
        name: "all requests",
        component: <VendorsRequest />,
        path: "allrequests",
      },
      {
        name: "add pooja",
        component: <AddPooja />,
        path: "addpooja",
      },
      {
        name: "all pooja",
        component: <AllPooja />,
        path: "allpooja",
      },
      {
        name: "add category",
        component: <AddCategory />,
        path: "categary/add",
      },
      {
        name: "all category",
        component: <AllCategory />,
        path: "categary/all",
      },
      {
        name: "all pandits",
        component: <AllPandits />,
        path: "pandit/all",
      },
      {
        name: "add pandit",
        component: <AddPandit />,
        path: "pandit/add",
      },
      {
        name: "edit pandit",
        component: <EditPandit />,
        path: "pandit/edit/:id",
      },
      {
        name: "view pandit",
        component: <ViewPandit />,
        path: "pandit/:id",
      },
    ],
  },
  {
    name: "vendor dashboard",
    component: <VendorDashboard />,
    path: "/VendorDashboard",
    isGroup: true,
    childs: [
      {
        name: "profile",
        component: <VenderProfile />,
        path: "profile",
      },
      {
        name: "profile Edit",
        component: <EditVendor />,
        path: "profile/:id",
      },
      {
        name: "bookings",
        component: <VendorBooking />,
        path: "bookings",
      },
      {
        name: "complete bookings",
        component: <VendorCompleteBooking />,
        path: "completebookings",
      },
      {
        name: "cancel bookings",
        component: <VendorCancelOrder />,
        path: "cancelbookings",
      },
      {
        name: "notifications",
        component: <VendorNotification />,
        path: "notifications",
      },
    ],
  },
  {
    name: "user dashboard",
    component: <UserDashboard />,
    path: "/user/dashboard",
    isGroup: true,
    childs: [
      {
        name: "bookings",
        component: <UserBooking />,
        path: "bookings",
      },
      {
        name: "profile",
        component: <UserProfile />,
        path: "profile",
      },
      {
        name: "payments",
        component: <UserPayments />,
        path: "payments",
      },
    ],
  },
];
