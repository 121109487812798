import React from "react";
import MainContaint from "./MainContaint";
import Navigation from "./Navigation";
import Categories from "../Components/Categories";
import Steps from "./Steps";
import Footer from "./Footer";
import RecentlyPosted from "./RecentlyPosted";
import Statics from "./Statics";
import AboutUs from "./AboutUs";
import AreYouPandit from "./AreYouPandit";
import ContactUs from "./ContactUs";
import Testimonial from "./Testimonial";
import Loading from "./Loading";

export default function Home() {
  return (
    <>
      
      <Navigation />

      <MainContaint />

      <Categories />

      <Steps />

      <RecentlyPosted />

      <Statics />

      <AboutUs />

      <AreYouPandit />

      <ContactUs />

      <Testimonial />

      <Footer />
    </>
  );
}
