import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Navigation from '../Navigation';
import '../../ComponentsCss/Categories.css'
import kalash from '../../Images/360_F_62211372_OzJ9Hm86JXKiJjkH9XkH1PhHOIEbbKTp-removebg-preview.png'
import Footer from '../Footer';

export default function Havan() {
    {
        window.scrollTo(0, 0)
    }

    let navigate = useNavigate();

    const getVendorList = (value) => {
        navigate(`/panditforpoooja/${value}`)
    }

    return (
        <>
            <Navigation />
            <Container style={{padding:'30px'}} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                        </Link>
                    </Col>
                    <Col md={8}>
                        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Our Hawan Services</h5>

                    </Col>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Ayush Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Ayush Havan</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Chandi Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Chandi Havan</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Dhanvanti Havan") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Dhanvanti Havan</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Ganpati Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Ganpati Havan</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Gayatri Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Gayatri Havan</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Laxmi Kuber Havan") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Laxmi Kuber Havan</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Laxmi Narsimbha Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Laxmi Narsimbha Havan</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Mrityunjay Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Mrityunjay Havan</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Navagrah Havan") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Navagrah Havan</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Rudra Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Rudra Havan</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Saraswati Havan") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Saraswati Havan</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Sudarshan Havan") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Sudarshan Havan</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
