import React from 'react'
import { Card, Carousel, Col, Container, Image, Row } from 'react-bootstrap'
import { FaEnvelopeOpenText, FaMapMarkedAlt, FaPhoneAlt } from 'react-icons/fa'
import '../ComponentsCss/Testimonial.css'
import userPic from '../Images/pandit-removebg-preview.png'
import Slider from "react-slick";

export default function Testimonial() {

    var settings = {
        arrows: false,
        dots: true,
        pauseOnHover: true,
        infinite: true,
        speed: 2000,
        autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
         responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      };

    return (
        <>
            <Container style={{ marginTop: '45px', backgroundColor: '#032276', padding: '50px', borderRadius: '35px' }} >
                <p style={{color:'#feb92d',textAlign:'center',fontSize:'20px',fontWeight:600}}>T E S T I M O N I A L S</p>
                <Slider {...settings}>
                <div>
                    <Row>
                        <Col md={4}>


                            {/* <Card className='mx-auto d-block teztimonialcard'>
                          <p style={{textAlign:'center',marginTop:'30px',color:'#032276',fontSize:'20px',fontWeight:600}}>Mail Address</p>
                          <p style={{textAlign:'center',marginTop:'10px',color:'#032276',fontSize:'14px',padding:'30px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                          <div className='mx-auto d-block testicon'>
                            <FaEnvelopeOpenText className='mx-auto d-block' style={{marginTop:'15px',height:'30px',width:'30px'}}/>
                          </div>
                          <div style={{marginTop:-10,overflow:"hidden",boxSizing:"border-box"}} >
                          <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                          </div>
                    </Card> */}
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Mail Address</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                    {/* <FaEnvelopeOpenText className='mx-auto d-block' style={{ marginTop: '15px', height: '30px', width: '30px' }} /> */}
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Contact No.</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px' }}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                   
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                    {/* <FaPhoneAlt className='mx-auto d-block' style={{ marginTop: '20px', height: '30px', width: '30px' }} /> */}
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Address</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                   
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                    {/* <FaMapMarkedAlt className='mx-auto d-block' style={{ marginTop: '20px', height: '30px', width: '30px' }} /> */}
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div>

                    <Row>
                        <Col md={4}>

                            {/* <Card className='mx-auto d-block teztimonialcard'>
                          <p style={{textAlign:'center',marginTop:'30px',color:'#032276',fontSize:'20px',fontWeight:600}}>Mail Address</p>
                          <p style={{textAlign:'center',marginTop:'10px',color:'#032276',fontSize:'14px',padding:'30px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                          <div className='mx-auto d-block testicon'>
                            <FaEnvelopeOpenText className='mx-auto d-block' style={{marginTop:'15px',height:'30px',width:'30px'}}/>
                          </div>
                          <div style={{marginTop:-10,overflow:"hidden",boxSizing:"border-box"}} >
                          <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                          </div>
                    </Card> */}
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Mail Address</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                   
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                    {/* <FaEnvelopeOpenText className='mx-auto d-block' style={{ marginTop: '15px', height: '30px', width: '30px' }} /> */}
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center', color: '#032276', fontSize: '18px', fontWeight: 600 }}>Contact No.</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px' }}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                    
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                    {/* <FaPhoneAlt className='mx-auto d-block' style={{ marginTop: '20px', height: '30px', width: '30px' }} /> */}
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className='mx-auto d-block teztimonialcard'>
                                <p style={{ textAlign: 'center',  color: '#032276', fontSize: '18px', fontWeight: 600 }}>Address</p>
                                <p style={{ textAlign: 'center', marginTop: '10px', color: '#032276', fontSize: '12px'}}>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries.</p>
                                <div className='mx-auto d-block testicon'>
                                    
                                <Image src={userPic} style={{height:'100%',width:'100%'}}></Image>
                                    {/* <FaMapMarkedAlt className='mx-auto d-block' style={{ marginTop: '20px', height: '30px', width: '30px' }} /> */}
                                </div>
                                <div style={{ marginTop: 0, overflow: "hidden", boxSizing: "border-box" }} >
                                    <svg width="100%" height="150%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="83%" y1="13%" x2="17%" y2="87%"><stop offset="5%" stop-color="#fcb900"></stop><stop offset="95%" stop-color="#ff6900"></stop></linearGradient></defs><path d="M 0,700 C 0,700 0,350 0,350 C 109.78571428571428,302.0357142857143 219.57142857142856,254.07142857142856 352,279 C 484.42857142857144,303.92857142857144 639.4999999999999,401.75000000000006 756,425 C 872.5000000000001,448.24999999999994 950.4285714285716,396.9285714285714 1058,372 C 1165.5714285714284,347.0714285714286 1302.7857142857142,348.53571428571433 1440,350 C 1440,350 1440,700 1440,700 Z" stroke="none" stroke-width="0" fill="url(#gradient)" fill-opacity="1" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                </Slider>
            </Container>
        </>
    )
}
