import AWS from "aws-sdk";
import uniqid from "uniqid";

const bucket_name = process.env.REACT_APP_AWS_BUCKET_NAME;
const access_key_id = process.env.REACT_APP_AWS_BUCKET_KEY_ID;
const secret_access_key = process.env.REACT_APP_AWS_BUCKET_KEY_SECRET;
const region = process.env.REACT_APP_AWS_BUCKET_KEY_REGION;

const s3 = new AWS.S3({
  accessKeyId: access_key_id,
  secretAccessKey: secret_access_key,
  region: region,
});

/**
 * Uploads a file (image or PDF) to AWS S3 and returns a promise that resolves with the unique file name of the uploaded file.
 *
 * @param {File} file - The file (image or PDF) to be uploaded.
 * @returns {Promise<string>} A promise that resolves with the unique file name of the uploaded file.
 */

export default function s3FileUpload(file) {
  return new Promise((resolve, reject) => {
    const extension = file.name.split(".").pop();
    const uniqueFileName = `${uniqid()}.${extension}`;

    if (!file.type.startsWith('image/')) {
      reject('File type is not supported. Please upload an image file.');
      return;
    }

    const params = {
      Bucket: bucket_name,
      Key: uniqueFileName,
      Body: file,
    };

    s3.upload(params, (err) => {
      if (err) {
        console.log("s3 upload error: ", err);
        reject("Error uploading to S3");
      } else {
        resolve(uniqueFileName);
      }
    });
  });
}

/**
 * Deletes a file from AWS S3 bucket.
 *
 * @param {string} fileName - The name of the file to be deleted.
 * @returns {Promise<void>} A promise that resolves when the file is successfully deleted.
 */
export async function deleteFileFromS3(fileName) {
  const params = {
    Bucket: bucket_name,
    Key: fileName,
  };

  try {
    await s3.deleteObject(params).promise();
    console.log(`File '${fileName}' deleted successfully from AWS S3 bucket.`);
  } catch (error) {
    console.error(`Error deleting file '${fileName}' from AWS S3 bucket:`, error);
    throw error;
  }
}
