import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Lottie from 'react-lottie';
import * as animationData from '../Images/122467-hanging-oil-lamp.json'

export default function AboutUs() {
  {
    window.scrollTo(0, 0)
}

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

  return (
    <>
      <Container style={{marginTop:'30px',padding:'20px'}} fluid>
        <p style={{color:'#feb92d',fontWeight:500,fontSize:'20px',marginLeft:'30px'}}>About Us </p>
        <h1 style={{color:'#032276',fontWeight:700,fontSize:'30px',marginLeft:'30px'}}>Know About Pandit </h1>

        <Row>
            <Col md={6}>
                <p style={{fontSize:'12px',color:'#032276',margin:'30px'}}>Vivek grew up in surroundings where he had more Muslim friends than Hindu. He lived in the mixed Chattabal neighbourhood of Srinagar, and his family had deep-rooted ties with Muslim families.</p>
                <p style={{fontSize:'12px',color:'#032276',margin:'30px'}}>In 1989, he was an eighth-grade student at Kashmir’s famous National School, situated in the same Karan Nagar locality where I had spent my early childhood. Oblivious to a world that was changing for the worse around him, the schoolchildren were busy rehearsing for grand celebrations to mark the school’s fiftieth anniversary. Those celebrations were cancelled, and within a year his family would have to leave their house and belongings in the dead of the night and flee for their lives.</p>
                <p style={{fontSize:'12px',color:'#032276',margin:'30px'}}>
                He recalls that he could sense a troubling disquiet around him. The 1987 assembly elections had led to bitterness when the Muslim United Front (MUF) claimed that the results had been manipulated by the Congress—who were in alliance with the National Front led by Farooq Abdullah. Large sections of people felt disgruntled and there were reports that some unsuccessful MUF candidates had crossed the border to get military training in Pakistan. Then a bomb exploded in Lalchowk and a Pandit was killed in crossfire between militants and the security forces.
                </p>
            </Col>

            <Col md={6}>
            <Lottie options={defaultOptions}
              height={350}
              width={350}
             />
            </Col>
        </Row>
      </Container>
    </>
  )
}
