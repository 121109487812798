import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { Card, Col, Container, Row } from "react-bootstrap";
import { MyAPI, MyError } from "../helper/api";
import Loading from "./Loading";

function AllPoojasCategory() {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(true);
    const { id } = useParams();
    const [data, setData] = useState(null);

    const getCategory = async () => {
        let res = await MyAPI.GET(`/public/poojas/category?categoryId=${id}`);
        setIsLoading(false);
        let { status, message, data } = res.data || res;
        if (status === true) {
            setData(data);
        } else {
            MyError.error(message);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);

    const openPooja = (id)=>{
        navigate("/pooja/"+id);
    }


    return (
        <>
        <Loading isLoading={isLoading} />
            <Navigation />
            <Container className="w-100 h-100 mt-3 mb-3 py-3">
                <Row className="mb-4">
                    <Col md={12}><h5>Pooja's</h5></Col>
                </Row>
                <Row xs={1} md={4} className="g-4">
                    {
                        !data && (
                            <h5>Loading Please Wait...</h5>
                        )
                    }

                    {
                        data && data.length === 0 && (
                            <h5>No Pooja's found for this category. Try another Category.</h5>
                        )
                    }

                    {data && data.length > 0 && data.map((item, index) => (
                        <Col onClick={()=>openPooja(item._id)} key={index} className="cursor-pointer">
                            <Card className="shadow">
                                <Card.Img style={{ maxHeight: '25vh', objectFit: 'cover', objectPosition: 'top' }} sizes="sm" variant="top" src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${item.image}`} />
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                    <Card.Text>{item.dateTime}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default AllPoojasCategory;
