import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import Footer from "./Footer";
import Navigation from "./Navigation";
import kalash from "../Images/clipart-removebg-preview.png";
import "../ComponentsCss/Categories.css";
import { Link, useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { GET_POOJA_NAME } from "../graphql/Query";
import { useQuery } from "@apollo/client";
import { MyAPI, MyError } from "../helper/api";

export default function PoojaSevices() {
  let navigate = useNavigate();

  const [categories, setCategories] = useState(null);

  // Get all categories data from

  const getAllCategory = async () => {
    let res = await MyAPI.GET("/public/category");
    let { status, message, data } = res.data || res;

    if (status === true) {
      setCategories(data);
      console.log("all public category", data);
    } else {
      MyError.error(message);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const getVendorList = (value) => {
    navigate(`/category/${value}`);
  };

  return (
    <>
      <Navigation />
      <Container style={{ padding: "30px" }} fluid>
        <Row>
          <Col md={1}></Col>
          <Col md={1}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <FaLongArrowAltLeft
                style={{
                  height: "25px",
                  width: "25px",
                  marginTop: "10px",
                  color: "black",
                }}
              />
            </Link>
          </Col>
          <Col md={8}>
            <h3 style={{ textAlign: "center" }}>Our Services</h3>
          </Col>
          <Col md={1}></Col>
          <Col md={1}></Col>
        </Row>
        <hr></hr>
        <Row>

          {
            !categories && (
              <h6>Loading Please  Wait...</h6>
            )
          }

          {
            categories && categories.length === 0 && (
              <h6>Categories Not  Found!</h6>
            )
          }

          {categories &&
            categories.length > 0 &&
            categories.map((item, index) => (
              <Col md={4}>
                <Card
                  onClick={() => {
                    getVendorList(item._id);
                  }}
                  className="crdCategory"
                >
                  <div className="textareas">
                    <div className="divCard overflow-hidden">
                      <Image
                        className="mx-auto d-block rounded-5 object-fit-contain"
                        src={
                          item.image
                            ? `https://niteshbucket.s3.ap-south-1.amazonaws.com/${item.image}`
                            : kalash
                        }
                        style={{
                          height: "80%",
                          width: "80%",
                          marginTop: "5px",
                        }}
                      ></Image>
                    </div>
                    <h6
                      style={{
                        color: "black",
                        marginTop: "40px",
                        marginLeft: "40px",
                        fontWeight: 700,
                      }}
                    >
                      {item.name}
                    </h6>
                  </div>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
}
