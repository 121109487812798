import React, { useState } from "react";
import { Button, Card, Form, Alert } from "react-bootstrap";
import s3FileUpload from "../../helper/imageUploader";
import { Item, MyAPI, MyError } from "../../helper/api";
import ButtonLoading from "../ButtonLoading";

const AddCategory = () => {
  const [categaryName, setCategoryName] = useState("");
  const [categaryStatus, setCategoryStatus] = useState("PENDING");
  const [categaryImage, setCategoryImage] = useState(null);
  const [errors, setErrors] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!categaryName.trim()) {
      errors.categaryName = "Category name is required";
      isValid = false;
    }

    if (!categaryStatus) {
      errors.categaryStatus = "Category status is required";
      isValid = false;
    }

    if (!categaryImage) {
      errors.categaryImage = "Category image is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setBtnLoading(true);
      try {
        const uploadedFileName = await s3FileUpload(categaryImage);
        if (uploadedFileName) {
          console.log("File uploaded successfully:", uploadedFileName);
          let res = await MyAPI.POST(
            "/admin/category",
            {
              name: categaryName,
              image: uploadedFileName,
              status: categaryStatus,
            },
            Item.getItem("token")
          );
          setBtnLoading(false);
          console.log("category added", res.data);
          let { status, message, data } = res.data || res;
          if (status === true) {
            setCategoryImage(null);
            setCategoryName("");
            setCategoryStatus("PENDING");
            MyError.success(message);
          } else {
            MyError.error(message);
          }
        } else {
          MyError.error("s3 file uploading error.");
        }
      } catch (error) {
        setBtnLoading(false);
        MyError.error(error.message);
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div>
      <h1 className="text-center">Add Category</h1>
      <Card>
        <Card.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="poojaName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="poojaName"
                placeholder="Enter Category Name"
                value={categaryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
              {errors.categaryName && (
                <Form.Text className="text-danger">
                  {errors.categaryName}
                </Form.Text>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="poojaPrice" className="form-label">
                Category Status
              </label>
              <select
                className="form-select"
                id="poojaPrice"
                value={categaryStatus}
                onChange={(e) => setCategoryStatus(e.target.value)}
              >
                <option disabled selected>
                  select
                </option>
                <option value="PENDING">Pending</option>
                <option value="ACTIVE">Active</option>
                <option value="REJECTED">Rejected</option>
              </select>
              {errors.categaryStatus && (
                <Form.Text className="text-danger">
                  {errors.categaryStatus}
                </Form.Text>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="poojaImage" className="form-label">
                Category Image
              </label>
              <input
                type="file"
                className="form-control"
                id="poojaImage"
                onChange={(e) => setCategoryImage(e.target.files[0])}
              />
              {errors.categaryImage && (
                <Form.Text className="text-danger">
                  {errors.categaryImage}
                </Form.Text>
              )}
            </div>

            {btnLoading ? (
              <ButtonLoading />
            ) : (
              <button type="submit" className="btn btn-primary">
                Add Category
              </button>
            )}
          </form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddCategory;
