import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Container, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'
import { GET_ALL_BOOKINGS, GET_USERDATA_BY_USERID } from '../../graphql/Query'

export default function AdminAllBookings() {
    {
        window.scrollTo(0, 0)
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { data: allBookingsData, loading: allBookingsDataLoading } = useQuery(GET_ALL_BOOKINGS)

    console.log('allBookingsData', allBookingsData)

    const [udId, setUsId] = useState('')
    const { data: usData } = useQuery(GET_USERDATA_BY_USERID, {
        variables: {
            "userId": `${udId}`
        }
    })
    function handleUsClick(dtata) {
        setUsId(dtata)
        handleShow()
    }
    console.log('usData', usData)

    return (
        <>
            <Container fluid>
                <h5 style={{ textAlign: 'center' }}>All Bookings</h5>
                <hr></hr>
                <Table bordered responsive="sm" style={{ borderBottom: '1px solid #000', border: '1px solid #000', fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th>Vendor Name</th>
                            <th>Booking No.</th>
                            <th>Service Name</th>
                            <th>Service Price</th>
                            <th>schedule Date/Time</th>
                            <th>paymentId</th>
                            <th style={{ textAlign: 'center' }}>Status</th>
                            <th style={{ textAlign: 'center' }}>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allBookingsDataLoading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner> :
                                allBookingsData && allBookingsData.getBooking.slice(0).reverse().map(bData =>
                                    <tr>
                                        <td>{bData.vendorName}</td>
                                        <td>{bData.bookingNo}</td>
                                        <td>{bData.serviceName}</td>
                                        <td>{bData.servicePrice}</td>
                                        <td>{bData.scheduleDate} {bData.scheduleTime}</td>
                                        <td>{bData.paymentId}</td>
                                        <td>{bData.status}</td>
                                        <td style={{ textAlign: 'center', color: '#032276 ' }}><FaEye onClick={() => handleUsClick(bData.userId)}/></td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </Container>

            <Modal show={show} onHide={handleClose}>
                
                <Modal.Body >
                    <h5 style={{fontSize:'14px',textAlign:'center'}}>User Details</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>Name : {usData && usData.getUserDataByUserID.fName} {usData && usData.getUserDataByUserID.lName}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>Email : {usData && usData.getUserDataByUserID.email}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>Contact : {usData && usData.getUserDataByUserID.contact}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>Address : {usData && usData.getUserDataByUserID.address}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>City : {usData && usData.getUserDataByUserID.city}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>Pincode : {usData && usData.getUserDataByUserID.pincode}</h5>
                    <hr></hr>
                    <h5 style={{fontSize:'12px'}}>State : {usData && usData.getUserDataByUserID.state}</h5>
                    <hr></hr>
                </Modal.Body>
        
            </Modal>
        </>


    )
}
