import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { GET_ALL_USER } from "../../graphql/Query";
import Moment from "react-moment";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { Item, MY_AWS, MyAPI, MyError } from "../../helper/api";
import { MdDeleteForever } from "react-icons/md";
import s3FileUpload, { deleteFileFromS3 } from "../../helper/imageUploader";
import Loading from "../Loading";

export default function AllCategory() {

  const [isLoading, setIsLoading] = useState(true);


  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [showImageInput, setShowImageInput] = useState(false);
  const [updateImage, setUpdateImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const getAllCategory = async () => {
    let res = await MyAPI.GET("/admin/category", Item.getItem("token"));
    let { status, message, data } = res.data || res;
    setIsLoading(false);
    if (status === true) {
      setData(data);
    } else {
      MyError.error(message);
    }
    console.log("all category", res.data);
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleEdit = (category) => {
    setEditingCategory(category);
    handleShowModal();
  };

  const handleDelete = async (category) => {
    setDeletingCategory(category);

    let res = await MyAPI.DELETE(
      `/admin/category?id=${category._id}`,
      Item.getItem("token")
    );
    let { status, message, data } = res.data || res;
    if (status === true) {
      getAllCategory();
      await MY_AWS.deleteFile(category.image);
      MyError.success(message);
    } else {
      MyError.error(message);
    }
    console.log(res.data);
  };

  const handleEditCategory = async () => {
    if (!editingCategory.name || !editingCategory.status) {
      MyError.error("All fields are required.");
      return;
    }

    if (showImageInput && !updateImage) {
      MyError.error("Please upload an image.");
      return;
    }

    let uploadedFileName = null;
    if (showImageInput) {
      try {
        uploadedFileName = await s3FileUpload(updateImage);
        if (!uploadedFileName) {
          MyError.error("Failed to upload image.");
        }
        await deleteFileFromS3(editingCategory.image);
      } catch (error) {
        MyError.error(error.message);
        return;
      }
    }

    try {
      const requestData = {
        name: editingCategory.name,
        status: editingCategory.status,
        image: showImageInput ? uploadedFileName : editingCategory.image,
      };

      const res = await MyAPI.PUT(
        `/admin/category?id=${editingCategory._id}`,
        requestData,
        Item.getItem("token")
      );
      const { status, message } = res.data || res;

      if (status === true) {
        MyError.success(message);
        getAllCategory();
        setShowModal(false);
        setEditingCategory(null); // Clear editingCategory state
      } else {
        MyError.error(message);
      }
    } catch (error) {
      MyError.error("An error occurred while processing your request.");
    }
  };

  return (
    <>
    <Loading isLoading={isLoading} />
      <Container fluid>
        <h6 style={{ textAlign: "center" }}>All Category</h6>
        <hr></hr>
        <Table
          bordered
          responsive="sm"
          style={{
            borderBottom: "1px solid #000",
            border: "1px solid #000",
            fontSize: "13px",
          }}
        >
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>Name</th>
              <th>Status</th>
              <th>Date/Time</th>
              <th>Image</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              data &&
              data.map((category) => (
                <tr style={{ textAlign: "center" }} key={category.id}>
                  <td>{category.name}</td>
                  <td>{category.status}</td>
                  <td>
                    <Moment format="DD/MM/YYYY, hh:mm a">
                      {category.dateTime}
                    </Moment>
                  </td>
                  <td>
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${category.image}`}
                      alt="Category"
                    />
                  </td>
                  <td>
                    <FaRegEdit
                      className="text-primary cursor-pointer"
                      size={22}
                      onClick={() => handleEdit(category)}
                    />
                    <MdDeleteForever
                      className="ms-3 text-danger cursor-pointer"
                      size={22}
                      onClick={() => handleDelete(category)}
                    />
                  </td>
                </tr>
              ))
            )}
            {data && data.length === 0 && (
              <tr>
                <td colSpan="5" className="px-3 py-2">
                  No Category Found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      {/* Modal for Editing Categories */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="categoryName">
            <Form.Label>Category Name</Form.Label>
            <Form.Control
              onChange={(e) =>
                setEditingCategory({ ...editingCategory, name: e.target.value })
              }
              type="text"
              value={editingCategory && editingCategory.name}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="categoryStatus">
            <Form.Label>Category Status</Form.Label>
            <Form.Select
              onChange={(e) =>
                setEditingCategory({
                  ...editingCategory,
                  status: e.target.value,
                })
              }
              value={editingCategory && editingCategory.status}
            >
              <option value="PENDING">Pending</option>
              <option value="ACTIVE">Active</option>
              <option value="REJECTED">Rejected</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="toggleImageInput">
            <Form.Check
              type="switch"
              id="toggleImageInput"
              label="Update Image"
              checked={showImageInput}
              onChange={() => setShowImageInput(!showImageInput)}
            />
          </Form.Group>

          {showImageInput && (
            <Form.Group className="mb-3" controlId="categoryImage">
              <Form.Label>Category Image</Form.Label>
              <Form.Control
                onChange={(e) => setUpdateImage(e.target.files[0])}
                type="file"
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditCategory}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
