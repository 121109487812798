import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Container, Modal, Spinner, Table } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import swal from 'sweetalert'
import { MUTATION_DELETE_REQUEST } from '../../graphql/Mutation'
import { GET_REQUEST } from '../../graphql/Query'

export default function VendorsRequest() {

    const { data: requestData, loading: requestLoading } = useQuery(GET_REQUEST)

    const [show2, setShow2] = useState(false);
    const [reqID, setReqId] = useState('')
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    function handleDeleteReq(id) {
        setReqId(id)
        handleShow2()
    }
    const [deleteRequest, { loading: dltLoading }] = useMutation(MUTATION_DELETE_REQUEST, {
        refetchQueries: [
            GET_REQUEST
        ]
    })

    function handleDeleteRequest() {
        deleteRequest({
            variables: {
                "requestId": `${reqID}`
            }
        })
        swal("Deleted!", "Vendor Request has been deleted!", "success")
        handleClose2()
    }
    console.log('requestData', requestData)
    return (
        <>
            <Container>
                <h6 style={{ textAlign: 'center' }}>All Vendor Request</h6>
                <hr></hr>
                <Table bordered responsive="sm" style={{ borderBottom: '1px solid #000', border: '1px solid #000', fontSize: '13px' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Contact</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th style={{ textAlign: 'center' }}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requestLoading ?
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                                 :
                                requestData && requestData.getRequest.slice(0).reverse().map(userData =>
                                    <tr>
                                        <td>{userData.fName} {userData.lName}</td>
                                        <td>{userData.contact}</td>
                                        <td>{userData.email}</td>
                                        <td>{userData.message}</td>
                                        <td style={{ textAlign: 'center', color: '#032276 ' }}><FaTrash style={{ color: 'red' }} onClick={() => handleDeleteReq(userData.id)} /></td>
                                    </tr>
                                )
                        }
                    </tbody>
                </Table>
            </Container>

            <Modal size='sm' centered show={show2} onHide={handleClose2}>
                <Modal.Body>
                    <h6>Are You Sure You Want To Delete Request??</h6>
                    {
                        dltLoading ?
                            <Spinner className='mx-auto d-block' animation="border" variant="warning" />
                            :
                            <Button className='mx-auto d-block' type="submit" style={{ backgroundColor: '#ffb600', color: '#000', border: 'none' }} onClick={() => handleDeleteRequest()}>Ok</Button>
                    }
                </Modal.Body>

            </Modal>

        </>
    )
}
