import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Item, MyAPI, MyError } from '../../helper/api';
import { Card } from 'react-bootstrap';
import Loading from '../Loading';

function UserById() {
    let { id } = useParams();

    const [user, setUser] = useState(null);
    const [isLoading,setIsLoading] = useState(true);

    const fetchUserDetails = async () => {
        let res = await MyAPI.GET(`/admin/user/${id}`, Item.getItem('token'));
        setIsLoading(false);
        let { status, message, data } = res.data || res;
        if (status === true) {
            console.log('requiested user ', data);
            setUser(data);
        } else {
            MyError.error(message);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, [])

    return (
        <>
        <Loading isLoading={isLoading} />
            <>
                {user && (
                    <Card style={{ width: 'auto' }}>
                        <Card.Body>
                        <div className='mb-5'>
                            <img src={user && `https://niteshbucket.s3.ap-south-1.amazonaws.com/${user.image}`} alt={user && `@${user.username} Profile`} style={{ width: '120px', height: '120px', borderRadius: '50%',objectFit:'cover',objectPosition:'top' }} />
                        </div>
                            <Card.Title>{user.name}</Card.Title>
                            <Card.Text>
                                <p><strong>Email:</strong> {user.email}</p>
                                <p><strong>Phone:</strong> {user.phone}</p>
                                <p><strong>Address:</strong> {user.address}, {user.city}, {user.district}, {user.state} - {user.pincode}</p>
                                <p><strong>Status:</strong> {user.status}</p>
                                <p><strong>WhatsApp:</strong> {user.whatsapp}</p>
                                {/* You can add more fields as needed */}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Last updated: {new Date(user.updatedAt).toLocaleString()}</small>
                        </Card.Footer>
                    </Card>
                )}
                {!user && (
                    <h5>Loading Plese Wait...</h5>
                )}
            </>
        </>
    )
}

export default UserById