import React, { useEffect, useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";

const PoojaCarousel = ({ poojaPhotos }) => {
  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    // Adjust the number of items to show based on screen size
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setItemsToShow(3); // Large screens (lg) - show 3 items
      } else if (window.innerWidth >= 768) {
        setItemsToShow(2); // Medium screens (md) - show 2 items
      } else {
        setItemsToShow(1); // Small screens and below - show 1 item
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container className="mt-3 mb-3 p-0">
      <Carousel
        interval={5000}
        pause={false}
        nextIcon={poojaPhotos.length > itemsToShow ? <span style={{fontSize:'32px'}}>&#8594;</span> : null}
        prevIcon={poojaPhotos.length > itemsToShow ? <span style={{fontSize:'32px'}}>&#8592;</span> : null} 
      >
        {poojaPhotos.map((photo, index) => (
          index % itemsToShow === 0 && (
            <Carousel.Item key={index}>
              <Row>
                {poojaPhotos.slice(index, index + itemsToShow).map((photo, subIndex) => (
                  <Col key={subIndex} lg={12 / itemsToShow} md={12 / itemsToShow}>
                    <img
                      style={{maxHeight: '40vh',aspectRatio:3/2,objectFit:'cover',objectPosition:'top'}}
                      className="d-block w-100 rounded-2"
                      src={`https://niteshbucket.s3.ap-south-1.amazonaws.com/${photo}`}
                      alt={`Pooja ${index}`}
                    />
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          )
        ))}
      </Carousel>
    </Container>
  );
};

export default PoojaCarousel;
