import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Navigation from './Navigation'
import horoscop from '../Images/6b53cbae414f00c4945661e53cd9fec4.png'
import '../ComponentsCss/Astrology.css'

export default function Astrology() {

    function astrologyservice(){
        alert("Our Astrology Service Start Very Soon..😊")
    }
    return (
        <>
            <Navigation />
            <Container style={{ padding: '30px' }} fluid>
                <Image className='roundhoroscop' src={horoscop}></Image>
                <div className='roundhoroscoptext'>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={1}>
                            <Link to='/' style={{ textDecoration: 'none' }}>
                                <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                            </Link>
                        </Col>
                        <Col md={8}>
                            <h3 style={{ textAlign: 'center' }}>Astrology</h3>
                            <p style={{ textAlign: 'center', fontSize: '13px' }}>See all the astrology services that we offer</p>

                        </Col>
                        <Col md={1}></Col>
                        <Col md={1}></Col>
                    </Row>
                    <hr></hr>
                    <Row style={{ marginTop: '20px' }}>
                        <Col md={4}>
                            <div style={{ display: 'flex'}}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://thumbs.dreamstime.com/b/man-to-walk-over-precipice-success-word-bridge-dream-sky-mountains-motivation-ambition-business-concept-53351932.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Career & Business</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://rdbusinessservices.com.au/wp-content/uploads/2016/09/Free-Consultation-For-Legal-Matters.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Legal Matters</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://travellersworldwide.com/wp-content/uploads/2022/11/shutterstock_623111885-1-scaled-phifxb7av8bl2lrxdes1syk43v9acwtvrveysd1ouo.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Travel</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ marginTop: '40px' }}>
                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://opinionexpress.in/assets/images/article/1597126632.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Health</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://www.thoughtco.com/thmb/yyN2mqKTJrVnUzCgAfCctvo2yik=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-573083127-593894665f4a4ae7b923d6b9b2058edb.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Marriage Obstacles</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://matrimonialsindia.files.wordpress.com/2010/06/horoscope-matching0.gif' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Horoscope Matching</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col md={4}>

                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='https://www.trimurty.com/blog/wp-content/uploads/2016/08/property-investement.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Wealth  & Property</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>

                        </Col>

                        <Col md={4}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ height: '80px', width: '80px' }}>
                                    <Image src='http://boostthyself.com/wp-content/uploads/2017/09/love-relationship.jpg' style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <div style={{ marginLeft: '15px' }}>
                                    <h5 style={{ fontSize: '16px' }}>Love & Relationship</h5>
                                    <h5 style={{ fontSize: '13px' }}>Call Our Astrologer now</h5>
                                    <a href='#' onClick={() => astrologyservice()} style={{ fontSize: '14px' }}>Call Now</a>
                                    <hr></hr>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </>
    )
}
