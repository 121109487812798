import { Routes, Route } from "react-router-dom";
import { CustomRoutes } from "./Routes";
import "./App.css";

function App() {
  return (
    <Routes>
      {CustomRoutes &&
        CustomRoutes.map((c_route) =>
          c_route && c_route.isGroup ? (
            <Route path={c_route.path} element={c_route.component}>
              {c_route &&
                c_route.childs.length > 0 &&
                c_route.childs.map((child) => (
                  <Route path={child.path} element={child.component} />
                ))}
            </Route>
          ) : (
            <Route path={c_route.path} element={c_route.component}></Route>
          )
        )}
    </Routes>
  );
}

export default App;
