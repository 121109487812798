import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FaCheck, FaLongArrowAltLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navigation from "./Navigation";
import "../ComponentsCss/BookNow.css";
import swal from "sweetalert";

export default function BookNow() {
  return (
    <>
      <Navigation />
      <Container style={{ padding: "30px" }} fluid>
        <Row>
          <Col md={1}></Col>
          <Col md={1}>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <FaLongArrowAltLeft
                style={{
                  height: "25px",
                  width: "25px",
                  marginTop: "10px",
                  color: "black",
                }}
              />
            </Link>
          </Col>
          <Col md={8}>
            <h5
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              Select Your Package
            </h5>
          </Col>
          <Col md={1}></Col>
          <Col md={1}></Col>
        </Row>
        <hr></hr>
        <Row>
          <Col md={8}>
            <Card className="detailsbooking">
              <Row>
                <Col md={12}>
                  <h6 style={{ fontSize: "14px" }} className="text-center">
                    Date & Time Requirements
                  </h6>

                  <Form>
                    <Row className="mt-4">
                      <Col md={8}>
                        <Form.Label style={{ fontSize: "13px" }}>
                          Date
                        </Form.Label>

                        <div className="d-flex align-items-center gap-3">
                          <Form.Control
                            type="date"
                            style={{ fontSize: "13px", width: "61%" }}
                          />

                          <Button
                            className="btn btn-sm border-0"
                            style={{ background: "#FFB600", width: "30%" }}
                          >
                            Check Availability
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={5}>
                        <Form.Label style={{ fontSize: "13px" }}>
                          Time
                        </Form.Label>

                        <Form.Control
                          type="time"
                          style={{ fontSize: "13px" }}
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>

            <Card className="detailsbooking">
              <Row>
                <Col md={6}>
                  <div
                    style={{
                      borderRight: "2px solid #b2bec3",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <h6 style={{ textAlign: "center", fontSize: "14px" }}>
                      Standard : (1 Panditji + All Puja Samagries)
                    </h6>
                    <ul>
                      <p style={{ fontSize: "13px" }}>Procedure involved:</p>
                      <li style={{ fontSize: "13px" }}>Gowri-Ganesh Puja</li>
                      <li style={{ fontSize: "13px" }}>Gowri-Ganesh Puja</li>
                      <li style={{ fontSize: "13px" }}>Gowri-Ganesh Puja</li>
                      <li style={{ fontSize: "13px" }}>Gowri-Ganesh Puja</li>
                      <li style={{ fontSize: "13px" }}>Gowri-Ganesh Puja</li>
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    style={{
                      borderRight: "2px solid #b2bec3",
                      height: "100%",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <h6 style={{ fontSize: "14px" }}>Inclusion</h6>

                    <p style={{ fontSize: "13px" }}>
                      <FaCheck style={{ color: "green" }} /> Dakshina
                    </p>
                    <p style={{ fontSize: "13px" }}>
                      <FaCheck style={{ color: "green" }} /> Dakshina
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    style={{
                      borderRigheight: "100%",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <h6 style={{ fontSize: "14px" }}>Price</h6>

                    <p style={{ fontSize: "13px", textAlign: "center" }}>
                      ₹ 4500/-
                    </p>
                    <Button
                      className="mx-auto d-block"
                      style={{
                        height: "20%",
                        width: "50%",
                        background: "#ffb600",
                        border: "none",
                        color: "#000",
                        fontSize: "13px",
                        borderRadius: "30px",
                        overflow: "hidden",
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="priccccbook">
              <Table>
                <tbody>
                  <tr>
                    <td>gas</td>
                    <td>Mark</td>
                  </tr>
                  <tr>
                    <td>gas</td>
                    <td>Mark</td>
                  </tr>
                  <tr>
                    <td>gas</td>
                    <td>Mark</td>
                  </tr>
                </tbody>
              </Table>
              <div style={{ display: "flex" }}>
                <input
                  type="radio"
                  id="javascript"
                  name="fav_language"
                  value="JavaScript"
                  style={{ height: "18px", width: "18px" }}
                />
                <label
                  for="javascript"
                  style={{
                    marginLeft: "10px",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  Pay Advance
                </label>
              </div>
              <div style={{ display: "flex", marginTop: "7px" }}>
                <input
                  type="radio"
                  id="javascript"
                  name="fav_language"
                  value="JavaScript"
                  style={{ height: "18px", width: "18px" }}
                />
                <label
                  for="javascript"
                  style={{
                    marginLeft: "10px",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  Pay Full Amount
                </label>
              </div>
              <div style={{ display: "flex", marginTop: "7px" }}>
                <input
                  type="radio"
                  id="javascript"
                  name="fav_language"
                  value="JavaScript"
                  style={{ height: "18px", width: "18px" }}
                />
                <label
                  for="javascript"
                  style={{
                    marginLeft: "10px",
                    fontSize: "13px",
                    fontWeight: 700,
                  }}
                >
                  Pay Cash
                </label>
              </div>
              <Row style={{ marginTop: "20px" }}>
                <Col>
                  <p style={{ textAlign: "right", fontSize: "12px" }}>
                    Advance
                  </p>
                  <p style={{ textAlign: "right", fontSize: "12px" }}>
                    Remaining
                  </p>
                  <hr></hr>
                  <p style={{ textAlign: "right", fontSize: "12px" }}>Total</p>
                </Col>
                <Col>
                  <p style={{ textAlign: "center", fontSize: "12px" }}>:</p>
                  <p style={{ textAlign: "center", fontSize: "12px" }}>:</p>
                  <hr></hr>
                  <p style={{ textAlign: "center", fontSize: "12px" }}>:</p>
                </Col>
                <Col>
                  <p style={{ fontSize: "12px" }}>₹ 1400.00</p>
                  <p style={{ fontSize: "12px" }}>₹ 2300.00</p>
                  <hr></hr>
                  <p style={{ fontSize: "12px" }}>₹ 3700.00</p>
                </Col>
              </Row>
            </Card>
            <Button
              className="mx-auto d-block"
              style={{
                height: "10%",
                width: "50%",
                background: "#ffb600",
                border: "none",
                color: "#000",
                fontSize: "13px",
                borderRadius: "30px",
                overflow: "hidden",
              }}
              onClick={() =>
                swal({
                  title: "Thank You",
                  text: "Your Booking is Confirmed!",
                  icon: "success",

                })
              }
            >
              Proceed To Pay
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
