import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Image, Modal, Row, Spinner } from 'react-bootstrap'
import { GET_BOOKING_BY_VENDORID, GET_USERDATA_BY_USERID, QUERY_GET_USERBY_USERID } from '../../graphql/Query'
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { MUTATION_CHANGE_BOOKING_STATUS } from '../../graphql/Mutation';

export default function VendorBooking() {

    const vendorId = localStorage.getItem('vendorId')

    const { data: vendorBookingData, loading: bookingLoading } = useQuery(GET_BOOKING_BY_VENDORID, {
        variables: {
            "vendorId": `${vendorId}`
        }
    })
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [userDataId, setUserDataId] = useState('')

    const { data: dataUser } = useQuery(GET_USERDATA_BY_USERID, {
        variables: {
            "userId": `${userDataId}`
        }
    })

    function handleUserClick(UData) {
        setUserDataId(UData.userId)
        setBookingId(UData.id)
        handleShow()

    }
    const [changeBookingStatus, { loading: statusloading }] = useMutation(MUTATION_CHANGE_BOOKING_STATUS, {
        refetchQueries: [
            GET_BOOKING_BY_VENDORID
        ]
    })

    const [bookingId, setBookingId] = useState('')
    const [status, setStatus] = useState('')

    function handleChangeStatus() {
        changeBookingStatus({
            variables: {
                "bookingId": `${bookingId}`,
                "status": `${status}`
            }
        })
        handleClose()
    }

    console.log('dataUser', dataUser)
    console.log('vendorBookingData', vendorBookingData)

    return (
        <>
            <Container>
                <h5 style={{ textAlign: 'center' }}>All Bookings</h5>
                <hr></hr>
                <Row>
                    <Col md={1}></Col>

                    <Col md={10}>
                        <Card style={{ padding: '20px' }}>
                            {
                                bookingLoading ?
                                    <>

                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                    :
                                    vendorBookingData && vendorBookingData.getBookingByVendorID.slice(0).reverse().map(bookingDAta =>
                                        <>

                                            <Row>
                                                <Col md={2}>
                                                    <div style={{ height: '100px', width: '120px' }}>
                                                        <Image classNAme='mx-auto d-block' src="https://www.godpng.com/uploads/png/lord-rama-sita-png.png" style={{ height: '100%', width: '100%' }} />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <h5 style={{ textAlign: 'center', fontSize: '13px', marginTop: '20px' }}>Booking No: {bookingDAta.bookingNo}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Date: {bookingDAta.scheduleDate}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Schedule Time: {bookingDAta.scheduleTime}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Service Name: {bookingDAta.serviceName}</h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Address: <span style={{color:'#3742fa'}}> {bookingDAta.address}</span></h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Contact: <span style={{color:'#3742fa'}}> {bookingDAta.contact}</span></h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Alternate Contact: <span style={{color:'#3742fa'}}> {bookingDAta.alternativeContact}</span></h5>
                                                    <h5 style={{ textAlign: 'center', fontSize: '11px' }}>Status: <span style={{color:'#ffb600'}}> {bookingDAta.status}</span></h5>
                                                </Col>
                                                <Col md={4}>
                                                    <h6 style={{ textAlign: 'center', fontSize: '12px', marginTop: '20px' }}>Booking Date & Time</h6>
                                                    <p style={{ textAlign: 'center', fontSize: '12px' }}><Moment format="DD-MM-YYYY hh:mm a">{bookingDAta.createDateTime}</Moment></p>
                                                    <Link onClick={() => handleUserClick(bookingDAta)}><p style={{ textAlign: 'center', fontSize: '13px' }}>View</p></Link>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                        </>
                                    )

                            }
                        </Card>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body style={{ padding: '15px' }}>

                    <h5 style={{ fontSize: '15px', textAlign: 'center' }}>User Details</h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>Name : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.fName} {dataUser && dataUser.getUserDataByUserID.lName}</span></h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>Contact No. : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.contact}</span></h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>Email : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.email}</span></h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>City : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.city}</span></h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>Address : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.address}</span></h5>
                    <hr></hr>
                    <h5 style={{ fontSize: '14px' }}>Pincode : <span style={{ fontWeight: 'normal' }}>{dataUser && dataUser.getUserDataByUserID.pincode}</span></h5>
                    <hr></hr>

                    <Form style={{ fonstSize: '12px' }}>
                        <Form.Select aria-label="Default select example" style={{ fontSize: '12px' }} onChange={(e) => setStatus(e.target.value)} value={status}>
                            <option>Change Status:</option>
                            <option value="complete">Complete</option>
                            <option value="cancel">Cancel</option>
                        </Form.Select>
                    </Form>
                    <hr></hr>
                    <Button onClick={handleClose} style={{ height: '30px', textAlign: 'center', fontSize: '12px', backgroundColor: '#ffb600', border: 'none', color: 'black' }}>Close</Button>
                    {
                        statusloading ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            :
                            <Button onClick={() => handleChangeStatus()} style={{ marginLeft: '15px', height: '30px', textAlign: 'center', fontSize: '12px', backgroundColor: '#ffb600', border: 'none', color: 'black' }}>Change</Button>

                    }
                </Modal.Body>

            </Modal>
        </>
    )
}
