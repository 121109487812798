import React from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Footer'
import Navigation from '../Navigation'
import kalash from '../../Images/pngtree-kalash-and-pooja-plate-png-image_3264531-removebg-preview.png'

export default function Shantipuja() {
    {
        window.scrollTo(0, 0)
    }

    let navigate = useNavigate();

    const getVendorList = (value) => {
        navigate(`/panditforpoooja/${value}`)
    }

    return (
        <>
            <Navigation />
            <Container style={{ padding: '30px' }} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <FaLongArrowAltLeft style={{ height: '25px', width: '25px', marginTop: '10px', color: 'black' }} />

                        </Link>
                    </Col>
                    <Col md={8}>
                        <h5 style={{ textAlign: 'center', marginTop: '10px' }}>Our Shanti Services</h5>

                    </Col>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Ark Vivah for Male") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Ark Vivah for Male</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Grahan Yog Shanti Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Grahan Yog Shanti Puja</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='crdCategory' onClick={(() => { getVendorList("Guru Chandal Yoga Shanti") })}>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Guru Chandal Yoga Shanti</h6>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Kaal Sarp Dash Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Kaal Sarp Dash Puja</h6>
                            </div>
                        </Card>


                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Kumbh Vivah for Female") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Kumbh Vivah for Female</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card onClick={(() => { getVendorList("Shani Chandra Vish Yog Nivaran") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Shani Chandra Vish Yog Nivaran</h6>
                            </div>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Shani Ketu Shrapati Dosh Nivaran") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Shani Ketu Shrapati Dosh Nivaran</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Vishnu Vivah for Female") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Vishnu Vivah for Female</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>

                        <Card onClick={(() => { getVendorList("Vishti Karan Shanti Puja") })} className='crdCategory'>
                            <div className='textareas'>
                                <div className='divCard' >
                                    <Image className='mx-auto d-block' src={kalash} style={{ height: '80%', width: '80%', marginTop: '5px' }}></Image>
                                </div>
                                <h6 style={{ color: 'black', marginTop: '40px', marginLeft: '40px', fontWeight: 700 }}>Vishti Karan (Bhandra) Shanti Puja</h6>
                            </div>
                        </Card>


                    </Col>
                </Row>

            </Container>
            <Footer />
        </>
    )
}
