import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import Lottie from "react-lottie";
import { Navigate, useNavigate, useNavigation } from "react-router-dom";
import { MUTATION_ADMIN_LOGIN } from "../../graphql/Mutation";
import * as animationData from "../../Images/122987-admin-page-koperasi.json";
import Footer from "../Footer";
import Navigation from "../Navigation";
import { Item, MyAPI, MyError } from "../../helper/api";
import { toast } from "react-toastify";
import ButtonLoading from "../ButtonLoading";

export default function AdminLogin() {
  const navigation = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const token = localStorage.getItem("adminToken");
  const [error, setError] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      setBtnLoading(true);

      let res = await MyAPI.POST("/admin/login", { username, password });
      setBtnLoading(false);
      let { status, message, data } = res.data || res;

      if (status === true) {
        let { token } = data;
        Item.setItem("token", token);
        Item.setItem("Iam", "admin");
        navigation("/admindashboard/allusers");
        MyError.success(message);
      } else {
        MyError.error(message);
      }
    }
  };

  return (
    <>
      <Navigation />
      <Container style={{ padding: "50px" }} fluid>
        <h2 style={{ textAlign: "center", marginTop: "10px", fontWeight: 600 }}>
          Hello Admin!! Please Log In Here
        </h2>
        <hr style={{ paddingBottom: "20px" }}></hr>

        <Row>
          <Col md={6}>
            <div style={{ height: "100%", width: "100%" }}>
              <Lottie
                options={defaultOptions}
                style={{ marginTop: "30px" }}
                height={400}
                width={400}
              />
            </div>
          </Col>
          <Col md={6}>
            <Card
              style={{
                padding: "30px",
                margin: "40px 10px 10px 10px ",
                borderRadius: "25px",
                boxShadow: " 5px 5px 5px 5px #f5f6f8",
                borderBottom: "5px solid #feb92d",
              }}
            >
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <h4
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    paddingBottom: "30px",
                  }}
                >
                  Admin Login
                </h4>

                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Username "
                    style={{
                      height: "50px",
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid #032276 ",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enter Password "
                    style={{
                      marginTop: "20px",
                      height: "50px",
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid #032276 ",
                      borderRadius: "20px",
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Group>

                <Col md={12} className="d-flex align-items-center justify-content-center">
                  {btnLoading ? (
                    <ButtonLoading />
                  ) : (
                    <Button
                    className="mt-3"
                      style={{
                        backgroundColor: "#032276",
                        height: "50px",
                        width: "60%",
                        borderRadius: "20px",
                      }}
                      type="submit"
                    >
                      Login
                    </Button>
                  )}
                </Col>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
